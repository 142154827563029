import Datas from "constants/Datas";

export const SIMPLE_FILTERS = [
  {
    value: "ID",
    key: "_id",
    label: "Buscar por ID",
  },
  {
    value: "Email",
    key: "email",
    label: "Buscar por Email",
  },
  {
    value: "Telefone",
    key: "cell_phone",
    label: "Buscar por Telefone",
  },
  {
    value: "ID do Ingresso",
    key: "ticket_id",
    label: "Id do Ingresso",
  },
  {
    value: "CPF/CNPJ Vendedor",
    key: "type_document",
    label: "CPF/CNPJ Vendedor",
  },
  {
    value: "Filtros avançados",
    key: "",
    label: "Filtros avançados",
  },
];

export const ADVANCED_FILTERS = [
  {
    label: "ID",
    type: "input",
    name: "_id",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o ID",
  },
  {
    label: "Cadastrado entre",
    type: "date",
  },
  {
    label: "Valor entre",
    type: "price_range",
  },
  {
    label: "Organizador",
    type: "organizer",
  },
  {
    label: "Evento",
    type: "event",
  },
  {
    label: "Ingresso",
    type: "ticket",
  },
  // {
  //   label: "Tipo documento",
  //   name: "type_document",
  //   type: "select",
  //   options: Datas.TypeDocument,
  //   placeholder: "Escolha o tipo",
  // },
  {
    label: "Tipo de pagamento",
    name: "payment_type",
    type: "select",
    options: Datas.TypePaymentsSales,
    placeholder: "Escolha o tipo",
  },
  {
    label: "CPF/ CNPJ",
    type: "input-document",
    name: "document",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o cpf/cnpj",
  },
  {
    label: "Status",
    name: "payment_type",
    type: "select",
    options: Datas.StatusPayments,
    placeholder: "Escolha  o status",
  },
];
