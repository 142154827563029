import { Colors } from "constants/colors";
import { Link } from "react-router-dom";

import styled from "styled-components";

export const DropDownContainer = styled.div`
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

interface DropdownProps {
  height?: string;
}

export const Dropdown = styled.div<DropdownProps>`
  position: absolute;
  background-color: ${Colors.white};
  width: 304px;
  height: ${(props) => (props.height ? props.height : "146px")};
  z-index: 3;
  margin-top: 30px;

  border: 1px solid ${Colors.border45};
  box-shadow: 0px 0px 20px rgba(108, 93, 211, 0.15);
  border-radius: 8px;


  @media (max-width: 621px) {
    width: 240px;
  }

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 32px;

      margin-top: 10px;
      /* border-bottom: 1px solid ${Colors.border10}; */
      display: flex;
      align-items: center;

      img {
        margin-left: 18px;

        width: 14px;
        height: 12px;
      }

      h5 {
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: ${Colors.black};
        margin-left: 10px;
        margin-bottom: 0px;
      }
    }

    li.last {
      border: 0;
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const AStyledLi = styled.a`
  width: 100%;
  list-style: none;
  height: 32px;

  margin-top: 10px;
  display: flex;
  align-items: center;

  border-bottom: 1px solid ${Colors.border10};

  img {
    margin-left: 18px;

    width: 14px;
    height: 12px;
  }

  h5 {
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black};
    margin-left: 10px;
    margin-bottom: 0px;
  }

  .last {
    border-bottom: 0;
  }
`;

export const AStyledLiLast = styled.a`
  width: 100%;
  list-style: none;
  height: 32px;

  margin-top: 10px;
  display: flex;
  align-items: center;

  img {
    margin-left: 18px;

    width: 14px;
    height: 12px;
  }

  h5 {
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black};
    margin-left: 10px;
    margin-bottom: 0px;
  }

  .last {
    border-bottom: 0;
  }
`;

export const LinkStyledLi = styled(Link)`
  width: 100%;
  list-style: none;
  height: 32px;

  margin-top: 10px;
  border-bottom: 1px solid ${Colors.border10};
  display: flex;
  align-items: center;

  img {
    margin-left: 18px;

    width: 14px;
    height: 12px;
  }

  h5 {
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black};
    margin-left: 10px;
    margin-bottom: 0px;
  }

  .last {
    border-bottom: 0;
  }
`;

export const LinkStyledLiLast = styled(Link)`
  width: 100%;
  list-style: none;
  height: 32px;

  margin-top: 10px;

  display: flex;
  align-items: center;

  img {
    margin-left: 18px;

    width: 14px;
    height: 12px;
  }

  h5 {
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black};
    margin-left: 10px;
    margin-bottom: 0px;
  }
`;

export const LiStyledLast = styled.li`
  width: 100%;
  list-style: none;
  height: 32px;

  margin-top: 10px;
  border-bottom: 1px solid transparent;

  display: flex;
  align-items: center;

  img {
    margin-left: 18px;

    width: 14px;
    height: 12px;
  }

  h5 {
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black};
    margin-left: 10px;
    margin-bottom: 0px;
  }
`;

export const LiStyled = styled.li`
  width: 100%;
  list-style: none;
  height: 32px;

  margin-top: 10px;
  border-bottom: 1px solid ${Colors.border10};
  display: flex;
  align-items: center;

  img {
    margin-left: 18px;

    width: 14px;
    height: 12px;
  }

  h5 {
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black};
    margin-left: 10px;
    margin-bottom: 0px;
  }

  .last {
    border: 0;
  }
`;
