import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerModal = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 0px 89px 24px 89px;

  @media (max-width: 700px) {
    padding: 0px 20px 24px 0px;
  }

  h3.normal {
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.purple40};
    margin-top: 20px;
  }

  h2.title {
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    color: ${Colors.secondary100};

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: ${Colors.gray45};
  }

  h3.account {
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.secondary70};
    margin-top: 44px;

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  div.content {
    width: 100%;
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div.image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 180px;
      height: 220px;
      margin-top: 20px;
    }

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 85px;
      text-align: center;
      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;
        text-align: center;
        color: ${Colors.black90};
      }
    }
  }
`;

export const DetailsTicket = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 884px) {
    flex-direction: column-reverse;
  }

  div.first {
    h3.title {
      font-weight: 700;
      font-size: 26px;
      line-height: 160%;
      color: ${Colors.black100};
    }

    @media (max-width: 884px) {
      margin-top: 30px;
    }

    ul {
      margin-top: 40px;

      li {
        list-style: none;
        margin-top: 20px;

        h4 {
          font-weight: 600;
          font-size: 22px;
          color: ${Colors.black100};
          margin-bottom: 0px;
        }

        p {
          font-weight: 400;
          font-size: 18px;
          color: ${Colors.black60};
        }
      }

      li.normal {
        margin-top: 30px;
      }
    }
  }

  div.box {
    width: 320px;
    height: 391px;
    padding: 26px;

    @media (max-width: 884px) {
      width: 100%;
    }

    background: ${Colors.white};
    border: 2px solid ${Colors.background7};
    border-radius: 10px;

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 0.1em;
      color: ${Colors.black100} #11142d;
      margin-top: 18px;
    }
  }
`;
