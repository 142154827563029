import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerNormalModal = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 0px 109px 24px 109px;

  @media (max-width: 700px) {
    padding: 0px 20px 24px 0px;
  }

  div.container-border {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.border {
    width: 80%;
    border-top: 1px solid ${Colors.border};
    margin-top: 26px;
    padding-top: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;

      color: ${Colors.gray45};
    }
  }

  h3.normal {
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.purple40};
    margin-top: 20px;
  }

  h2.title {
    font-weight: bold;
    font-size: 32px;
    /* line-height: 160%; */
    text-align: center;
    color: ${Colors.secondary100};

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    text-align: center;

    color: #585b6c;
  }

  h3.account {
    font-weight: 600;
    font-size: 14px;

    color: ${Colors.secondary70};
    margin-top: 44px;

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  div.content {
    width: 100%;
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div.image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 180px;
      height: 220px;
      margin-top: 20px;
    }

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 85px;

      text-align: center;

      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }
`;

export const ContainerModal = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 0px 18px 24px 18px;

  @media (max-width: 700px) {
    padding: 0px 20px 24px 0px;
  }

  div.container-border {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.border {
    width: 80%;
    border-top: 1px solid ${Colors.border};
    margin-top: 26px;
    padding-top: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;

      color: ${Colors.gray45};
    }
  }

  h3.normal {
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.purple40};
    margin-top: 20px;
  }

  h2.title {
    font-weight: bold;
    font-size: 32px;
    /* line-height: 160%; */
    text-align: center;
    color: ${Colors.secondary100};

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    text-align: center;

    color: #585b6c;
  }

  h3.account {
    font-weight: 600;
    font-size: 14px;

    color: ${Colors.secondary70};
    margin-top: 44px;

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  div.content {
    width: 100%;
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div.image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 180px;
      height: 220px;
      margin-top: 20px;
    }

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 85px;

      text-align: center;

      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }
`;

export const ContainerNormalButtons = styled.div`
  width: 100%;
  margin-top: 83px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  margin-top: 22px;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  button.close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 366px;
    height: 50px;

    border: 1px solid #d40050;
    background: ${Colors.white};

    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 14px;

    span {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 34px;
      display: flex;
      align-items: center;
      text-align: center;

      color: ${Colors.primary100};
    }
  }

  button.send {
    cursor: pointer;
    border: 0;
    outline: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 9px 10px;

    width: 389px;
    height: 50px;

    background: ${Colors.primary100};
    box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    border-radius: 14px;

    span {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: ${Colors.white};
      margin-left: 11px;
    }
  }

  @media (max-width: 776px) {
    flex-direction: column;

    button {
      width: 100%;
    }

    button.send {
      margin-top: 10px;
    }
  }
`;

export const ButtonCancel = styled.button`
  width: 132px;
  height: 50px;
  border: 0;
  cursor: pointer;

  border: 1px solid ${Colors.primary100};
  background: ${Colors.white};
  box-sizing: border-box;
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    font-family: "Cairo";

    text-align: center;

    color: ${Colors.primary100};
  }
`;

interface ButtonCancelPurchaseProps {
  isLoading?: boolean;
  active?: boolean;
}

export const ButtonCancelPurchase = styled.button<ButtonCancelPurchaseProps>`
  cursor: pointer;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${(props) => (props.active ? Colors.primary100 : Colors.black10)};

  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;

  color: ${Colors.white};

  span {
    margin-left: ${(props) => (props.isLoading ? "5px" : 0)};
    font-family: "Cairo";
  }
`;

export const ContainerForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white};

  margin-top: 20px;

  div.all-input {
    width: 92%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;

      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  h2.title {
    font-weight: bold;
    font-size: 32px;

    text-align: center;
    color: ${Colors.secondary100};
    margin-top: 20.5px;

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    text-align: center;

    color: #585b6c;
  }

  button.ok {
    cursor: pointer;
    border: 0;
    width: 80px;
    height: 50px;
    margin-top: 40px;

    background: ${Colors.primary100};
    box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    border-radius: 14px;

    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: ${Colors.white};
    }
  }
`;
