import { Link } from "react-router-dom";
import { Container } from "./styles";
import { StatusComponent } from "components/Status";
import { Dropdown, DropDownContainer, LinkStyledLi } from "styles/Dropdown";
import points from "assets/points.svg";
import mail from "assets/mail.svg";
import phoneIcon from "assets/phone.svg";
import eyes_secondary from "assets/eyes-secondary.svg";
import pen_green from "assets/pen-green.svg";

type OrganizerCardProps = {
  id: string;
  agent_email?: string;
  agent_name?: string;
  agent_phone?: string;
  status: boolean;
  onSelectOrganizer: () => void;
  isActive: boolean;
}

function OrganizerCard({ id, agent_email, agent_name, agent_phone, status, onSelectOrganizer, isActive }: OrganizerCardProps) {
  return (
    <Container>
      <div className="column">
        <head>
          <Link to={`/organizers/${id}`}>
            <h4>
              {agent_name
                ? agent_name.length > 22
                  ? `${agent_name.substring(
                      0,
                      22
                    )}...`
                  : agent_name
                : ""}
            </h4>
          </Link>

          <div className="options">
            <StatusComponent
              color={
                status ? "green" : "grey"
              }
              content={
                status ? "ATIVO" : "INATIVO"
              }
            />

            <DropDownContainer>
              <div
                className="area"
                onClick={onSelectOrganizer}
              >
                <img src={points} alt="" />
              </div>

              {isActive ? (
                <Dropdown height="98px">
                  <ul>
                    <LinkStyledLi
                      to={`/organizers/${id}`}
                    >
                      <img
                        src={eyes_secondary}
                        alt="eye"
                      />
                      <h5>Visualizar organizador</h5>
                    </LinkStyledLi>

                    <LinkStyledLi
                      to={`/organizers/${id}`}
                    >
                      <img src={pen_green} alt="pen" />
                      <h5>Editar organizador</h5>
                    </LinkStyledLi>
                  </ul>
                </Dropdown>
              ) : null}
            </DropDownContainer>
          </div>
        </head>

        <div className="second">
          <div className="local">
            <img src={mail} alt="" />

            <span>{agent_email}</span>
          </div>

          <div className="type">
            <img src={phoneIcon} alt="" />
            <span>{agent_phone}</span>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default OrganizerCard;
