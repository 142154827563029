import { Select } from "antd";
import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -16px;
  border-left: 1px solid ${Colors.border100};
  border-bottom: 1px solid ${Colors.border100};
  border-right: 1px solid ${Colors.border100};
  padding: 10px 32px 20px 32px;
`;

export const FormFullNormal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white};

  margin-bottom: 16px;

  div.all-input {
    width: 92%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white};
  margin-bottom: 16px;

  div.all-input {
    width: 92%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
  hasPrefix?: boolean;
}

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
}

export const ContainerInput = styled.div<ContainerInputProps>`
  width: 49%;
  display: flex;
  flex-direction: column;

  height: 68px;

  @media (max-width: 800px) {
    min-height: 40px;
    width: 100%;
    margin-top: 10px;
  }

  background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};

  input {
    width: 100%;
    outline: 0;
    border: 0;
    background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    padding: 0px 0px 4px 3px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  div.hasPrefix {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    h3 {
      display: inline;
      color: ${Colors.secondary70};
      font-size: 12px;
      margin-bottom: 0px;
      padding-left: 3px;
      white-space: nowrap;
      background: ${Colors.border};

      @media (max-width: 600px) {
        white-space: normal;
      }
    }

    input {
      width: 100%;
      padding: 0px 0px 4px 3px;
    }

    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
  }

  input {
    width: 100%;
    outline: 0;
    border: 0;
    color: ${Colors.secondary70};
    font-size: 12px;
    padding: 0px 0px 4px 4px;

    ${(props) =>
      !props.hasPrefix &&
      css`
        border-bottom: 2px solid
          ${props.error ? Colors.error20 : Colors.secondary20};
      `};

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }

  @media (max-width: 800px) {
    width: 100%;
    /* margin-top: 12px; */
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    color: ${Colors.secondary40};
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    margin-left: 4px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin-top: 52px;
  margin-bottom: 52px;
`;

export const ButtonRemove = styled.button`
  cursor: pointer;
  margin-right: 10px;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${Colors.white};
  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  color: ${Colors.primary100};
`;

interface ButtonEditProps {
  isLoading?: boolean;
}

export const ButttonEdit = styled.button<ButtonEditProps>`
  cursor: pointer;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${Colors.primary100};
  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;

  color: ${Colors.white};

  span {
    margin-left: ${(props) => (props.isLoading ? "5px" : 0)};
  }
`;

export const StyledSelect = styled(Select)``;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  h4 {
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.purple40};
    margin-bottom: 0px;
  }

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    display: flex;
    align-items: center;
    color: ${Colors.black70};
    margin-top: 6px;
  }

  div.label {
    margin-top: 6px;

    span {
      font-family: "Poppins";
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      color: ${Colors.black70};
    }
  }

  div.empty {
    width: 502px;
    height: 252.66px;
    background: ${Colors.background6};
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;

      color: ${Colors.black3};
    }

    @media (max-width: 600px) {
      width: 100%;

      h3 {
        font-size: 12px;
      }

      svg {
        height: 50px;
        width: 50px;
      }
    }
  }
`;

interface BackgroundProps {
  img: string;
}

export const Background = styled.div<BackgroundProps>`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  border-radius: 20px;
  background-size: contain;

  width: 502px;
  height: 252.66px;
  border-radius: 10px;
  padding: 14px;

  @media (max-width: 600px) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  div.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const Circle = styled.div`
  cursor: pointer;
  width: 45.24px;
  height: 48.19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${Colors.white};

  img {
    width: 18px;
    height: 20px;
  }
`;

export const ContainerCategories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 14px;
  margin-bottom: 16px;
`;

interface CategoryPillProps {
  active?: boolean;
  haveCursorPointer?: boolean;
}

export const CategoryPill = styled.div<CategoryPillProps>`
  cursor: ${(props) => (props.haveCursorPointer ? "pointer" : "normal")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  gap: 10px;
  min-width: 109px;
  height: 29px;
  margin-right: 18px;

  background: ${(props) => (props.active ? Colors.primary100 : Colors.white10)};
  border-radius: 100px;

  font-family: "Open Sans";

  h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0px;
    color: ${(props) => (props.active ? Colors.white : Colors.secondary70)};
  }
`;
