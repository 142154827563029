import { Select } from "antd";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthTickets } from "services/hooks/ticket";
import { TicketType } from "types";
import { ContainerInput, StyledSelect } from "./styles";

const { Option } = Select;

interface TicketsInterface {
  results: TicketType[];
  count: number;
}

export function SelectTicket() {
  const [searchParams, setSearchParams] = useSearchParams();

  const eventId = searchParams.get("event") ?? "";
  const ticketId = searchParams.get("ticket") ?? "";

  const { data: dataTickets } = useAuthTickets<TicketsInterface>({
    filters: `event_id=${eventId}&limit=50`,
  });

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        ticket_id: value.value,
      });
    },
    [searchParams, setSearchParams]
  );

  const clearTicketId = useCallback(() => {
    searchParams.delete("ticket");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  return (
    <ContainerInput>
      <strong>Ingresso</strong>

      <div className="select">
        <StyledSelect
          bordered={false}
          labelInValue
          defaultValue={ticketId}
          placeholder="Escolha o ingresso"
          showSearch
          disabled={!eventId}
          onChange={handleSelectedIdFilter}
          allowClear
          onClear={clearTicketId}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          style={{
            width: "100%",
            minHeight: "100%",
            border: "none",
            outline: 0,
          }}
        >
          {dataTickets?.results?.map((ticket) => (
            <Option value={ticket._id} key={ticket._id}>
              {ticket.name}
            </Option>
          ))}
        </StyledSelect>
      </div>
    </ContainerInput>
  );
}
