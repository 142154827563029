import { Colors } from "constants/colors";
import { ReactNode } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import { ModalStyled } from "./styles";

interface ModalProps {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  children: ReactNode;
}

export function Modal({
  isModalVisible,
  handleOk,
  handleCancel,
  children,
}: ModalProps) {
  return (
    <ModalStyled
      width={857}
      visible={isModalVisible}
      onOk={handleOk}
      style={{ borderRadius: "20px" }}
      onCancel={handleCancel}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      {children}
    </ModalStyled>
  );
}
