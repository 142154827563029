import { Pagination } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const PaginationStyled = styled(Pagination)`
  .ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: "Cairo";
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: ${Colors.white};
    border: 1px solid ${Colors.primary100};
    border-radius: 6px;
    outline: 0;
    cursor: pointer;
    font-weight: 700;
  }

  .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: ${Colors.primary100};
    transition: none;
  }

  .ant-pagination-item-active {
    background: ${Colors.primary100};
    font-weight: 700;
    color: ${Colors.white};
  }

  .ant-pagination-item-active a {
    color: ${Colors.white};
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid ${Colors.primary100};
    border-radius: 6px;
    outline: none;
    transition: all 0.3s;

    color: ${Colors.primary100};
  }
`;
