import { useMemo } from "react";

import { Link, useLocation } from "react-router-dom";

import { useAuth } from "contexts/AuthContext";

import { navArray } from "constants/nav";

import { Avatar, Container, ContainerItems, Profile } from "./styles";

import logo from "../../assets/logo.svg";
import opcoesIcon from "../../assets/opcoesIcon.svg";
import exitIcon from "../../assets/exitIcon.svg";
import user_avatar from "assets/user-avatar.svg";

export function Sidebar() {
  const { signOut, user } = useAuth();

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const renderSplitLocation = useMemo(() => {
    if (splitLocation[1] === "") {
      return "/";
    }

    return `/${splitLocation[1]}`;
  }, [splitLocation]);

  return (
    <Container>
      <ContainerItems>
        <div className="image">
          <img src={logo} alt="" />
        </div>

        <ul>
          {navArray.map((nav) => (
            <Link to={`${nav.link}`}>
              <nav
                className={`card ${
                  renderSplitLocation === nav.link ? "active" : ""
                }`}
              >
                {renderSplitLocation === nav.link ? (
                  <img src={nav.iconActive} alt="" />
                ) : (
                  <img src={nav.icon} alt="" />
                )}

                <h2>{nav.title}</h2>
              </nav>
            </Link>
          ))}
        </ul>

        <Profile>
          <Avatar src={user?.avatar ?? user_avatar} alt={user?.name} />

          <div className="content">
            <div className="first">
              <h3>{user?.name}</h3>
              <p>{user?.email}</p>
            </div>

            <div className="second">
              <div className="icon">
                <img src={opcoesIcon} alt="" />
                <span>Opções</span>
              </div>

              <div className="icon" onClick={signOut}>
                <img src={exitIcon} alt="" />
                <span>Sair</span>
              </div>
            </div>
          </div>
        </Profile>
      </ContainerItems>
    </Container>
  );
}
