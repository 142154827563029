import { Colors } from "../../constants/colors";
import styled from "styled-components";

export const Container = styled.aside`
  @media (max-width: 1240px) {
    display: none;
  }
`;

export const ContainerItems = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  min-width: 223px;
  max-width: 223px;
  position: sticky;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;

  ul {
    &:nth-child(2) {
      margin-top: 65px;
    }
  }

  div.image {
    padding-top: 42.5px;
    padding-left: 30px;
  }

  background-color: ${Colors.secondary100};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0px;
  }

  nav.first {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-top: 31px;
    margin-bottom: 45px;
    padding: 10px 30px;
    border-right: 4px solid transparent;

    .notification {
      border-radius: 2px;
    }

    .notification .badge {
      position: absolute;
      left: 42px;
      top: 106px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 13px;
      width: 13px;
      border-radius: 50%;
      background-color: red;
      background: ${Colors.primary100};
      font-weight: bold;
      font-size: 11px;
      color: ${Colors.white};
    }

    div.circle {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${Colors.white};
    }

    h2 {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;

      color: ${Colors.white};
      margin-bottom: 0px;
      margin-left: 17px;
    }
  }

  nav.card {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    padding: 10px 30px;
    border-right: 4px solid transparent;

    div.circle {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${Colors.white};
    }

    h2 {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;

      color: ${Colors.white};
      margin-bottom: 0px;
      margin-left: 17px;
    }
  }

  nav.active {
    background: rgba(221, 51, 115, 0.07);
    border-right: 4px solid ${Colors.primary80};
  }
`;

export const Profile = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 31px;

  background: ${Colors.secondary90};
  margin-top: 26px;
  margin-bottom: 0px;

  div.content {
    margin-left: 8px;
    display: flex;
    flex-direction: column;

    div.first {
      margin-left: 2px;

      h3 {
        font-weight: normal;
        font-size: 10px;
        line-height: 100%;

        color: ${Colors.white};
      }

      p {
        font-weight: bold;
        font-size: 8px;
        line-height: 100%;

        color: ${Colors.white};
        margin-top: 2px;
      }
    }

    div.second {
      display: flex;
      flex-direction: row;
      margin-top: 8px;

      div + div {
        margin-left: 16px;
      }

      div.icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
          font-weight: 600;
          font-size: 9px;
          line-height: 100%;

          color: ${Colors.primary50};
          margin-left: 4.89px;
        }
      }
    }
  }
`;

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  left: 30px;
  top: 696px;

  align-items: center;
  justify-content: center;
  background: ${Colors.gray30};
  border-radius: 50%;

  border: 5px solid ${Colors.white};
  box-sizing: border-box;
  border-radius: 72px;
`;
