import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

// import featuredBanner from "assets/featuredBanner.jpg";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;

  padding-left: 28px;
  padding-right: 28px;

  margin-top: 70px;
  width: 100%;

  @media (max-width: 800px) {
    margin-top: 38px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }

  div.header {
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
      padding-right: 28px;
    }

    h3 {
      cursor: pointer;
      color: ${Colors.black100};
      font-weight: bold;
      font-size: 36px;
      line-height: 67px;

      @media (max-width: 800px) {
        color: ${Colors.black100};
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;
      }
    }

    p {
      width: 40%;
      color: ${Colors.black100};
      font-weight: normal;
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 35px;

      @media (max-width: 800px) {
        width: 100%;
        color: ${Colors.black100};
        font-weight: normal;
        font-size: 8px;
        line-height: 11px;
        margin-top: 0px;
        margin-bottom: 10px;
      }
    }
  }
`;

export const ListItems = styled.div`
  margin-top: 40px;
`;

export const Item = styled.div`
  display: flex;
  width: 319px;
  flex-direction: column;

  /* margin-right: 90px !important; */


  justify-content: center;
  align-items: center;
  list-style: none;

  margin-top: 25px;
  /* margin-left: 22px; */

  @media (max-width: 800px) {
    max-width: 190px;
    max-height: 93px;
    margin-left: -30px;
    margin-right: 20px;
  }

  img {
    border-radius: 20px;
    position: relative;


    width: 319px;
    height: 156px;

    @media (max-width: 800px) {
      max-width: 190px;
      max-height: 93px;
      border-radius: 10px;
    }
  }

  h3 {
    color: ${Colors.secondary100};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    /* Roxo */


    margin-left: 0px;
    text-align: left;
    /* identical to box height */

    /* Roxo */

    /* color: ${Colors.secondary100}; */

    margin-top: 17px;

    @media (max-width: 800px) {
      font-size: 12px;
      margin-top: 4px;
      /* margin-right: 20px; */
    }
  }
`;

export const CardArrowLeft = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 98;
  cursor: pointer;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 50%;
  box-shadow: -10px 10px 33px rgba(108, 93, 211, 0.25);
  background-color: ${Colors.white};
  position: absolute;
  top: 39%;

  display: block;
  left: -20px;

  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
`;

export const CardArrowRight = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 10px 10px 33px rgba(108, 93, 211, 0.25);
  background-color: ${Colors.white};

  position: absolute;
  top: 46%;

  border: 0;

  display: block;
  right: -25px;

  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
`;

interface DotProps {
  active: boolean;
}

export const Dot = styled.div<DotProps>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? Colors.secondary100 : Colors.secondary20};
  margin-right: 16px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 18px;

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */

    color: ${Colors.secondary100};
  }

  div.all-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    button.view {
      border: 0;
      outline: 0;
      cursor: pointer;
      min-width: 85.75px;
      height: 36px;
      background: ${Colors.white};
      border: 1px solid ${Colors.primary100};
      box-sizing: border-box;
      border-radius: 6px;
      margin-right: 26px;

      padding-left: 10px;
      padding-right: 10px;

      @media (max-width: 621px) {
        display: none;
      }

      span {
        font-weight: 700;
        font-size: 17px;
        line-height: 100%;

        font-family: "Cairo", sans-serif;

        color: ${Colors.primary100};
      }
    }

    button.add {
      border: 0;
      outline: 0;
      cursor: pointer;
      min-width: 156.36px;
      height: 36px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      padding-right: 10px;

      background: ${Colors.primary100};
      border-radius: 6px;

      span {
        font-weight: 700;
        font-size: 17px;
        line-height: 100%;

        font-family: "Cairo", sans-serif;

        color: ${Colors.white};
      }

      .web {
        @media (max-width: 414px) {
          display: none;
        }
      }

      .mobile {
        @media (min-width: 414px) {
          display: none;
        }
      }
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  margin-top: 139px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 790px) {
    margin-top: 79px;
  }
`;

export const ButtonCancel = styled.button`
  width: 132px;
  height: 50px;
  border: 0;
  cursor: pointer;

  border: 1px solid ${Colors.primary100};
  background: ${Colors.white};
  box-sizing: border-box;
  border-radius: 14px;

  @media (max-width: 790px) {
    width: 162px;
    height: 34px;
    border-radius: 6px;
  }

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.primary100};

    @media (max-width: 790px) {
      font-weight: 700;
      font-size: 14px;
    }
  }
`;

interface ButtonAddProps {
  hasEvent?: boolean;
}

export const ButtonAdd = styled.button<ButtonAddProps>`
  width: 200px;
  height: 50px;
  border: 0;
  cursor: pointer;

  @media (max-width: 790px) {
    width: 162px;
    height: 34px;
    border-radius: 6px;
  }

  background: ${(props) =>
    props.hasEvent ? Colors.primary100 : Colors.black10};

  ${(props) =>
    props.hasEvent &&
    css`
      box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    `}

  border-radius: 14px;

  span {
    color: ${(props) => (props.hasEvent ? Colors.white : Colors.black30)};
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    text-align: center;

    @media (max-width: 790px) {
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
    }
  }
`;

export const ButtoMore = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 34px;
  left: 35px;
  top: 1354px;

  background: ${Colors.white};
  border: 1px solid ${Colors.primary100};
  border-radius: 6px;

  font-weight: 700;
  font-size: 14px;
  line-height: 100%;

  color: ${Colors.primary100};

  @media (min-width: 414px) {
    display: none;
  }
`;
