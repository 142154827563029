import {
  ContainerEvents,
  Event,
  EventLoading,
  ImgEmpty,
  ListEvents,
} from "./styles";

import { EventUserType } from "types";
import { useState } from "react";
import { Pagination } from "antd";

import eventImage from "assets/event.jpg";
import points from "assets/points.svg";
import pin from "assets/pin.svg";
import calendar from "assets/calendar.svg";
import timer from "assets/timer.svg";
import { StatusComponent } from "components/Status";
import Skeleton from "react-loading-skeleton";
import {
  AStyledLi,
  Dropdown,
  DropDownContainer,
  LinkStyledLi,
  LinkStyledLiLast,
} from "styles/Dropdown";

import eyes_secondary from "assets/eyes-secondary.svg";
import pen_green from "assets/pen-green.svg";
// import trash_red from "assets/trash-red.svg";
import link from "assets/link.svg";
import { LinkWithHover } from "styles/LinkWithHover";
import { useAuthEventsOrganizer } from "services/hooks/organizer";

interface EventsUserInterface {
  results: EventUserType[];
  count: number;
}

interface EventsUserProps {
  activeUserId: number | null;
}

export function EventsOrganizer({ activeUserId }: EventsUserProps) {
  const [activeEvent, setActiveEvent] = useState<EventUserType | null>();
  const [page, setPage] = useState(1);

  const { data } = useAuthEventsOrganizer<EventsUserInterface>({
    organizerId: activeUserId,
    page,
  });

  const onChange = (currentPage: number) => {
    setPage(currentPage);
  };

  const handleSelectedEvent = (event: EventUserType) => {
    if (activeEvent?._id === event._id) {
      setActiveEvent(null);

      return;
    }

    setActiveEvent(event);
  };

  return (
    <ContainerEvents>
      <ListEvents>
        {!data?.results
          ? [0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
              <EventLoading>
                <div className="first">
                  <Skeleton height="100%" width={60} />
                </div>

                <div className="column">
                  <head>
                    <Skeleton height={18} width={60} />

                    <div className="options">
                      <StatusComponent color="green" content="ATIVO" />

                      <div className="area">
                        <img src={points} alt="" />
                      </div>
                    </div>
                  </head>

                  <div className="second">
                    <div className="local">
                      <Skeleton height={18} width={90} />
                    </div>

                    <div className="type">
                      <Skeleton height={18} width={90} />
                    </div>
                  </div>

                  <div className="third">
                    <div className="day">
                      <Skeleton height={18} width={90} />
                    </div>

                    <div className="time">
                      <Skeleton height={18} width={90} />
                    </div>
                  </div>
                </div>
              </EventLoading>
            ))
          : null}

        {/* {data?.data && data?.data.length > 0 ? (
          data?.data.map((event) => (
           <div></div>
          ): null} */}

        {data?.results && data.results.length > 0
          ? data.results.map((event) => (
              <Event>
                <div className="first">
                  {event.thumb ? (
                    <img src={event.thumb ?? eventImage} alt="event" />
                  ) : (
                    <ImgEmpty />
                  )}
                </div>

                <div className="column">
                  <head>
                    <LinkWithHover to={`/events/${event._id}`}>
                      <h4>
                        {event.name
                          ? event.name.length > 14
                            ? `${event.name.substring(0, 14)}...`
                            : event.name
                          : ""}
                      </h4>
                    </LinkWithHover>

                    <DropDownContainer>
                      <div
                        className="area"
                        onClick={() => handleSelectedEvent(event)}
                      >
                        <img src={points} alt="" />
                      </div>

                      {activeEvent?._id === event._id ? (
                        <Dropdown height="146px">
                          <ul>
                            <LinkStyledLi to={`/events/${event._id}`}>
                              <img src={eyes_secondary} alt="eye" />
                              <h5>Visualizar Evento</h5>
                            </LinkStyledLi>

                            <AStyledLi
                              className="last"
                              target="_blank"
                              rel="noreferrer"
                              href={`${process.env.REACT_APP_EVENTX_WEBSITE}/${event?.nickname}`}
                            >
                              <img src={link} alt="trash" />
                              <h5>Visualizar o link do checkout</h5>
                            </AStyledLi>

                            <LinkStyledLiLast to={`/events/${event._id}`}>
                              <img src={pen_green} alt="pen" />
                              <h5>Editar Evento</h5>
                            </LinkStyledLiLast>
                          </ul>
                        </Dropdown>
                      ) : null}
                    </DropDownContainer>

                    {/* <div className="options">
                      <StatusComponent color="green" content="ATIVO" />

                      <div className="area">
                        <img src={points} alt="" />
                      </div>
                    </div> */}
                  </head>

                  <div className="second">
                    <div className="local">
                      <img src={pin} alt="" />

                      <span>
                        {event.address_street
                          ? event.address_street.length > 14
                            ? `${event.address_street.substring(0, 14)}..`
                            : event.address_street
                          : ""}{" "}
                        - {event.address_state}
                      </span>
                    </div>

                    <div className="type">
                      <span>Tipo: Presencial</span>
                    </div>
                  </div>

                  <div className="third">
                    <div className="day">
                      <img src={calendar} alt="" />

                      <span>{event.formattedBeginDate}</span>
                    </div>

                    <div className="time">
                      <img src={timer} alt="" />
                      <span>
                        {event.begin_hour}h - {event.end_hour}h
                      </span>
                    </div>
                  </div>
                </div>
              </Event>
            ))
          : null}

        {data?.results && data?.results.length === 0 ? (
          <div className="empty">
            <p>Não ha Eventos desse organizador</p>
          </div>
        ) : null}
      </ListEvents>

      {data?.results && data?.results.length > 0 ? (
        <div className="pagination">
          <Pagination current={page} onChange={onChange} total={data?.count} />
        </div>
      ) : null}
    </ContainerEvents>
  );
}
