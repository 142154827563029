import Skeleton from "react-loading-skeleton";

import { Tr } from "./styles";

interface SkeletonRowTableProps {
  row_numbers_size?: number;
  column_numbers_size: number;
  height_size: number;
  width_size: number;
}

export function SkeletonRowTable({
  row_numbers_size = 20,
  column_numbers_size,
  height_size,
  width_size,
}: SkeletonRowTableProps) {
  return (
    <>
      {Array.from(Array(row_numbers_size).keys()).map((_) => {
        return (
          <Tr active={false}>
            {Array.from(Array(column_numbers_size).keys()).map((_) => (
              <td>
                <Skeleton height={height_size} width={width_size} />
              </td>
            ))}
          </Tr>
        );
      })}
    </>
  );
}
