import { Colors } from "constants/colors";
import styled from "styled-components";

// import featuredBanner from "assets/featuredBanner.jpg";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;

  /* padding-left: 28px;
  padding-right: 28px; */

  margin-top: 70px;
  width: 100%;

  @media (max-width: 800px) {
    margin-top: 38px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }

  div.header {
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
      padding-right: 28px;
    }

    h3 {
      cursor: pointer;
      color: ${Colors.black100};
      font-weight: bold;
      font-size: 36px;
      line-height: 67px;

      @media (max-width: 800px) {
        color: ${Colors.black100};
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;
      }
    }

    p {
      width: 40%;
      color: ${Colors.black100};
      font-weight: normal;
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 35px;

      @media (max-width: 800px) {
        width: 100%;
        color: ${Colors.black100};
        font-weight: normal;
        font-size: 8px;
        line-height: 11px;
        margin-top: 0px;
        margin-bottom: 10px;
      }
    }
  }
`;

export const ListItems = styled.div`
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;

  /* &:after {
    content: "";
    flex: auto;
    margin-top: 20px;
  } */
`;

export const Item = styled.div`
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  display: flex;
  text-align: center;
  margin-right: 15px;
  margin-top: 30px;

  h3 {
    color: ${Colors.secondary100};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: ${Colors.secondary100};

    margin-top: 30px;
    /* margin-right: 56px; */

    @media (max-width: 800px) {
      font-size: 12px;
      margin-top: 4px;
      margin-right: 20px;
    }
  }
`;

interface BackgroundProps {
  img: string;
}

export const Background = styled.div<BackgroundProps>`
  cursor: pointer;
  background-image: url(${(props) => props.img});

  border-radius: 20px;
  position: relative;

  background-size: 120px 170px;

  padding: 12px;

  border-radius: 20px;

  width: 120px;
  height: 170px;

  @media (max-width: 800px) {
    max-width: 152.34px;
    max-height: 108.62px;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  div.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Circle = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${Colors.white};

  img {
    width: 14px;
    height: 24px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 18px;

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */

    color: ${Colors.secondary100};
  }

  div.all-buttons {
    display: flex;
    flex-direction: row;

    button.view {
      border: 0;
      outline: 0;
      cursor: pointer;
      width: 85.75px;
      height: 31px;
      background: ${Colors.white};
      border: 1px solid ${Colors.primary100};
      box-sizing: border-box;
      border-radius: 6px;
      margin-right: 26px;

      span {
        font-weight: 700;
        font-size: 17px;
        line-height: 100%;
        font-family: "Cairo", sans-serif;
        color: ${Colors.primary100};
      }
    }

    button.add {
      border: 0;
      outline: 0;
      cursor: pointer;
      min-width: 156.36px;
      height: 36px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      padding-right: 10px;

      background: ${Colors.primary100};
      border-radius: 6px;

      @media (max-width: 621px) {
        display: none;
      }

      span {
        font-weight: 700;
        font-size: 17px;
        line-height: 100%;
        font-family: "Cairo", sans-serif;
        color: ${Colors.white};
      }
    }
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0px 109px 64px 109px;

  div.input {
    width: 100%;
    height: 38px;
    background: ${Colors.white};
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;
  }

  div.logo-qr-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.content {
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 14px;

      text-align: center;

      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }

  /* div.or {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  div.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    span {
      padding: 0px 11px;
    }

    div.line {
      min-width: 160px;
      width: 100%;
      border: 1px solid ${Colors.secondary100};
      opacity: 0.2;
    }
  }

  div.download-app {
    width: 100%;
    margin-top: 36px;
    align-items: center;
    justify-content: center;

    padding-left: 10px;
    padding-right: 10px;

    h5 {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;

      color: ${Colors.black3};
    }

    div.row {
      width: 100%;
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      justify-content: center;
      margin-top: 31px;

      img {
        cursor: pointer;
        min-width: 189px;
        min-height: 128px;
      }
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  margin-top: 89px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonCancel = styled.button`
  width: 132px;
  height: 50px;
  border: 0;
  cursor: pointer;

  border: 1px solid ${Colors.primary100};
  background: ${Colors.white};
  box-sizing: border-box;
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */

    text-align: center;

    color: ${Colors.primary100};
  }
`;

export const ButtonAdd = styled.button`
  width: 200px;
  height: 50px;
  border: 0;
  cursor: pointer;

  /* Primaria/primaria 100 */

  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  span {
    color: ${Colors.white};
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */

    text-align: center;
  }
`;

export const ContainerNewCategory = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
`;

export const ContentNewCategory = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;

  h5.title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;

    color: ${Colors.black100};
  }

  div.new-image {
    display: flex;
    flex-direction: column;
    margin-top: 55px;

    h5.title-image {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;

      color: ${Colors.black100};
    }

    span.image {
      font-style: italic;
      font-weight: bold;
      font-size: 10px;
      line-height: 14px;

      color: ${Colors.gray10};
    }
  }
`;

export const DivImage = styled.div`
  width: 251px;
  height: 358px;

  display: flex;
  align-items: center;
  justify-content: center;

  img.preview {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  img.empty {
    width: 40px;
    height: 36px;
  }

  background: ${Colors.border80};
  border-radius: 5px;
`;

export const ButtonAddImage = styled.label`
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 304px;
  height: 38px;

  background: ${Colors.primary10};
  border-radius: 8px;
  margin-top: 28px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;

    color: ${Colors.primary100};
    margin-bottom: 0px;
  }
`;

export const AllInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    color: ${Colors.red10};
    font-size: 11px;
    margin-top: 4px;
  }
`;

interface InputContainerProps {
  error: boolean | undefined;
}

export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;

  /* margin-top: 33px; */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  margin-top: 24px;
  border-bottom: 2px solid
    ${(props) => (props.error ? Colors.red10 : Colors.secondary20)};

  @media (max-width: 1200px) {
    width: 100%;
  }

  input {
    border: 0;
    outline: 0;
    margin-left: 24px;

    margin-bottom: 5px;
    width: 100%;
  }
`;

export const ContainerNormalButtons = styled.div`
  width: 100%;
  margin-top: 83px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerModalDelete = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0px 109px 24px 109px;

  div.content {
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 85px;

      text-align: center;

      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }
`;
