import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerModalAddDocument = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* justify-content: flex-start; */

  padding: 0px 109px 24px 109px;

  @media (max-width: 700px) {
    padding: 0px 20px 24px 0px;
  }

  h2.title {
    font-weight: bold;
    font-size: 32px;
    /* line-height: 160%; */
    text-align: center;
    color: ${Colors.secondary100};

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    text-align: center;

    color: #585b6c;
  }

  div.header-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    h3 {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 0px;

      color: ${Colors.secondary100};
    }

    h4 {
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      display: flex;
      align-items: center;
      color: ${Colors.black70};
      margin-top: 6px;
    }
  }

  div.content {
    width: 100%;
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div.image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 180px;
      height: 220px;
      margin-top: 20px;
    }

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 85px;

      text-align: center;

      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white};

  margin-top: 20px;

  div.all-input {
    width: 92%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      /* identical to box height, or 32px */

      /* Secundaria/secundaria 70 */

      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }

  /* @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  } */
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
}

export const ContainerInputDocument = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  height: 68px;

  /* @media (max-width: 800px) {
    min-height: 40px;
    width: 100%;
    margin-top: 10px;
  } */

  background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};

  div.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    div.card {
      width: 89%;
      height: 33px;
      background: ${Colors.white};
      border: 2px solid ${Colors.secondary20};
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding-left: 10px;

      h3.text {
        font-weight: 400;
        font-size: 12px;
        color: ${Colors.purple40};
        margin-bottom: 0px;
      }
    }
  }

  input {
    width: 100%;
    outline: 0;
    border: 0;
    background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    /* line-height: 20%; */
    padding: 0px 0px 4px 3px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    /* margin-bottom: 0px;
      margin-top: 23px; */
  }
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
}

export const ContainerInput = styled.div<ContainerInputProps>`
  width: 49%;
  display: flex;
  flex-direction: column;

  height: 68px;

  @media (max-width: 800px) {
    min-height: 40px;
    width: 100%;
    margin-top: 10px;
  }

  background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};

  input {
    width: 100%;
    outline: 0;
    border: 0;
    background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    /* line-height: 20%; */
    padding: 0px 0px 4px 3px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    /* margin-bottom: 0px;
      margin-top: 23px; */
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    margin-left: 4px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const ContainerNormalButtons = styled.div`
  width: 100%;
  margin-top: 83px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonCancel = styled.button`
  width: 132px;
  height: 50px;
  border: 0;
  cursor: pointer;

  border: 1px solid ${Colors.primary100};
  background: ${Colors.white};
  box-sizing: border-box;
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */

    text-align: center;

    color: ${Colors.primary100};
  }
`;

export const ButtonAdd = styled.button`
  width: 200px;
  height: 50px;
  border: 0;
  cursor: pointer;

  /* Primaria/primaria 100 */

  @media (max-width: 700px) {
    width: 140px;
  }

  background: ${Colors.primary100};
  /* box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15); */
  border-radius: 14px;

  span {
    color: ${Colors.white};
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */

    text-align: center;
  }
`;

interface ButtonEditProps {
  isLoading?: boolean;
  hasActive?: boolean;
}

export const ButttonEdit = styled.button<ButtonEditProps>`
  cursor: pointer;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${(props) =>
    props.hasActive ? Colors.primary100 : Colors.black10};
  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;

  color: ${(props) => (props.hasActive ? Colors.white : Colors.black30)};

  span {
    margin-left: ${(props) => (props.isLoading ? "5px" : 0)};
  }
`;

export const ButtonAddFile = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  width: 54.16px;
  height: 34px;

  background: ${Colors.primary100};
  border: 1px solid ${Colors.primary100};
  border-radius: 6px;
`;
