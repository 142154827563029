import { useEffect, useState } from "react";

import {
  Container,
  ContainerItems,
  Item,
  ListItems,
  Content,
  Background,
  ContainerModal,
  ContainerButtons,
  ButtonCancel,
  ButtonAdd,
  ContainerNewCategory,
  ContentNewCategory,
  DivImage,
  AllInput,
  InputContainer,
  ButtonAddImage,
  ContainerNormalButtons,
  ContainerModalDelete,
} from "./styles";

import { BiCalendarCheck } from "react-icons/bi";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Colors } from "constants/colors";
import { Modal } from "components/Modal";
import { CategoryType } from "types";
import { useAuthCategories } from "services/hooks/category";
import { BsPlus } from "react-icons/bs";

import empty_image from "assets/empty-image.svg";

type CategoryFormData = {
  title: string;
};

interface Meta {
  total: number;
}

interface CategoriesInterface {
  data: CategoryType[];
  meta: Meta;
}

const categoryFormSchema = yup.object().shape({
  title: yup.string().required("Titulo obrigatório"),
});

interface BannersProps {
  title: string;
  slug: string;
  showModal(): void;
  setIsModalVisible(value: boolean): void;
  isModalVisible: boolean;
}

export function BannersCategory({
  title,
  slug,
  showModal,
  setIsModalVisible,
  isModalVisible,
}: BannersProps) {
  const [eventHover, setEventHover] = useState<CategoryType | null>(null);
  const [hover, setHover] = useState(false);

  const { data } = useAuthCategories<CategoriesInterface>({});

  const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(
    null
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CategoryFormData>({
    resolver: yupResolver(categoryFormSchema),
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const handleDeleteOk = () => {
    setIsModalDeleteVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsModalDeleteVisible(false);
  };

  const handleAddCategory: SubmitHandler<CategoryFormData> = async ({
    title,
  }) => {
    try {
      // await signIn({
      //   email,
      //   password,
      // });
    } catch (err) {
      alert("Email ou senha invalidos");
    }
  };

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  return (
    <Container>
      <ContainerItems>
        <Content>
          <h1>{title}</h1>

          <div className="all-buttons">
            {/* <button className="view">
              <span>Ver todos</span>
            </button> */}

            {/* <button className="add" onClick={showModal}>
              <BsPlus color={Colors.white} size={18} />

              <span>Adicionar {slug}</span>
            </button> */}
          </div>
        </Content>

        <ListItems>
          {data?.data.map((category) => (
            <Item
              key={category.id}
              onMouseEnter={() => {
                setHover(true);
                setEventHover(category);
              }}
              onMouseLeave={() => {
                setHover(false);
                setEventHover(null);
              }}
            >
              <Background img={category.thumb}>
                {/* {hover && eventHover?.id === category.id ? (
                  <div className="row">
                    <Circle>
                      <img src={editIcon} alt="" />
                    </Circle>
                    <Circle onClick={() => showModalDelete(category)}>
                      <img src={trashIcon} alt="" />
                    </Circle>
                  </div>
                ) : null} */}
              </Background>
              <h3>
                {category.name
                  ? category.name.length > 10
                    ? `${category.name.substring(0, 10)}...`
                    : category.name
                  : ""}
              </h3>
            </Item>
          ))}
        </ListItems>
      </ContainerItems>
      <Modal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
      >
        <ContainerModal onSubmit={handleSubmit(handleAddCategory)}>
          <div className="content">
            <h2>Nova categoria</h2>

            <p>Crie uma nova categoria</p>
          </div>

          <ContainerNewCategory>
            <ContentNewCategory>
              <h5 className="title">Informações da categoria</h5>
              <AllInput>
                <InputContainer error={!!errors?.title}>
                  <div>
                    <BiCalendarCheck size={23} color={Colors.secondary40} />
                  </div>
                  <input
                    type="title"
                    placeholder="Nome da categoria"
                    {...register("title")}
                  />
                </InputContainer>
                {errors.title && <span>Por favor, insira o Email.</span>}
              </AllInput>

              <div className="new-image">
                <h5 className="title-image">Adicione uma imagem de capa</h5>

                <span className="image">
                  Especificações: arquivo do tipo PNG ou JPEG, com dimensão
                  recomendada de 720x1024 px e tamanho máximo de 4MB.
                </span>
              </div>

              <input
                id="file"
                type="file"
                style={{ display: "none" }}
                accept="image/png, image/gif, image/jpeg"
                onChange={onSelectFile}
              />

              <ButtonAddImage htmlFor="file">
                <BsPlus color={Colors.primary100} size={18} />

                <span>Adicionar Imagem</span>
              </ButtonAddImage>
            </ContentNewCategory>

            <DivImage>
              {selectedFile ? (
                <img className="preview" src={preview} alt="" />
              ) : (
                <img src={empty_image} alt="empty" />
              )}
            </DivImage>
          </ContainerNewCategory>

          <ContainerButtons>
            <ButtonCancel onClick={handleCancel}>
              <span>Cancelar</span>
            </ButtonCancel>
            <ButtonAdd>
              <span>Criar categoria</span>
            </ButtonAdd>
          </ContainerButtons>
        </ContainerModal>
      </Modal>

      <Modal
        isModalVisible={isModalDeleteVisible}
        handleCancel={handleDeleteCancel}
        handleOk={handleDeleteOk}
      >
        <ContainerModalDelete>
          <div className="content">
            <h2>Deseja excluir?</h2>

            <p>
              Você tem certeza que deseja excluir o item “
              {selectedCategory?.name ?? ""}” da lista “Categorias”?
            </p>
          </div>

          <ContainerNormalButtons>
            <ButtonCancel onClick={handleDeleteCancel}>
              <span>Cancelar</span>
            </ButtonCancel>
            <ButtonAdd>
              <span>Sim, desejo</span>
            </ButtonAdd>
          </ContainerNormalButtons>
        </ContainerModalDelete>
      </Modal>
    </Container>
  );
}
