import { Modal } from "components/Modal";
import QRCode from "react-qr-code";
import {
  TicketUserType,
  TicketPurchase,
  TicketType,
  EventType,
  UserTicket,
} from "types";
import { ContainerModal, DetailsTicket } from "./styles";
import { useAuthTicket } from "services/hooks/ticket";
import { useAuthEvent } from "services/hooks/event";

interface ModalTicketProps {
  isModalVisible: boolean;
  handleCancel(): void;
  handleOk(): void;
  data: TicketUserType | TicketPurchase | UserTicket | null;
}

export function ModalTicket({
  isModalVisible,
  handleOk,
  handleCancel,
  data,
}: ModalTicketProps) {
  const { data: dataTicket } = useAuthTicket<TicketType>(
    String(data?.event_ticket_id)
  );

  const { data: dataEvent } = useAuthEvent<EventType>(String(data?.event));

  return (
    <Modal
      isModalVisible={isModalVisible}
      handleCancel={handleCancel}
      handleOk={handleOk}
    >
      <ContainerModal>
        <h2 className="title">Visualizar ingresso</h2>

        <DetailsTicket>
          <div className="first">
            <h3 className="title">Dados do ingresso</h3>

            <ul>
              <li>
                <h4>Nome do ingresso</h4>
                <p>{dataTicket ? dataTicket?.name : "Não informado"}</p>
              </li>

              <li>
                <h4>Nome do evento</h4>
                <p>{dataEvent ? dataEvent?.name : "Não informado"}</p>
              </li>

              <li>
                <h4>Nome do comprador</h4>
                <p>{data?.holder_name ?? "Não informado"}</p>
              </li>

              <li className="normal">
                <h4>Email do comprador</h4>
                <p>{data?.holder_email ?? "Não informado"}</p>
              </li>

              <li className="normal">
                <h4>Cpf do comprador</h4>
                <p>{data?.holder_cpf ?? "Não informado"}</p>
              </li>

              <li className="normal">
                <h4>CheckIn</h4>

                <div>
                  <p>
                    {data?.checkin ? "Sim" : "Não"}
                    {/* {data?.formattedCheckinAt} */}
                  </p>
                </div>
              </li>

              <li className="normal">
                <h4>CheckOut</h4>

                <div>
                  <p>
                    {data?.checkout ? "Sim" : "Não"}
                    {/* {data?.formattedCheckoutAt} */}
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="box">
            {data && data?.ticket_code ? (
              <QRCode value={String(data?.ticket_code)} />
            ) : null}

            <h3>{String(data?.ticket_code)}</h3>
          </div>
        </DetailsTicket>
      </ContainerModal>
    </Modal>
  );
}
