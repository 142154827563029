export const returnStatusPurchase = (value: string) => {
  switch (value) {
    case "PAID":
      return "Pago";
    case "PENDING":
    default:
      return "Pendente";
    case "REFUND":
      return "Estornado";
  }
};

export const returnStatusColorPurchase = (value: string) => {
  switch (value) {
    case "PAID":
      return "green";
    case "PENDING ":
      return "orange";
    case "REFUND":
      return "red";

    default:
      return "yellow";
  }
};

export const returnTypePaymentPurchase = (value: string) => {
  switch (value) {
    case "credit-card":
      return "Cartão de Crédito";

    case "boleto":
      return "Boleto";

    case "courtesy":
      return "Cortesia";

    case "pix":
      return "Pix";

    default:
      return "Boleto";
  }
};
