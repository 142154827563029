import { useRef } from "react";

import {
  Container,
  ContainerItems,
  Item,
  CardArrowRight,
  Content,
  ButtoMore,
  Center,
  Background,
} from "./styles";

import { Link } from "react-router-dom";
import Slider, { CustomArrowProps } from "react-slick";
import { RiArrowRightLine } from "react-icons/ri";

import { Colors } from "constants/colors";
import { BsPlus } from "react-icons/bs";
import { HighlightedEventType } from "types";
import { useHighlightedtEvents } from "services/hooks/dashboard";
import { ModalAddHigh } from "components/ModalAddHigh";

interface Meta {
  total: number;
}

interface HighlightedsInterface {
  data: HighlightedEventType[];
  meta: Meta;
}

interface HighlightedsProps {
  title: string;
  slug: string;
  showModal(): void;
  setIsModalVisible(value: boolean): void;
  isModalVisible: boolean;
}

export function HighlightedEvents({
  title,
  slug,
  showModal,
  setIsModalVisible,
  isModalVisible,
}: HighlightedsProps) {
  const slider = useRef<Slider>(null);

  const { data } = useHighlightedtEvents<HighlightedsInterface>();

  const ArrowRight = ({ style, onClick }: CustomArrowProps) => {
    return (
      <CardArrowRight onClick={onClick} style={style}>
        <RiArrowRightLine
          style={{ marginTop: 5, marginLeft: 2 }}
          fontSize={23}
          color={Colors.secondary80}
        />
      </CardArrowRight>
    );
  };

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: data?.data && data?.data.length > 3 ? 4 : data?.data.length,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,
    // prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 946,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: data?.data && data.data.length > 1 ? 1.08 : 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <Container>
      <ContainerItems>
        <Content>
          <h1>{title}</h1>

          <div className="all-buttons">
            <Link to="/visual#high">
              <button className="view">
                <span>Ver todos</span>
              </button>
            </Link>

            <button className="add" onClick={showModal}>
              <BsPlus color={Colors.white} size={23} />
              <span className="web">Adicionar {slug}</span>
              <span className="mobile">Adicionar</span>
            </button>
          </div>
        </Content>

        {data?.data && data.data.length > 0 ? (
          <Slider {...settings} ref={slider}>
            {data?.data.map((high) => (
              // <Item key={high.id}>
              //   <img src={high.thumb} alt="" />
              //   <h3>{high.name}</h3>
              // </Item>

              <Item key={high.id}>
                <Background img={high.thumb}></Background>
                <h3>
                  {high.name
                    ? high.name.length > 15
                      ? `${high.name.substring(0, 15)}...`
                      : high.name
                    : ""}
                </h3>
              </Item>
            ))}
          </Slider>
        ) : null}

        {data?.data && data.data.length === 0 ? (
          <Center>
            <h5>Não há eventos em destaque</h5>
          </Center>
        ) : null}

        <Link to="/visual#high">
          <ButtoMore>Ver todos</ButtoMore>
        </Link>
      </ContainerItems>

      <ModalAddHigh
        title={title}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Container>
  );
}
