import { Select } from "antd";
import styled, { css } from "styled-components";
import { Colors } from "../../constants/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 60px 60px 0px 60px;

  div.header {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg.icon {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  @media (max-width: 1240px) {
    padding: 104px 60px 0px 60px;
  }

  @media (max-width: 768px) {
    padding: 104px 16px 0px 28px;
  }

  @media (max-width: 621px) {
    padding: 104px 16px 0px 30px;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    color: ${Colors.secondary90};
    margin-bottom: 0px;

    @media (max-width: 640px) {
      font-size: 24px;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: ${Colors.secondary80};
    margin-top: 32px;

    @media (max-width: 621px) {
      margin-bottom: 0px;
    }
  }
`;

export const ListButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 33px;

  @media (max-width: 740px) {
    margin-top: 16px;
  }

  div.last {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Button = styled.button`
  border: 0;
  cursor: pointer;
  outline: 0;
  width: 137px;
  height: 34px;
  background: ${Colors.primary100};
  border-radius: 97px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;

    color: ${Colors.white};
  }
`;

export const ButtonBorder = styled.button`
  min-width: 107px;
  height: 34px;
  padding: 8px 24px;
  border: 0;
  background: ${Colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 97px;

  span {
    font-weight: normal;
    font-size: 18px;

    color: ${Colors.primary100};
    margin-bottom: 0px;
  }
`;

export const ContainerItemsTable = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  padding: 0px 60px 0px 60px;
  margin-top: 54px;
  margin-bottom: 100px;

  div.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 768px) {
      padding-right: 20px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 1240px) {
    padding: 24px 60px 0px 60px;
  }

  @media (max-width: 768px) {
    padding: 0px 0px 0px 0px;
    margin-bottom: 40px;
    margin-top: 24px;
  }

  @media (max-width: 621px) {
    padding: 30px 0px 0px 0px;
  }
`;

export const ContainerTabs = styled.div`
  width: 100%;

  h3.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 18px */

    color: ${Colors.secondary80};
    margin-bottom: 27px;

    /* @media (max-width: 740px) {
      margin-bottom: 20px;
      padding-left: 34px;
    } */
  }

  @media (max-width: 768px) {
    padding: 0px 28px 0px 28px;
    margin-top: 0px;
  }

  .ant-tabs-tab {
    font-size: 18px;
    line-height: 100%;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    height: 49px;

    color: ${Colors.white10};

    background: ${Colors.secondary50} !important;
  }

  .ant-tabs-tab-active {
    font-size: 18px;
    line-height: 100%;

    color: ${Colors.secondary80};
    background: ${Colors.white} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.secondary80};
  }
`;

export const AllEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -16px;
  border-left: 1px solid ${Colors.border100};
  border-bottom: 1px solid ${Colors.border100};
  border-right: 1px solid ${Colors.border100};
`;

export const ContainerEvents = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 39px 65px 28px 56px;

  @media (max-width: 768px) {
    padding: 0px 28px 40px 28px;
    margin-top: 0px;
  }

  div.total {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    h3 {
      font-size: 14px;
      font-weight: 400;

      color: ${Colors.secondary80};

      strong {
        font-weight: bold;
      }
    }
  }

  div.pagination {
    margin-top: 20px;
  }
`;

export const ListEvents = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 200px;

  @media (max-width: 823px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  div.empty {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${Colors.secondary80};
      font-weight: 500;
      font-size: 15px;
    }
  }
`;

export const Event = styled.div`
  width: 48%;
  height: 174px;
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  padding: 12px;

  background: ${Colors.white};
  border: 1px solid ${Colors.border100};
  box-sizing: border-box;
  border-radius: 5px;

  @media (max-width: 900px) {
    min-width: 100%;
    width: 100%;
    /* height: 92px; */
  }

  @media (max-width: 516px) {
    /* height: 122px; */
  }

  div.first {
    img {
      width: 41px;
      height: 37px;
    }
  }

  div.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-direction: flex-start;
    margin-left: 12px;
    margin-top: 0px;

    head {
      display: flex;
      flex-direction: row;
      /* align-items: flex-start; */
      justify-content: space-between;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;

      h3 {
        font-weight: 600;
        font-size: 14px;

        margin-top: 0px;
        margin-bottom: 0px;

        color: ${Colors.secondary80};
      }

      h3.last {
        margin-bottom: 8px;
      }

      h4 {
        font-weight: 600;
        font-size: 12px;

        margin-top: 0px;
        margin-bottom: 0px;

        color: ${Colors.secondary70};

        span {
          color: ${Colors.secondary80};
        }
      }

      div.options {
        display: flex;
        flex-direction: row;

        div.area {
          cursor: pointer;
          width: 26px;
          height: 26px;
          margin-left: 12px;

          display: flex;
          align-items: center;
          justify-content: center;

          background: ${Colors.white};
          border: 1px solid ${Colors.border5};
          border-radius: 6px;

          img {
            width: 10px;
            height: 14px;
          }
        }
      }
    }

    div.second {
      display: flex;
      flex-direction: row;
      margin-top: 0px;

      @media (max-width: 516px) {
        flex-direction: column;
      }

      div.local {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 160%;

          color: ${Colors.secondary40};
        }
      }

      div.type {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};

        @media (max-width: 516px) {
          margin-left: 0px;
          padding-left: 0px;
          border-left: none;
        }
      }
    }

    div.third {
      display: flex;
      flex-direction: row;

      div.day {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }
      }

      div.time {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};
      }
    }
  }

  .icon {
    margin-left: 30px;
  }
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -16px;
  border-left: 1px solid ${Colors.border100};
  border-bottom: 1px solid ${Colors.border100};
  border-right: 1px solid ${Colors.border100};
  padding: 10px 32px 20px 32px;
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white};

  margin-top: 20px;

  div.all-input {
    width: 100%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      /* identical to box height, or 32px */

      /* Secundaria/secundaria 70 */

      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }

  /* @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  } */
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
  hasPrefix?: boolean;
}

export const ContainerInput = styled.div<ContainerInputProps>`
  width: 49%;
  display: flex;
  flex-direction: column;

  min-height: 60px;

  @media (max-width: 800px) {
    min-height: 40px;
    width: 100%;
    margin-top: 10px;
  }

  div.hasPrefix {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      margin-bottom: 0px;
      color: ${Colors.secondary70};
      font-size: 12px;
      margin-bottom: 4px;
      padding-left: 3px;
    }

    input {
      padding: 0px 0px 4px 3px;
    }

    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
  }

  background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};

  input {
    width: 100%;
    outline: 0;
    border: 0;
    background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};
    cursor: ${(props) => (props.isEditing ? "normal" : "not-allowed")};

    ${(props) =>
      !props.hasPrefix &&
      css`
        border-bottom: 2px solid
          ${props.error ? Colors.error20 : Colors.secondary20};
      `};

    color: ${Colors.secondary70};
    font-size: 12px;
    /* line-height: 20%; */
    padding: 0px 0px 4px 3px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    /* margin-bottom: 0px;
      margin-top: 23px; */
  }
`;

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    /* margin-bottom: 0px;
      margin-top: 23px; */
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 12px;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    margin-left: 4px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  /* padding-right: 32px; */
  margin-top: 52px;
  margin-bottom: 52px;
`;

export const ButtonRemove = styled.button`
  cursor: pointer;
  margin-right: 10px;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${Colors.white};
  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  color: ${Colors.primary100};
`;

interface ButtonEditProps {
  isLoading?: boolean;
}

export const ButttonEdit = styled.button<ButtonEditProps>`
  cursor: pointer;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${Colors.primary100};
  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;

  color: ${Colors.white};

  span {
    margin-left: ${(props) => (props.isLoading ? "5px" : 0)};
  }
`;

export const StyledSelect = styled(Select)`
  /* .ant-select-selector {
    border-color: none !important;
    box-shadow: none !important;
    height: 38px;
  } */
  /* color: #71E083 #E24949 #E2C049 #ACACAC  */
`;

export const EventLoading = styled.div`
  width: 48%;
  height: 194px;
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  padding: 12px;

  background: ${Colors.white};
  border: 1px solid ${Colors.border100};
  box-sizing: border-box;
  border-radius: 5px;

  @media (max-width: 900px) {
    min-width: 100%;
    width: 100%;
    /* height: 92px; */
  }

  @media (max-width: 516px) {
    /* height: 122px; */
  }

  div.first {
    img {
      width: 41px;
      height: 37px;
    }
  }

  div.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-direction: flex-start;
    margin-left: 12px;
    margin-top: 0px;

    head {
      display: flex;
      flex-direction: row;
      /* align-items: flex-start; */
      justify-content: space-between;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;

      h4 {
        font-weight: 600;
        font-size: 14px;
        /* line-height: 160%; */
        margin-top: 0px;
        margin-bottom: 0px;

        color: ${Colors.secondary80};
      }

      div.options {
        display: flex;
        flex-direction: row;

        div.area {
          cursor: pointer;
          width: 26px;
          height: 26px;
          margin-left: 12px;

          display: flex;
          align-items: center;
          justify-content: center;

          background: ${Colors.white};
          border: 1px solid ${Colors.border5};
          border-radius: 6px;

          img {
            width: 10px;
            height: 14px;
          }
        }
      }
    }

    div.second {
      display: flex;
      flex-direction: row;
      margin-top: 0px;

      @media (max-width: 516px) {
        flex-direction: column;
      }

      div.local {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 160%;

          color: ${Colors.secondary40};
        }
      }

      div.type {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};

        @media (max-width: 516px) {
          margin-left: 0px;
          padding-left: 0px;
          border-left: none;
        }
      }
    }

    div.third {
      display: flex;
      flex-direction: row;

      div.day {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }
      }

      div.time {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};
      }
    }
  }

  .icon {
    margin-left: 30px;
  }
`;
