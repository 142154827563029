import { useState } from "react";
import { Avatar, Container, ContainerItems, ContainerMobile } from "./styles";

import fa_bars from "assets/fa-bars.svg";
import exit_icon from "assets/exit-icon.svg";
import logo from "assets/logo.svg";

import { Drawer } from "antd";
import { SidebarMobile } from "components/SidebarMobile";
import { Colors } from "constants/colors";
import user_avatar from "assets/user-avatar.svg";
import { useAuth } from "contexts/AuthContext";

export function Header() {
  const { user } = useAuth();

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Container>
      <ContainerItems>
        <div className="image">
          {visible ? (
            <img className="side" src={exit_icon} alt="" onClick={onClose} />
          ) : (
            <img className="side" src={fa_bars} alt="" onClick={showDrawer} />
          )}

          <img className="logo" src={logo} alt="" />
        </div>

        <ContainerMobile>
          {/* <div className="notification">
            <div className="circle">
              <img src={notification} alt="" />
            </div>

            {false && <span className="badge">5</span>}
          </div> */}

          <Avatar src={user?.avatar ?? user_avatar} alt={user?.name} />
        </ContainerMobile>
      </ContainerItems>

      <Drawer
        width={287}
        placement="left"
        onClose={onClose}
        visible={visible}
        style={{ zIndex: 96, padding: 0, marginTop: 19 }}
        bodyStyle={{
          zIndex: 96,
          padding: 0,
          background: Colors.secondary100,
        }}
      >
        <SidebarMobile />
      </Drawer>
    </Container>
  );
}
