import { GatewayRegister } from "types";
import { Container, Gateway } from "./styles";


interface GatewayOrganizerProps {
  gateways: GatewayRegister[]
}

export function GatewayOrganizer({ gateways }: GatewayOrganizerProps) {
  return (
    <Container>
      {gateways?.length === 0 ? (
         <div className="empty">
          <p>Não ha Gateway registrado nesse organizador</p>
        </div>
      ): null}

      <ul>
       {gateways.map(gateway => (
        <Gateway>
          <h3>
            <strong>Id:</strong>{" "}{gateway._id}
          </h3>

          <h3>
            <strong>Gateway Id:</strong>{" "}{gateway.gateway_id}
          </h3>

          <h3>
            <strong>Taxa Organizador:</strong>{" "}{gateway.fee}
          </h3>

          <div>
            <h3><strong>Status: </strong>{" "}{gateway.status}</h3>
          </div>

          <div>
            <h3><strong>Tipo: </strong>{" "}{gateway.type}</h3>
          </div>

          <div>
            <h3><strong>Status do documento: </strong>{" "}{gateway.document_status ? "Enviado": "Não enviado"}</h3>
          </div>
        </Gateway>
       ))}
      </ul>
    </Container>
  )
}
