import { useCallback, useEffect, useState } from "react";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin } from "antd";

import {
  ButtonRemove,
  ButttonEdit,
  ContainerButtons,
  ContainerFormAddress,
  ContainerInput,
  FormFull,
  FormLabel,
  StyledSelect,
} from "./styles";

import { cep } from "utils/masks";

import { City, UserType } from "types";
import { useCitiesByUf } from "services/hooks/city";
import { ufs } from "constants/ufs";
import { Colors } from "constants/colors";
import { EditUserAddress, GetUsers } from "services/hooks/user";
import { GetAddressByZipCode } from "services/hooks/city";

const { Option } = Select;

type AddressFormData = {
  id: number;
  address_title: string;
  address_zipcode: string;
  address_country: string;
  address_district: string;
  address_state: string;
  address_city: string;
  address_street: string;
  address_number: string;
  address_complement: string;
};

const addressFormSchema = yup.object().shape({
  address_title: yup.string().required(),
  address_zipcode: yup.string().required(),
  address_country: yup.string().required(),
  address_district: yup.string().required(),
  address_state: yup.string().required(),
  address_city: yup.string().required(),
  address_street: yup.string().required(),
  address_number: yup.string().notRequired(),
  address_complement: yup.string().notRequired(),
});

interface AddAddressProps {
  isEditingAddress: boolean;
  setIsEditingAddress(value: boolean): void;
  setItsCreateAddress(value: boolean): void;
  address: AddressFormData;
  activeUser: UserType;
  setActiveUser(user: UserType): void;
  page: number;
  handleCloseEditAddress(): void;
  selectedAddress: AddressFormData | null;
}

export function EditAddress({
  isEditingAddress,
  setIsEditingAddress,
  setItsCreateAddress,
  address,
  activeUser,
  setActiveUser,
  page,
  handleCloseEditAddress,
  selectedAddress,
}: AddAddressProps) {
  const [stateByUserSelected, setStateByUserSelected] = useState("");

  const { data: dataCities } = useCitiesByUf<City[]>(
    stateByUserSelected ? stateByUserSelected : "SP"
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<AddressFormData>({
    resolver: yupResolver(addressFormSchema),
  });

  useEffect(() => {
    reset({ ...address });
  }, [address, reset]);

  const handleEditAddress: SubmitHandler<AddressFormData> = async ({
    address_title,
    address_zipcode,
    address_district,
    address_state,
    address_city,
    address_street,
    address_number,
    address_complement,
    address_country,
  }) => {
    try {
      const credentials = {
        address_title,
        address_zipcode,
        address_district,
        address_state,
        address_city,
        address_street,
        address_number,
        address_complement,
        address_country,
      };

      await EditUserAddress({
        userId: activeUser?._id,
        addressId: selectedAddress?.id,
        credentials,
      });

      const userByPage = await GetUsers({ page });

      const findUserActive = userByPage?.data.data.find(
        (user: UserType) => user._id === activeUser?._id
      );

      if (findUserActive) {
        setActiveUser(findUserActive);
      }

      setIsEditingAddress(false);
      setItsCreateAddress(false);

      alert(`Endereço editado com sucesso`);
    } catch (err) {}
  };

  const addressZipCode = watch("address_zipcode");

  const handleSearchAddress = useCallback(async () => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        try {
          const response = await GetAddressByZipCode({
            zipCode: addressZipCode,
          });

          const { logradouro, bairro, localidade, uf } = response.data;

          if (bairro === undefined) {
            setError("address_zipcode", {
              type: "manual",
              message: "Não foi possivel encontrar o Cep",
            });
            return;
          }
          clearErrors("address_zipcode");
          setValue("address_street", logradouro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_district", bairro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_city", localidade, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_state", uf, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } catch (err) {
          setError("address_zipcode", {
            type: "required",
            message: "Não foi possivel encontrar o Cep",
          });
        }
      }
    }
  }, [addressZipCode, clearErrors, setError, setValue]);

  useEffect(() => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        handleSearchAddress();
      }
    }
  }, [addressZipCode, handleSearchAddress]);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const handleKeyUpCep = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cep(e);
  }, []);

  return (
    <ContainerFormAddress onSubmit={handleSubmit(handleEditAddress)}>
      <FormFull>
        <ContainerInput isEditing={isEditingAddress}>
          <FormLabel>
            <h2>Titulo do endereço</h2>
          </FormLabel>

          <input {...register("address_title")} disabled={!isEditingAddress} />

          {errors.address_title ? (
            <span className="error">Insira um título valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditingAddress}>
          <FormLabel>
            <h2>CEP</h2>
          </FormLabel>

          <input
            {...register("address_zipcode")}
            onKeyUp={handleKeyUpCep}
            disabled={!isEditingAddress}
          />

          {errors.address_zipcode ? (
            <span className="error">Insira um cep valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditingAddress}>
          <FormLabel>
            <h2>Endereço</h2>
          </FormLabel>

          <input {...register("address_street")} disabled={!isEditingAddress} />

          {errors.address_street ? (
            <span className="error">Insira um endereço valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditingAddress}>
          <FormLabel>
            <h2>Número</h2>
          </FormLabel>

          <input {...register("address_number")} disabled={!isEditingAddress} />

          {errors.address_number ? (
            <span className="error">Insira um numero valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditingAddress}>
          <FormLabel>
            <h2>Complemento</h2>
          </FormLabel>

          <input
            {...register("address_complement")}
            disabled={!isEditingAddress}
          />
        </ContainerInput>

        <ContainerInput isEditing={isEditingAddress}>
          <FormLabel>
            <h2>Bairro</h2>
          </FormLabel>

          <input
            {...register("address_district")}
            disabled={!isEditingAddress}
          />

          {errors.address_district ? (
            <span className="error">Insira um Bairro valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditingAddress}>
          <FormLabel>
            <h2>Estado</h2>
          </FormLabel>

          <div className="select">
            <Controller
              control={control}
              name="address_state"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <StyledSelect
                  bordered={false}
                  labelInValue
                  placeholder="Escolha o estado"
                  value={value}
                  disabled={!isEditingAddress}
                  onChange={(e: any) => {
                    onChange(e.value);
                    setStateByUserSelected(e.value);
                  }}
                  style={{
                    width: "100%",
                    height: "38px",
                    border: "none",
                    outline: 0,
                  }}
                >
                  {ufs.map((uf) => {
                    return (
                      <Option value={uf.sigla} key={uf.id}>
                        {uf.sigla}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            />
          </div>
        </ContainerInput>

        <ContainerInput isEditing={isEditingAddress}>
          <FormLabel>
            <h2>Cidade</h2>
          </FormLabel>

          <div className="select">
            <Controller
              control={control}
              name="address_city"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <StyledSelect
                  bordered={false}
                  labelInValue
                  placeholder="Escolha a cidade"
                  value={value}
                  disabled={!isEditingAddress}
                  onChange={(e: any) => {
                    onChange(e.value);
                  }}
                  style={{
                    width: "100%",
                    height: "38px",
                    border: "none",
                    outline: 0,
                  }}
                >
                  {dataCities?.map((city) => {
                    return (
                      <Option value={city.nome} key={city.nome}>
                        {city.nome}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            />
          </div>
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditingAddress}>
          <FormLabel>
            <h2>País</h2>
          </FormLabel>

          <input
            {...register("address_country")}
            disabled={!isEditingAddress}
          />

          {errors.address_country ? (
            <span className="error">Insira um País valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <ContainerButtons>
        <ButtonRemove onClick={handleCloseEditAddress}>Cancelar</ButtonRemove>

        {isEditingAddress ? (
          <ButttonEdit type="submit" isLoading={isSubmitting}>
            <Spin spinning={isSubmitting} indicator={antIcon} />

            <span>Salvar</span>
          </ButttonEdit>
        ) : null}

        {!isEditingAddress ? (
          <ButttonEdit type="button" onClick={() => setIsEditingAddress(true)}>
            Editar
          </ButttonEdit>
        ) : null}
      </ContainerButtons>
    </ContainerFormAddress>
  );
}
