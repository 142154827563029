import { DatePicker } from "antd";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Container } from "./styles";

const dateFormat = "DD/MM/YYYY";

interface InputDateProps {
  label?: string;
  gteNameFilter?: string;
  lteNameFilter?: string;
}

export function InputDateFilter({
  label,
  gteNameFilter,
  lteNameFilter,
}: InputDateProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [dataGte, setDataGte] = useState<string | null>();
  const [dataLte, setDataLte] = useState<string | null>();

  function onChangeOne(_: any, dateString: any) {
    if (!dateString) {
      setDataGte("");

      searchParams.delete(gteNameFilter ?? "data_gte");

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
      });

      return;
    }

    const newDate: string = dateString.split("/");

    const dateComponent = `${newDate[2]}-${newDate[1]}-${newDate[0]}`;

    setDataGte(dateComponent);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      ...(gteNameFilter
        ? { [gteNameFilter]: dateComponent }
        : { data_gte: dateComponent }),
    });
  }

  function onChangeTwo(_: any, dateString: any) {
    if (!dateString) {
      setDataLte("");

      searchParams.delete(lteNameFilter ?? "data_lte");

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
      });

      return;
    }

    const newDate: string = dateString.split("/");

    const dateComponent = `${newDate[2]}-${newDate[1]}-${newDate[0]}`;

    setDataLte(dateComponent);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      ...(lteNameFilter
        ? { [lteNameFilter]: dateComponent }
        : { data_lte: dateComponent }),
    });
  }

  return (
    <Container>
      {!!label && <strong>{label}</strong>}

      <div className="range-pick">
        <DatePicker
          format={dateFormat}
          placeholder="00/00/0000"
          style={{
            width: "48%",

            padding: 0,
            paddingLeft: 2,
          }}
          bordered={false}
          suffixIcon={null}
          onChange={onChangeOne}
        />
        <div>-</div>
        <DatePicker
          format={dateFormat}
          placeholder="00/00/0000"
          style={{
            width: "48%",
            paddingRight: "0px",
          }}
          bordered={false}
          suffixIcon={null}
          onChange={onChangeTwo}
        />
      </div>
    </Container>
  );
}
