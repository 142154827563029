import { Colors } from "./colors";

export enum EAdminStatus {
  PENDING = "pending",
  REJECTED = "rejected",
  REQUIRED_CHANGES = "required_changes",
  AVAILABLE = "available",
}

export const ADMIN_STATUS = [
  {
    value: "pending",
    label: "Pendente",
  },
  {
    value: "rejected",
    label: "Rejeitado",
  },
  {
    value: "required_changes",
    label: "Alterações necessárias",
  },
  {
    value: "available",
    label: "Disponível",
  },
];

export const ADMIN_STATUS_COLORS = [
  {
    value: "pending",
    color: Colors.yellow50,
  },
  {
    value: "rejected",
    color: "#F94646",
  },
  {
    value: "required_changes",
    color: "#FF8A00",
  },
  {
    value: "available",
    color: "green",
  },
];
