import {
  AccountDropDownOrganizer,
  Button,
  Container,
  ContainerItems,
} from "./styles";
import { Sidebar } from "components/Sidebar";
import { Wrapper } from "./styles";
import { Header } from "components/Header";
import { Banners } from "./components/Banners";
import { BannersCategory } from "./components/BannersCategory";
import { HighlightedEvents } from "./components/HighlightedEvents";
import { LastEvents } from "./components/LastEvents";
import { BsPlus } from "react-icons/bs";
import { Colors } from "constants/colors";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";

export default function VisualPage() {
  const [activeDropOrganizer, setActiveDropOrganizer] = useState(false);

  let myRefHigh = useRef<HTMLDivElement>(null);
  let myRefBanner = useRef<HTMLDivElement>(null);
  let myRefCategory = useRef<HTMLDivElement>(null);
  let myRefLast = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const [isModalBannerVisible, setIsModalBannerVisible] = useState(false);

  const showModalBanner = () => {
    setIsModalBannerVisible(true);
    setActiveDropOrganizer(false);
  };

  const [isModalHighVisible, setIsModalHighVisible] = useState(false);

  const showModalHigh = () => {
    setIsModalHighVisible(true);
    setActiveDropOrganizer(false);
  };

  const [isModalCategoryVisible, setIsModalCategoryVisible] = useState(false);

  const showModalCategory = () => {
    setIsModalCategoryVisible(true);
    setActiveDropOrganizer(false);
  };

  useEffect(() => {
    if (location.hash && myRefCategory) {
      switch (location.hash) {
        case "#categories":
          window.scrollTo({
            behavior: "smooth",
            top: myRefCategory?.current?.offsetTop,
          });
          break;
        case "#banners":
          window.scrollTo({
            behavior: "smooth",
            top: myRefBanner?.current?.offsetTop,
          });
          break;
        case "#high":
          window.scrollTo({
            behavior: "smooth",
            top: myRefHigh?.current?.offsetTop,
          });
          break;

        default:
          break;
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <h1>Visual</h1>

          <h2 className="box">Caixa de ferramentas</h2>

          <div>
            <Button
              onClick={() => setActiveDropOrganizer(!activeDropOrganizer)}
            >
              <BsPlus color={Colors.white} size={23} />
              <span>Novo</span>

              <div className="icon">
                <AiFillCaretDown color={Colors.white} size={12} />
              </div>
            </Button>

            {activeDropOrganizer ? (
              <AccountDropDownOrganizer>
                <ul>
                  <li onClick={showModalBanner}>
                    <h5>Slider</h5>
                  </li>

                  <li className="last" onClick={showModalHigh}>
                    <h5>Eventos em destaque</h5>
                  </li>

                  {/* <li className="last" onClick={showModalCategory}>
                    <h5>Categoria</h5>
                  </li> */}
                </ul>
              </AccountDropDownOrganizer>
            ) : null}
          </div>

          <div style={{ marginTop: 20 }} ref={myRefHigh}>
            <HighlightedEvents
              title="Highlight"
              slug="highlight"
              showModal={showModalHigh}
              isModalVisible={isModalHighVisible}
              setIsModalVisible={setIsModalHighVisible}
            />
          </div>

          <div ref={myRefBanner}></div>
          <Banners
            title="Slider"
            slug="slider"
            showModal={showModalBanner}
            isModalVisible={isModalBannerVisible}
            setIsModalVisible={setIsModalBannerVisible}
          />

          <div ref={myRefCategory}>
            <BannersCategory
              title="Categorias"
              slug="categoria"
              showModal={showModalCategory}
              isModalVisible={isModalCategoryVisible}
              setIsModalVisible={setIsModalCategoryVisible}
            />
          </div>

          <div ref={myRefLast}></div>
          <LastEvents
              title="Últimos eventos"
              slug="last"
              showModal={showModalHigh}
              isModalVisible={isModalHighVisible}
              setIsModalVisible={setIsModalHighVisible}
            />
        </ContainerItems>
      </Container>
    </Wrapper>
  );
}
