import { Header } from "components/Header";
import { Sidebar } from "components/Sidebar";
import { Colors } from "constants/colors";
import { BsPlus } from "react-icons/bs";

import bell from "../../assets/bell.svg";
import points from "../../assets/points.svg";
import chatIcon from "../../assets/chatIcon.svg";
import moneyGreenIcon from "../../assets/moneyGreenIcon.svg";
import returnIcon from "../../assets/returnIcon.svg";
import checkGreenIcon from "../../assets/checkGreenIcon.svg";
import yellowIcon from "../../assets/yellowIcon.svg";
import exitRedIcon from "../../assets/exitRedIcon.svg";
import blueIcon from "../../assets/blueIcon.svg";

import {
  ButtonBorder,
  Container,
  ContainerItems,
  ListButtons,
  Wrapper,
  Button,
  Card,
  ContainerCard,
  Border,
  Circle,
} from "./styles";

import { GoChevronRight } from "react-icons/go";

export default function NotificationsPage() {
  const data = [
    { id: 0, type: "normal" },
    { id: 1, type: "green" },
    { id: 2, type: "exit" },
    { id: 3, type: "check" },
    { id: 4, type: "yellow" },
    { id: 5, type: "red" },
    { id: 6, type: "blue" },
  ];

  const renderTypeNotification = (type: string) => {
    switch (type) {
      case "normal":
        return (
          <Circle type={type}>
            <img src={chatIcon} alt="" />
          </Circle>
        );

      case "green":
        return (
          <Circle type={type}>
            <img src={moneyGreenIcon} alt="" />
          </Circle>
        );

      case "exit":
        return (
          <Circle type={type}>
            <img src={returnIcon} alt="" />
          </Circle>
        );

      case "check":
        return (
          <Circle type={type}>
            <img src={checkGreenIcon} alt="" />
          </Circle>
        );

      case "yellow":
        return (
          <Circle type={type}>
            <img src={yellowIcon} alt="" />
          </Circle>
        );

      case "red":
        return (
          <Circle type={type}>
            <img src={exitRedIcon} alt="" />
          </Circle>
        );

      case "blue":
        return (
          <Circle type={type}>
            <img src={blueIcon} alt="" />
          </Circle>
        );

      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <h1>Notificações</h1>

          <ContainerCard>
            <Card>
              <div className="header">
                <div className="first">
                  <img src={bell} alt="" />

                  <h2 className="title">Notificações</h2>
                </div>

                <div className="area">
                  <img src={points} alt="" />
                </div>
              </div>

              {data.map((item) => (
                <Border key={item.id}>
                  <div className="content">
                    {renderTypeNotification(item.type)}

                    <div className="column">
                      <h3>Nova mensagem</h3>

                      <h4>
                        Você recebeu uma nova mensagem de Jhon Doe, clique para
                        visualizar.
                      </h4>

                      <p>1 min atrás</p>
                    </div>
                  </div>

                  <div>
                    <div className="point"></div>
                  </div>
                </Border>
              ))}
            </Card>
          </ContainerCard>
        </ContainerItems>
      </Container>
    </Wrapper>
  );
}
