import { useCallback, useState } from "react";
import { useSWRConfig } from "swr";
import { useParams } from "react-router-dom";

import { BsPlus } from "react-icons/bs";

import { Colors } from "constants/colors";

import { UserAddress } from "types";
import { DeleteUserAddress } from "services/hooks/user";

import { useDisclosure } from "hooks/useDisclosure";

import {
  Address,
  AllEvents,
  ButtonAddNew,
  ContainerEvents,
  ListEvents,
} from "./styles";

import { Dropdown, DropDownContainer, LiStyled } from "styles/Dropdown";

import { ModalDelete } from "components/ModalDelete";

import points from "assets/points.svg";
import trash_red from "assets/trash-red.svg";
import pen_green from "assets/pen-green.svg";

type AddressFormData = {
  id: number;
  address_title: string;
  address_zipcode: string;
  address_country: string;
  address_district: string;
  address_state: string;
  address_city: string;
  address_street: string;
  address_number: string;
  address_complement: string;
};

interface ListAddressesProps {
  addresses: UserAddress[] | [];
  handleChangeItsEditAddress(value: boolean): void;
  handleChangeSelectedAddress(address: AddressFormData): void;
}

export function ListAddresses({
  addresses,
  handleChangeItsEditAddress,
  handleChangeSelectedAddress,
}: ListAddressesProps) {
  const [loadingRemoveAddress, setLoadingRemoveAddress] = useState(false);

  const [activeAddress, setActiveAddress] = useState<AddressFormData | null>(
    null
  );

  const { id } = useParams();

  const { mutate } = useSWRConfig();

  const {
    handleCancel: handleCancelDelete,
    isModalVisible: isModalVisibleDelete,
    showModal: showModalDelete,
  } = useDisclosure();

  const handleActiveAddress = useCallback(
    (address: AddressFormData) => {
      if (address.id === activeAddress?.id) {
        setActiveAddress(null);

        return;
      }

      setActiveAddress(address);
    },
    [activeAddress?.id]
  );

  const handleSelectedAddress = useCallback(
    async (address: AddressFormData) => {
      setActiveAddress(null);
      handleChangeItsEditAddress(true);
      handleChangeSelectedAddress(address);
    },
    [handleChangeItsEditAddress, handleChangeSelectedAddress]
  );

  const handleRemoveAddress = useCallback(async () => {
    try {
      setLoadingRemoveAddress(true);

      await DeleteUserAddress({ addressId: activeAddress?.id });

      setLoadingRemoveAddress(false);
      setActiveAddress(null);

      mutate(`/backoffice/user/${id}`);

      handleCancelDelete();

      alert(`Endereço excluido com sucesso`);
    } catch (err) {}
  }, [activeAddress?.id, handleCancelDelete, id, mutate]);

  return (
    <>
      <AllEvents>
        <ContainerEvents>
          <ListEvents>
            {addresses && addresses.length > 0 ? (
              addresses.map((address) => (
                <Address>
                  <div className="column">
                    <head>
                      <h4>{address.address_title ?? ""}</h4>

                      <div className="options">
                        <DropDownContainer>
                          <div
                            className="area"
                            onClick={() => handleActiveAddress(address)}
                          >
                            <img src={points} alt="" />
                          </div>

                          {activeAddress?.id === address.id ? (
                            <Dropdown height="110px">
                              <ul>
                                <LiStyled
                                  onClick={() => handleSelectedAddress(address)}
                                >
                                  <img src={pen_green} alt="pen" />
                                  <h5>Editar Endereço</h5>
                                </LiStyled>

                                <LiStyled
                                  className="last"
                                  onClick={showModalDelete}
                                >
                                  <img src={trash_red} alt="trash" />
                                  <h5>Excluir Endereço</h5>
                                </LiStyled>
                              </ul>
                            </Dropdown>
                          ) : null}
                        </DropDownContainer>
                      </div>
                    </head>

                    <div className="second">
                      <div className="local">
                        <span>
                          {`${address.address_street}, ${address.address_number}- ${address.address_complement}`}{" "}
                        </span>
                      </div>
                    </div>

                    <div className="second">
                      <div className="local">
                        <span>
                          {`${address.address_district}, ${address.address_city}`}
                        </span>
                      </div>
                    </div>

                    <div className="second">
                      <div className="local">
                        <span>{address.address_state}</span>
                      </div>
                    </div>

                    <div className="second">
                      <div className="local">
                        <span>{address.address_zipcode}</span>
                      </div>
                    </div>
                  </div>
                </Address>
              ))
            ) : (
              <div className="empty">
                <p>Não há endereços desse usuario</p>
              </div>
            )}
          </ListEvents>
        </ContainerEvents>
      </AllEvents>

      <ModalDelete
        isModalVisible={isModalVisibleDelete}
        handleCancel={handleCancelDelete}
        handleOk={handleRemoveAddress}
        loading={loadingRemoveAddress}
        text={`Você tem certeza que deseja excluir o Endereço “
         ${activeAddress?.address_title ?? ""}” ?`}
      />
    </>
  );
}
