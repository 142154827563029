import { useState } from "react";

import { Pagination } from "antd";

import Skeleton from "react-loading-skeleton";

import { TicketUserType } from "types";

import { useAuthTicketsUser } from "services/hooks/user";

import { ContainerEvents, EventLoading, ListEvents } from "./styles";

import { ModalTicket } from "components/ModalTicket";
import { StatusComponent } from "components/Status";

import points from "assets/points.svg";
import TicketCard from "./components/TicketCard";

interface TicketsUserInterface {
  results: TicketUserType[];
  count: number;
}

interface EventsUserProps {
  activeUserId: number | null;
}

export function TicketsUser({ activeUserId }: EventsUserProps) {
  const [activeTicket, setActiveTicket] = useState<TicketUserType | null>();
  const [
    isModalVisualizatingTicketVisible,
    setIsModalVisualizatingTicketVisible,
  ] = useState(false);

  const [page, setPage] = useState(1);

  const { data } = useAuthTicketsUser<TicketsUserInterface>({
    userId: activeUserId,
    page,
  });

  const onChange = (currentPage: number) => {
    setPage(currentPage);
  };

  function handleSelectedTicket(ticket: TicketUserType) {
    if (activeTicket?._id === ticket._id) {
      setActiveTicket(null);

      return;
    }

    setActiveTicket(ticket);
  }

  const showModalVisualizatingTicket = () => {
    setIsModalVisualizatingTicketVisible(true);
  };

  const handleVisualizatingTicketCancel = () => {
    setIsModalVisualizatingTicketVisible(false);
    setActiveTicket(null);
  };

  return (
    <ContainerEvents>
      <ListEvents>
        {!data?.results
          ? [0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
              <EventLoading>
                <div className="first">
                  <Skeleton height="100%" width={60} />
                </div>

                <div className="column">
                  <head>
                    <Skeleton height={18} width={60} />

                    <div className="options">
                      <StatusComponent color="green" content="ATIVO" />

                      <div className="area">
                        <img src={points} alt="" />
                      </div>
                    </div>
                  </head>

                  <div className="second">
                    <div className="local">
                      <Skeleton height={18} width={90} />
                    </div>
                  </div>
                </div>
              </EventLoading>
            ))
          : null}

        {data?.results && data?.results.length > 0
          ? data?.results.map((ticket) => (
              <TicketCard
                key={ticket._id}
                isActive={activeTicket?._id === ticket._id}
                id={ticket?.event_ticket_id}
                onHandleSelectedTicket={() => handleSelectedTicket(ticket)}
                onHandleShowModalVisualizatingTicket={showModalVisualizatingTicket}
                status={ticket.status}
                holderName={ticket.holder_name}
                holderCpf={ticket.holder_cpf}
                holderEmail={ticket.holder_email}
                eventId={ticket.event}
              />
            ))
          : null}

        {data?.results && data.results.length === 0 ? (
          <div className="empty">
            <p>Nenhum ingresso deste usuário</p>{" "}
          </div>
        ) : null}
      </ListEvents>

      {data?.results && data?.results.length > 0 ? (
        <div className="pagination">
          <Pagination
            current={page}
            onChange={onChange}
            total={data?.count}
          />
        </div>
      ) : null}

      {activeTicket ? (
        <ModalTicket
          isModalVisible={isModalVisualizatingTicketVisible}
          handleCancel={handleVisualizatingTicketCancel}
          handleOk={() => {}}
          data={activeTicket}
        />
      ) : null}
    </ContainerEvents>
  );
}
