import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 56px;
  background: ${Colors.secondary100};

  position: fixed;
  display: flex;
  margin: 0 auto;
  z-index: 99;

  @media (min-width: 1240px) {
    flex-direction: column;
    display: none;
  }
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;

  border: 2px solid ${Colors.purple60};

  z-index: 99;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 621px) {
    justify-content: space-between;
  }

  div.image {
    display: flex;
    flex-direction: row;
    align-items: center;

    img.logo {
      width: 97px;
      height: 24px;

      margin-left: 34px;
    }

    img.side {
      cursor: pointer;
      width: 14px;
      height: 13px;

      @media (min-width: 1240px) {
        display: none;
      }
    }
  }

  ul {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    div.items-menu {
      display: flex;
      flex-direction: row;
    }

    @media (max-width: 621px) {
      display: none;
      justify-content: "none";
    }
  }

  nav.first {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-left: 30px;

    @media (max-width: 642px) {
      padding-left: 5px;
    }

    .notification {
      border-radius: 2px;
    }

    .notification .badge {
      position: absolute;
      left: 184px;
      top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 13px;
      width: 13px;
      border-radius: 50%;
      background-color: red;
      background: ${Colors.primary100};
      font-weight: bold;
      font-size: 11px;
      color: ${Colors.white};

      @media (max-width: 642px) {
        left: 157px;
      }
    }

    div.circle {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${Colors.white};
    }
  }

  nav.last {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-left: 30px;

    @media (max-width: 724px) {
      padding-left: 5px;
    }

    div.circle {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  nav {
    cursor: pointer;
    margin-left: 12px;

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 13px;
      margin-bottom: 0px;
      color: ${Colors.white};
    }
  }
`;

export const ContainerMobile = styled.div`
  display: flex;
  flex-direction: row;

  .notification {
    border-radius: 2px;
  }

  .notification .badge {
    position: absolute;
    right: 109px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: red;
    background: ${Colors.primary100};
    font-weight: bold;
    font-size: 11px;
    color: ${Colors.white};
  }

  div.circle {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${Colors.white};
  }
`;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  background: ${Colors.gray30};
  border-radius: 50%;
  border: 2px solid ${Colors.white};
  box-sizing: border-box;
  border-radius: 72px;
`;
