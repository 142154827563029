// import { parseCookies, setCookie } from "nookies";
import axios from "axios";

const API_VERSION = "v1"

export function setupAuthClient() {
  // let cookies = parseCookies(ctx);
  const token = localStorage.getItem("Backoffice.token");

  const auth = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/${API_VERSION}`,
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //   "Content-Type": "aplication/json",
    // },
    // auth: {
    //   username: `${process.env.REACT_APP_USERNAME}`,
    //   password: `${process.env.REACT_APP_PASSWORD}`,
    // },
  });

  // auth.defaults.headers.common[
  //   "Authorization"
  // ] = `Bearer ${cookies["Eventx.token"]}`;

  auth.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  // axios.defaults.headers.patch["Access-Control-Allow-Origin"] = "*";

  return auth;
}
