interface paramsToStringInterface {
  _id?: string;
  name?: string;
  organizer?: string;
  organizer_name?: string;
  organizer_document?: string;
  simple_filter_id?: string;
  simple_filter_text?: string;
  data_lte?: string;
  data_gte?: string;
  value_lte?: string;
  value_gte?: string;
  created_gte?: string;
  created_lte?: string;
  begin_gte?: string;
  begin_lte?: string;
  event?: string;
  ticket_id?: string;
  type_document?: string;
  type_payment?: string;
  purchase_status?: string;
  status?: string;
  address_state?: string;
  address_city?: string;
  document?: string;
  type_event?: string;
  age?: string;
  privacity?: string;
  categories?: string;
  has_discount?: string;
  transfer?: string;
  organizer_id?: string;
  event_id?: string;
  verified?: string;
  type_ticket?: string;
  payment_type?: string;
  admin_status?: string;
}

export function paramsToString({
  _id,
  name,
  organizer,
  organizer_name,
  organizer_document,
  simple_filter_id,
  simple_filter_text,
  data_lte,
  data_gte,
  value_lte,
  value_gte,
  created_gte,
  created_lte,
  begin_gte,
  begin_lte,
  event,
  ticket_id,
  type_document,
  type_payment,
  purchase_status,
  status,
  address_state,
  address_city,
  document,
  type_event,
  age,
  privacity,
  categories,
  has_discount,
  transfer,
  organizer_id,
  event_id,
  verified,
  type_ticket,
  payment_type,
  admin_status,
}: paramsToStringInterface) {
  let params = {};

  if (type_ticket) {
    params = { ...params, type_ticket: type_ticket };
  }

  if (_id) {
    params = { ...params, _id };
  }

  if (name) {
    params = { ...params, name };
  }

  if (organizer) {
    params = { ...params, organizer };
  }

  if (organizer_name) {
    params = { ...params, organizer_name };
  }

  if (organizer_document) {
    params = { ...params, organizer_document };
  }

  if (simple_filter_id && simple_filter_text) {
    params = { ...params, [simple_filter_id]: simple_filter_text };
  }

  if (data_lte && data_gte) {
    params = { ...params, data_lte, data_gte };
  }

  if (value_lte && value_gte) {
    params = { ...params, value_lte, value_gte };
  }

  if (created_gte && created_lte) {
    params = { ...params, created_gte, created_lte };
  }

  if (begin_gte && begin_lte) {
    params = { ...params, begin_gte, begin_lte };
  }

  if (event) {
    params = { ...params, event };
  }

  if (ticket_id) {
    params = { ...params, ticket_id };
  }

  if (type_document) {
    params = { ...params, type_document };
  }

  if (type_payment) {
    params = { ...params, type_payment };
  }

  if (purchase_status) {
    params = { ...params, purchase_status };
  }

  if (status) {
    params = { ...params, status };
  }

  if (address_state) {
    params = { ...params, address_state };
  }

  if (address_city) {
    params = { ...params, address_city };
  }

  if (document) {
    params = { ...params, document };
  }

  if (type_event) {
    params = { ...params, type_event };
  }

  if (age) {
    params = { ...params, age };
  }

  if (privacity) {
    params = { ...params, privacity };
  }

  if (categories) {
    params = { ...params, categories };
  }

  if (has_discount) {
    params = { ...params, has_discount };
  }

  if (transfer) {
    params = { ...params, transfer };
  }

  if (organizer_id) {
    params = { ...params, organizer_id };
  }

  if (event_id) {
    params = { ...params, event_id };
  }

  if (verified) {
    params = { ...params, verified };
  }

  if (payment_type) {
    params = { ...params, payment_type };
  }

  if (admin_status) {
    params = { ...params, admin_status };
  }

  return params;
}
