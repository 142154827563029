import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-left: 8px;

  strong {
    color: ${Colors.white};
    font-weight: 500;
    font-size: 14px;
  }

  div.range-pick {
    border: 0;
    background: ${Colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 206px;
    height: 38px;
    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;
    padding-left: 18px;
    padding-right: 2px;

    @media (max-width: 503px) {
      width: 140px;
      padding-left: 8px;

      input {
        font-size: 10px;
      }
    }

    input {
      max-width: 78px;
      height: 100%;
      border: 0;
      background: transparent;
      outline: 0;

      @media (max-width: 503px) {
        width: 98%;
      }
      ::placeholder {
        color: ${Colors.purple30};
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: ${Colors.purple30};
      }

      ::-ms-input-placeholder {
        color: ${Colors.purple30};
      }
    }
  }

  div.range-prices {
    border: 0;
    background: ${Colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 206px;
    height: 38px;
    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;
    padding-left: 18px;
    padding-right: 16px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${Colors.purple30};

      span {
        margin-bottom: 0px;
      }

      @media (max-width: 720px) {
        font-size: 10px;
      }
    }

    @media (max-width: 503px) {
      width: 140px;
      padding-left: 8px;
      padding-right: 2px !important;

      input {
        font-size: 10px;
      }
    }

    input {
      max-width: 58px;
      height: 100%;
      border: 0;
      background: transparent;
      outline: 0;
      margin-left: 3px;
      color: ${Colors.purple30};
      margin-bottom: 0px;

      @media (max-width: 503px) {
        max-width: 48px;
      }

      ::placeholder {
        color: ${Colors.purple30};
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: ${Colors.purple30};
      }

      ::-ms-input-placeholder {
        color: ${Colors.purple30};
      }
    }

    input.first {
      max-width: 38px;
      color: ${Colors.purple30};
    }
  }

  input.normal {
    outline: none;
    background: ${Colors.white};
    border: 0;
    width: 206px;
    height: 38px;
    padding-left: 18px;
    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;
    color: ${Colors.purple30};

    @media (max-width: 503px) {
      width: 140px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  div.select {
    border: 0;
    width: 206px;
    height: 38px;
    outline: none;
    background: ${Colors.white};

    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;

    @media (max-width: 503px) {
      width: 140px;
    }
  }
`;
