import { Modal } from "components/Modal";
import { Colors } from "constants/colors";
import { useCallback, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import { authClient } from "services/authClient";
import { EventType } from "types";

import eye from "assets/eye-icon.svg";

import {
  ButtonAdd,
  ButtonCancel,
  ContainerButtons,
  ContainerEvents,
  ContainerModal,
  Event,
} from "./styles";
import moment from "moment";
import { useSWRConfig } from "swr";
import { EditStatusEvent } from "services/hooks/event";

interface ModalAddSliderProps {
  isModalVisible: boolean;
  setIsModalVisible(value: boolean): void;
  title: string;
}

export function ModalAddSlider({
  title,
  isModalVisible,
  setIsModalVisible,
}: ModalAddSliderProps) {
  const [loadingSearchEvent, setLoadingSearchEvent] = useState(false);
  const [textNameEvent, setTextNameEvent] = useState("");

  const [eventSelected, setEventSelected] = useState<EventType | null>(null);
  const [eventsFound, setEventsFound] = useState<EventType[] | null>(null);

  const { mutate } = useSWRConfig();

  const handleSearchEvent = useCallback(async () => {
    if (textNameEvent === "") {
      return;
    }

    try {
      setLoadingSearchEvent(true);

      const { data: dataEvent } = await authClient.get(
        `/backoffice/event/filter?page=1&limit=4&name=${textNameEvent}`
      );

      setLoadingSearchEvent(false);

      const dataResponse = dataEvent.results;

      const [event] = dataResponse;

      if (event) {
        setEventsFound(dataResponse);
        setEventSelected(event);
      } else {
        setEventsFound(null);
      }
    } catch (err) {
      setLoadingSearchEvent(false);
      setEventsFound(null);
    }
  }, [textNameEvent]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = useCallback(() => {
    setEventsFound(null);
    setTextNameEvent("");
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const handleAddEvent = useCallback(async () => {
    try {
      if (eventSelected?._id) {
        const credentials = {
          slider: "1",
        };

        await EditStatusEvent({ id: eventSelected?._id, credentials });

        mutate(`/backoffice/dashboard/slider-events?page=1&limit=20`);

        handleCancel();
        alert(`Evento ${eventSelected?.name} adicionado com sucesso`);
      }
    } catch (err) {}
  }, [eventSelected?._id, eventSelected?.name, handleCancel, mutate]);

  return (
    <Modal
      isModalVisible={isModalVisible}
      handleCancel={handleCancel}
      handleOk={handleOk}
    >
      <ContainerModal>
        <div className="content">
          <h2>{title}</h2>

          <p>Selecione o evento a ser adicionado a lista de “{title}”</p>
        </div>

        <div className="input">
          <input
            type="text"
            placeholder="Busque pelo nome do evento"
            value={textNameEvent}
            onChange={(e) => setTextNameEvent(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (textNameEvent !== "") {
                  handleSearchEvent();
                }

                return;
              }
            }}
          />

          <button className="search" onClick={handleSearchEvent}>
            <IoIosSearch color={Colors.secondary20} size={20} />
          </button>
        </div>

        {!eventsFound && !loadingSearchEvent ? (
          <div className="download-app">
            <h5>Nenhuma pré-visualização diponível</h5>

            <div className="row">
              <img src={eye} alt="" />
            </div>
          </div>
        ) : null}

        {loadingSearchEvent ? (
          <ContainerEvents>
            {[0, 1].map((item) => (
              <Event>
                <div className="column">
                  <Skeleton
                    height={156}
                    width={319}
                    style={{ borderRadius: "14px" }}
                  />
                  <Skeleton
                    height={24}
                    width={80}
                    style={{ marginTop: "14px" }}
                  />
                </div>
              </Event>
            ))}
          </ContainerEvents>
        ) : null}

        {eventsFound && !loadingSearchEvent ? (
          <ContainerEvents>
            {eventsFound?.map((event) => (
              <Event
                onClick={() => setEventSelected(event)}
                hasSelected={eventSelected?._id === event._id}
              >
                <div className="column">
                  {event.thumb !== null ? (
                    <img src={event?.thumb} alt="" />
                  ) : (
                    <div className="empty" />
                  )}
                  <h2>{event?.name}</h2>
                  <h5>
                    Acontece no dia:{" "}
                    {moment(event?.begin_date).utc().format("DD/MM/YYYY")}
                  </h5>
                </div>
              </Event>
            ))}
          </ContainerEvents>
        ) : null}

        <ContainerButtons>
          <ButtonCancel onClick={handleCancel}>
            <span>Cancelar</span>
          </ButtonCancel>
          <ButtonAdd
            disabled={eventsFound === null}
            hasEvent={eventsFound !== null}
            onClick={handleAddEvent}
          >
            <span>Adicionar Evento</span>
          </ButtonAdd>
        </ContainerButtons>
      </ContainerModal>
    </Modal>
  );
}
