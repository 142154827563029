import { useState } from "react";
import { Pagination } from "antd";
import Skeleton from "react-loading-skeleton";
import { useAuthPurchasesUser } from "services/hooks/user";
import { PurchaseUserType } from "types";
import { ContainerEvents, ListEvents } from "./styles";
import PurchaseCard from "./components/PurchaseCard";
import { Container as PurchaseSkeleton } from "./components/PurchaseCard/styles";

interface PurchasesUserInterface {
  results: PurchaseUserType[];
  count: number;
}

interface EventsUserProps {
  activeUserId?: string;
}

export function PurchasesUser({ activeUserId }: EventsUserProps) {
  const [page, setPage] = useState(1);
  const [activePurchase, setActivePurchase] =
    useState<PurchaseUserType | null>();

  const { data } = useAuthPurchasesUser<PurchasesUserInterface>({
    userId: activeUserId ?? '',
    page,
  });

  const onChange = (currentPage: number) => {
    setPage(currentPage);
  };

  const handleSelectedPurchase = (purchase: PurchaseUserType) => {
    if (purchase._id === activePurchase?._id) {
      setActivePurchase(null);

      return;
    }

    setActivePurchase(purchase);
  };

  return (
    <ContainerEvents>
      <ListEvents>
        {!data?.results
          ? [0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
              <PurchaseSkeleton>
                <div className="first">
                  <Skeleton height="100%" width={60} />
                </div>
              </PurchaseSkeleton>
            ))
          : null}

        {data?.results && data?.results.length > 0
          ? data?.results.map((purchase) => (
              <PurchaseCard
                id={purchase._id}
                eventId={purchase.event_id}
                status={purchase.payment_status}
                total={purchase.total}
                onSelectPurchase={() => handleSelectedPurchase(purchase)}
                isActivePurchase={activePurchase?._id === purchase._id}
              />
            ))
          : null}

        {data?.results && data.results.length === 0 ? (
          <div className="empty">
            <p>Nenhuma compra deste usuário</p>{" "}
          </div>
        ) : null}
      </ListEvents>

      {data?.results && data?.results.length > 0 ? (
        <div className="pagination">
          <Pagination
            current={page}
            onChange={onChange}
            total={data?.count}
          />
        </div>
      ) : null}
    </ContainerEvents>
  );
}
