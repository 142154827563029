import {
  forwardRef,
  ForwardRefRenderFunction,
  TextareaHTMLAttributes,
} from "react";

import { FieldError } from "react-hook-form";
import { Container, Label } from "./styles";

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  isEditing?: boolean;
  label?: string;
  error?: FieldError;
  isFull?: boolean;
}

export const TextAreaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  InputProps
> = ({ name, isEditing, label, error = null, isFull, ...rest }, ref) => {
  return (
    <Container isFull={isFull} isEditing={isEditing}>
      {!!label && (
        <Label>
          <h2>{label}</h2>
        </Label>
      )}

      <textarea name={name} {...rest} ref={ref} disabled={!isEditing} />

      {!!error ? <span className="error">{error.message}</span> : null}
    </Container>
  );
};

export const TextArea = forwardRef(TextAreaBase);
