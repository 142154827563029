"use";

import styled from "styled-components";

import { notification } from "antd";

import { BiCheck } from "react-icons/bi";
import { FiAlertCircle } from "react-icons/fi";
import { Colors } from "constants/colors";

// o estilo está nas cofigurações globais
const notificationStyle = {
  width: 440,
  minHeight: 49,
};

const notificationStyleSuccess = {
  width: 440,
  minHeight: 49,
  // marginTop: 64,
  borderLeft: `5px solid ${Colors.success}`,
};

const limitStyle = {
  width: 440,
  minHeight: 49,
  borderLeft: `5px solid ${Colors.yellow100}`,
};

const IconSuccess = styled.div`
  width: 34px;
  height: 34px;
  background: ${Colors.backgroundGreen};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconLimit = styled.div`
  width: 34px;
  height: 34px;
  background: ${Colors.yellow10};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.h4`
  font-weight: 600;
  font-size: 18px;
  color: ${Colors.secondary100};
  margin-bottom: 0px;
`;

const Description = styled.h4`
  font-weight: 400;
  font-size: 14px;
  color: ${Colors.black70};
  margin-bottom: 0px;
`;

export const successNotification = (
  title = "",
  description = "",
  duration = 4.5
) => {
  notification.success({
    message: <Message>{title}</Message>,
    description: <Description>{description}</Description>,
    style: notificationStyleSuccess,
    duration,
    icon: (
      <IconSuccess>
        <BiCheck size={22} color={Colors.success} />
      </IconSuccess>
    ),
  });
};

export const limitNotification = (
  title = "",
  description = "",
  duration = 4.5
) => {
  notification.warning({
    message: <Message>{title}</Message>,
    description: <Description>{description}</Description>,
    style: limitStyle,
    duration,
    icon: (
      <IconLimit>
        <FiAlertCircle size={22} color={Colors.yellow100} />
      </IconLimit>
    ),
  });
};

export const infoNotification = (title = "", description = "") => {
  notification.info({
    message: title,
    description,
    style: notificationStyle,
  });
};

export const warningNotification = (title = "", description = "") => {
  notification.warning({
    message: title,
    description,
    style: notificationStyle,
  });
};

export const errorNotification = (title = "", description = "") => {
  notification.error({
    message: title,
    description,
    style: notificationStyle,
  });
};
