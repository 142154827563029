import { Select } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div``;

interface HeaderFilterProps {
  filterAdvancedIsActive: boolean;
}

export const HeaderFilter = styled.div<HeaderFilterProps>`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: ${Colors.secondary80};
  padding-right: 28px;

  @media (max-width: 1050px) {
    display: none;
  }

  h6 {
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: ${Colors.white};

    strong {
      font-weight: bold;
    }
  }

  div.first {
    display: flex;
    flex-direction: row;
    height: 32px;

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      background: ${Colors.white};

      height: 32px;
      border: 1px solid ${Colors.border};
      border-radius: 5px 0px 0px 5px;
      margin-left: 32px;

      @media (max-width: 1100px) {
        width: 80%;
      }

      div.dropdown {
        width: 166px;
        height: 32px;
        cursor: pointer;
        border-right: 1px solid ${Colors.border};
        display: flex;
        flex-direction: column;
        align-items: center;

        z-index: 3;

        @media (max-width: 1100px) {
        }

        div.icon {
          margin-top: 4px;
        }

        @media (max-width: 1300px) {
          align-items: center;
          justify-content: center;

          div.icon {
            display: none;
          }

          div.text {
            display: none;
          }
        }

        h3 {
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;

          margin-top: 6px;
          color: ${Colors.secondary40};

          @media (max-width: 1304px) {
            font-size: 10px;
          }

          @media (max-width: 1100px) {
            font-size: 8px;
          }
        }
      }

      div.clear {
        width: 15px;
        display: ${(props) => (props.filterAdvancedIsActive ? "none" : "")};
      }

      div.searchInput {
        width: 301px;
        height: 100%;

        display: ${(props) => (props.filterAdvancedIsActive ? "none" : "")};

        input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;

          padding-left: 11px;
          caret-color: ${Colors.primary100};

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;

          ::placeholder {
            color: ${Colors.gray};
          }

          color: ${Colors.gray};
        }
      }
    }

    div.search {
      cursor: pointer;

      display: ${(props) => (props.filterAdvancedIsActive ? "none" : "flex")};

      align-items: center;
      justify-content: center;
      width: 46px;
      border-radius: 0px 5px 5px 0px;
      height: 100%;

      background: ${Colors.tertiary100};
    }
  }
`;

export const AdvancedFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 168px;
  background: ${Colors.secondary70};
  padding-left: 22px;
  padding-right: 30px;
`;

export const FirstRow = styled.div`
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: repeat(4, minmax(6rem, 1fr));
  /* column-gap: 0.45rem;
  row-gap: 1.5rem; */

  @media (max-width: 990px) {
    grid-template-columns: repeat(3, minmax(6rem, 1fr));
  }

  @media (max-width: 740px) {
    grid-template-columns: repeat(2, minmax(6rem, 1fr));
  }

  @media (max-width: 281px) {
    grid-template-columns: repeat(1, minmax(6rem, 1fr));
  }

  margin-top: 8px;
`;

interface HeaderFiltersMobileProps {
  filterAdvancedIsActive: boolean;
}

export const HeaderFiltersMobile = styled.div<HeaderFiltersMobileProps>`
  width: 100%;
  min-height: 24px;

  background: ${Colors.secondary80};
  border-radius: 5px 5px 0px 0px;
  padding-left: 16px;
  padding-right: 20px;

  @media (min-width: 1050px) {
    display: none;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 32px;

    nav {
      width: 100%;
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      background: ${Colors.white};

      margin-top: 16px;

      border: 1px solid ${Colors.border};
      border-radius: 5px 0px 0px 5px;

      div.dropdown {
        width: 40%;
        height: 32px;
        cursor: pointer;
        border-right: 1px solid ${Colors.border};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 2px 0px 12px;

        @media (max-width: 1300px) {
          width: 20%;
        }

        @media (max-width: 1100px) {
          width: 20%;
          svg {
            width: 20px;
            height: 20px;
          }
        }

        div.icon {
          margin-top: 4px;
        }

        @media (max-width: 1300px) {
          align-items: center;
          justify-content: center;

          div.icon {
            display: none;
          }

          div.text {
            display: none;
          }
        }

        h3 {
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;

          margin-top: 6px;
          color: ${Colors.secondary40};

          @media (max-width: 1304px) {
            font-size: 10px;
          }

          @media (max-width: 1100px) {
            font-size: 8px;
          }
        }
      }

      div.searchInput {
        width: 100%;
        height: 100%;

        input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;

          padding-left: 18px;
          caret-color: ${Colors.primary100};

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;

          ::placeholder {
            color: ${Colors.gray};
          }

          color: ${Colors.gray};
        }
      }
    }

    div.search {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-top: 16px;
      border-radius: 0px 5px 5px 0px;
      height: 100%;
      background: ${Colors.tertiary100};
    }
  }

  div.select {
    height: 32px;
    width: 100%;
    background: ${Colors.white};
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: ${Colors.secondary40};
      margin-bottom: 0px;
    }
  }

  div.footer-informations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${(props) => (props.filterAdvancedIsActive ? "14px" : "29px")};

    p.quantity {
      font-weight: 400;
      font-size: 11px;
      color: ${Colors.white};
    }
  }
`;

export const StyledSelect = styled(Select)``;
