import dashIcon from "assets/dashIcon.svg";
import dash_active from "assets/dash-active.svg";
import usersIcon from "assets/usersIcon.svg";
import user_active from "assets/user-active.svg";
import organizerIcon from "assets/organizerIcon.svg";
import organizer_active from "assets/organizer-active.svg";
import eventsIcon from "assets/eventsIcon.svg";
import event_active from "assets/event-active.svg";
import ticketsIcon from "assets/ticketsIcon.svg";
import ticket_active from "assets/ticket-active.svg";
import vendasIcon from "assets/vendasIcon.svg";
import venda_active from "assets/venda-active.svg";
import faqIcon from "assets/faqIcon.svg";
import faq_active from "assets/faq-active.svg";
import visualIcon from "assets/visualIcon.svg";
import visual_active from "assets/visual-active.svg";

export const navArray = [
  {
    title: "Dashboard",
    link: "/",
    icon: dashIcon,
    iconActive: dash_active,
  },
  {
    title: "Usuários",
    link: "/users",
    icon: usersIcon,
    iconActive: user_active,
  },
  {
    title: "Organizadores",
    link: "/organizers",
    icon: organizerIcon,
    iconActive: organizer_active,
  },
  {
    title: "Eventos",
    link: "/events",
    icon: eventsIcon,
    iconActive: event_active,
  },
  {
    title: "Ingressos",
    link: "/tickets",
    icon: ticketsIcon,
    iconActive: ticket_active,
  },
  {
    title: "Vendas",
    link: "/sales",
    icon: vendasIcon,
    iconActive: venda_active,
  },
  {
    title: "FAQ",
    link: "/faq",
    icon: faqIcon,
    iconActive: faq_active,
  },
  {
    title: "Visual",
    link: "/visual",
    icon: visualIcon,
    iconActive: visual_active,
  },
];
