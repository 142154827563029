import { Colors } from "../../constants/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerItems = styled.div`
  max-width: 1080px;
  width: 100%;
  padding: 60px 30px 0px 30px;
  margin-bottom: 120px;

  @media (max-width: 1240px) {
    padding: 104px 30px 0px 0px;
  }

  @media (max-width: 1120px) {
    padding: 104px 30px 0px 30px;
  }

  @media (max-width: 768px) {
    padding: 104px 16px 0px 30px;
  }

  @media (max-width: 621px) {
    padding: 104px 16px 0px 30px;
  }

  @media (max-width: 621px) {
    padding: 104px 16px 0px 30px;

    h2.box {
      display: none;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    color: ${Colors.secondary90};
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: ${Colors.secondary80};
    margin-top: 32px;
  }
`;

export const Button = styled.button`
  border: 0;
  cursor: pointer;
  outline: 0;
  width: 137px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 33px;

  background: ${Colors.primary100};
  border-radius: 97px;

  z-index: 6 !important;

  div.icon {
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 11px;
    border-left: 1px solid #b30446;
    margin-left: 14px;
  }

  span {
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    color: ${Colors.white};
  }
`;

export const Card = styled.div`
  width: 100%;
  min-height: 340px;
  padding: 34px 34px;
  display: flex;
  flex-direction: row;

  border: 1px solid ${Colors.border};

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    padding: 0px 0px;
    border: 0;
  }

  @media (max-width: 621px) {
    min-height: 140px;
  }

  background: ${Colors.white};

  border-radius: 8px;
  margin-top: 32px;
`;

export const SidebarFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  @media (max-width: 621px) {
    display: none;
  }

  div.card-filter {
    cursor: pointer;
    width: 192px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 28px;

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;

      display: flex;
      align-items: center;

      color: ${Colors.secondary70};
    }

    span.active {
      font-weight: bold;
    }
  }

  div.active {
    background: ${Colors.gray80};
  }
`;

export const ContainerResults = styled.div`
  padding-left: 47px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 24px;
    margin-top: 25px;
  }

  @media (max-width: 621px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-left: 0px;

    overflow-y: scroll;
  }
`;

export const CardResult = styled.div`
  width: 194px;
  height: 117px;
  display: flex;
  flex-direction: column;
  background: ${Colors.border90};
  border: 0.6px solid ${Colors.border70};
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 18px;
  padding: 12px 19px 0px 16px;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }

  @media (max-width: 621px) {
    margin-bottom: 10px;
    min-width: 194px;
    min-height: 117px;
    margin-right: 20px;
  }

  div.first-information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div.content {
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        font-weight: normal;
        font-size: 18px;
        line-height: 100%;
        color: ${Colors.secondary70};
        margin-left: 8px;
        margin-bottom: 0px;
      }

      div.circle {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(111, 92, 158, 0.1);
      }
    }
  }

  div.second-information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 23px;
    margin-left: 10px;

    h4 {
      font-weight: 600;
      font-size: 36px;

      line-height: 8px;
      color: ${Colors.secondary70};
      margin-bottom: 6px;
    }

    span.available {
      font-weight: bold;
      font-size: 9px;
      line-height: 12px;
      text-transform: uppercase;

      color: ${Colors.black4};
      opacity: 0.75;
      margin-top: 6px;
    }

    span.danger {
      font-weight: 600;
      font-size: 11px;
      line-height: 100%;

      color: ${Colors.red30};
      margin-bottom: 20px;
    }
  }
`;

export const ContainerGraphs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 340px;

  background: ${Colors.white};
  margin-top: 56px;

  @media (max-width: 621px) {
    flex-direction: column;
  }
`;

export const Graph = styled.div`
  width: 48%;
  height: 457px;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid ${Colors.border};

  @media (max-width: 621px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const AccountDropDownOrganizer = styled.div`
  display: block;
  position: absolute;
  background-color: ${Colors.white};
  width: 304px;
  height: 120px;

  /* margin-left: 20px; */
  z-index: 3;
  margin-top: 3px;

  border-radius: 0px 0px 14px 14px;

  border: 1px solid ${Colors.border10};

  box-shadow: 0px 18px 33px rgba(108, 93, 211, 0.1);
  border-radius: 0px 0px 14px 14px;

  @media (max-width: 621px) {
    width: 240px;
  }

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      h5 {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;

        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        color: #000000;
      }
    }

    li.last {
      border: 0;
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;
