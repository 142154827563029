import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerEvents = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 39px 65px 28px 56px;

  @media (max-width: 768px) {
    padding: 0px 28px 40px 28px;
    margin-top: 0px;
  }

  div.pagination {
    margin-top: 20px;
  }
`;

export const ListEvents = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 200px;

  @media (max-width: 823px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  div.empty {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${Colors.secondary80};
      font-weight: 500;
      font-size: 15px;
    }
  }
`;

// export const Event = styled.div`
//   min-width: 300px;

//   height: 66px;
//   margin-top: 26px;

//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   /* flex-wrap: wrap; */
//   justify-content: space-between;
//   padding: 10px;

//   background: ${Colors.white};
//   border: 1px solid ${Colors.border100};
//   box-sizing: border-box;
//   border-radius: 5px;
//   padding-right: 14px;
//   padding-left: 16px;

//   @media (max-width: 723px) {
//     min-width: 100%;
//     width: 100%;
//     height: 80px;
//   }

//   div.first {
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     @media (max-width: 723px) {
//       align-items: flex-start;
//       flex-direction: column;
//     }
//   }

//   h3 {
//     color: ${Colors.secondary80};
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 100%;
//     margin-bottom: 0px;
//   }

//   div.column {
//     display: flex;
//     flex-direction: column;
//     margin-left: 32px;
//     padding-right: 20px;
//     /* align-items: center;
//     justify-content: center; */

//     @media (max-width: 840px) {
//       margin-left: 0px;
//       margin-top: 8px;
//     }

//     span {
//       color: ${Colors.secondary80};

//       font-weight: 500;
//       font-size: 10px;
//     }
//   }

//   .icon {
//     margin-left: 30px;
//   }
// `;
