import { useCallback, useEffect, useState } from "react";
import {
  ButtonRemove,
  ButttonEdit,
  ContainerButtons,
  ContainerFormAddress,
  ContainerInput,
  FormFull,
  FormLabel,
  StyledSelect,
} from "./styles";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";

import { Spin, Select } from "antd";
import { City, UserType } from "types";
import { Colors } from "constants/colors";
import { cep } from "utils/masks";
import { ufs } from "constants/ufs";
import { GetAddressByZipCode, useCitiesByUf } from "services/hooks/city";

import { useSWRConfig } from "swr";
import { CreateUserAddress } from "services/hooks/user";

const { Option } = Select;

type AddressFormData = {
  id: number;
  address_title: string;
  address_zipcode: string;
  address_country: string;
  address_district: string;
  address_state: string;
  address_city: string;
  address_street: string;
  address_number: string;
  address_complement: string;
};

const addressFormSchema = yup.object().shape({
  address_title: yup.string().required(),
  address_zipcode: yup.string().required(),
  address_country: yup.string().required(),
  address_district: yup.string().required(),
  address_state: yup.string().required(),
  address_city: yup.string().required(),
  address_street: yup.string().required(),
  address_number: yup.string().notRequired(),
  address_complement: yup.string().notRequired(),
});

interface AddAddressProps {
  userId?: number | null;
  page: number;
  setItsCreateAddress(value: boolean): void;
  setActiveUser(user: UserType): void;
}

export function AddAddress({
  userId,
  page,
  setItsCreateAddress,
  setActiveUser,
}: AddAddressProps) {
  const [stateByUserSelected, setStateByUserSelected] = useState("");

  const { mutate } = useSWRConfig();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    control: control1,
    setValue,
    setError,
    watch,
    formState: { errors: errors1, isSubmitting: isSubmitting1 },
  } = useForm<AddressFormData>({
    resolver: yupResolver(addressFormSchema),
  });

  const { data: dataCities } = useCitiesByUf<City[]>(
    stateByUserSelected ? stateByUserSelected : "SP"
  );

  const handleAddAddress: SubmitHandler<AddressFormData> = async ({
    address_title,
    address_zipcode,
    address_district,
    address_state,
    address_city,
    address_street,
    address_number,
    address_complement,
    address_country,
  }) => {
    try {
      const credentials = {
        address_title,
        address_zipcode,
        address_district,
        address_state,
        address_city,
        address_street,
        address_number,
        address_complement,
        address_country,
      };

      CreateUserAddress({ userId, credentials });

      mutate(`/backoffice/users/${userId}`);

      setItsCreateAddress(false);

      reset1();
      alert(`Endereço adicionado com sucesso`);
    } catch (err) {}
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const handleKeyUpCep = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cep(e);
  }, []);

  const addressZipCode = watch("address_zipcode");

  const handleSearchAddress = useCallback(async () => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        try {
          const response = await GetAddressByZipCode({
            zipCode: addressZipCode,
          });

          const { logradouro, bairro, localidade, uf } = response.data;

          if (bairro === undefined) {
            setError("address_zipcode", {
              type: "manual",
              message: "Não foi possivel encontrar o Cep",
            });
            return;
          }
          setValue("address_street", logradouro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_district", bairro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_city", localidade, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_state", uf, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } catch (err) {
          setError("address_zipcode", {
            type: "required",
            message: "Não foi possivel encontrar o Cep",
          });
        }
      }
    }
  }, [addressZipCode, setError, setValue]);

  useEffect(() => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        handleSearchAddress();
      }
    }
  }, [addressZipCode, handleSearchAddress]);

  return (
    <ContainerFormAddress onSubmit={handleSubmit1(handleAddAddress)}>
      <FormFull>
        <ContainerInput isEditing>
          <FormLabel>
            <h2>Titulo do endereço</h2>
          </FormLabel>

          <input {...register1("address_title")} />

          {errors1.address_title ? (
            <span className="error">Insira um título valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing>
          <FormLabel>
            <h2>CEP</h2>
          </FormLabel>

          <input {...register1("address_zipcode")} onKeyUp={handleKeyUpCep} />

          {errors1.address_zipcode ? (
            <span className="error">Insira um cep valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing>
          <FormLabel>
            <h2>Endereço</h2>
          </FormLabel>

          <input {...register1("address_street")} />

          {errors1.address_street ? (
            <span className="error">Insira um endereço valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing>
          <FormLabel>
            <h2>Número</h2>
          </FormLabel>

          <input {...register1("address_number")} />

          {errors1.address_number ? (
            <span className="error">Insira um numero valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing>
          <FormLabel>
            <h2>Complemento</h2>
          </FormLabel>

          <input {...register1("address_complement")} />
        </ContainerInput>

        <ContainerInput isEditing>
          <FormLabel>
            <h2>Bairro</h2>
          </FormLabel>

          <input {...register1("address_district")} />

          {errors1.address_district ? (
            <span className="error">Insira um Bairro valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing>
          <FormLabel>
            <h2>Estado</h2>
          </FormLabel>

          <div className="select">
            <Controller
              control={control1}
              name="address_state"
              rules={{ required: "Salutation is required" }}
              // disabled={isSubmitting}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <StyledSelect
                  bordered={false}
                  labelInValue
                  placeholder="Escolha o estado"
                  value={value}
                  onChange={(e: any) => {
                    onChange(e.value);
                    setStateByUserSelected(e.value);
                  }}
                  style={{
                    width: "100%",
                    height: "38px",
                    border: "none",
                    outline: 0,
                  }}
                >
                  {ufs.map((uf) => {
                    return (
                      <Option value={uf.sigla} key={uf.id}>
                        {uf.sigla}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            />
          </div>

          {errors1.address_state ? (
            <span className="error">Insira um Estado valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing>
          <FormLabel>
            <h2>Cidade</h2>
          </FormLabel>

          <div className="select">
            <Controller
              control={control1}
              name="address_city"
              rules={{ required: "Salutation is required" }}
              // disabled={isSubmitting}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <StyledSelect
                  bordered={false}
                  labelInValue
                  placeholder="Escolha a cidade"
                  value={value}
                  onChange={(e: any) => {
                    onChange(e.value);
                  }}
                  style={{
                    width: "100%",
                    height: "38px",
                    border: "none",
                    outline: 0,
                  }}
                >
                  {dataCities?.map((city) => {
                    return (
                      <Option value={city.nome} key={city.nome}>
                        {city.nome}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            />
          </div>

          {errors1.address_city ? (
            <span className="error">Insira uma cidade válida</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing>
          <FormLabel>
            <h2>País</h2>
          </FormLabel>

          <input {...register1("address_country")} defaultValue="BR" />

          {errors1.address_country ? (
            <span className="error">Insira um País valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <ContainerButtons>
        <ButtonRemove onClick={() => setItsCreateAddress(false)}>
          Cancelar
        </ButtonRemove>

        <ButttonEdit type="submit" isLoading={isSubmitting1}>
          <Spin spinning={isSubmitting1} indicator={antIcon} />

          <span>Salvar</span>
        </ButttonEdit>
      </ContainerButtons>
    </ContainerFormAddress>
  );
}
