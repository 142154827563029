import { Select } from "antd";
import { Colors } from "constants/colors";
import { BiSearch } from "react-icons/bi";

import {
  Container,
  Card,
  HeaderFilter,
  HeaderFiltersMobile,
  Tr,
  ContainerItemsMobile,
  CardMobile,
  List,
  Item,
  AdvancedFilters,
  FirstRow,
  ContainerInput,
  StyledSelect,
  LinkStyled,
  LinkStyledMobile,
} from "./styles";

import { ufs } from "constants/ufs";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useCallback, useEffect, useState } from "react";
import { City, FAQType } from "types";
import Skeleton from "react-loading-skeleton";
import { useCitiesByUf } from "services/hooks/city";
import { dateRange } from "utils/masks";
import { IoCloseCircle } from "react-icons/io5";
import { StatusComponent } from "components/Status";
import { categoryFaq } from "utils/faq";

const { Option } = Select;

interface TableProps {
  data?: FAQType[];
  setHasSelected?(value: boolean): void;
  setHasSelectedUser?(value: boolean): void;
  setActiveEvent(value: FAQType | null): void;
  activeEvent: FAQType | null;
  filterId: string;
  filterValue: string;
  setFilterId(value: string): void;
  setFilterValue(value: string): void;
  filterText: string;
  setFilterText(value: string): void;
  handleSearchFilter(): void;
  filterAdvancedIsActive: boolean;
  setFilterAdvancedIsActive(value: boolean): void;
  idEvent: string;
  setIdEvent(value: string): void;
  nameEvent: string;
  setNameEvent(value: string): void;
  nameOrganizerEvent: string;
  setNameOrganizerEvent(value: string): void;
  createdGteEvent: string;
  setCreatedGteEvent(value: string): void;
  createdLteEvent: string;
  setCreatedLteEvent(value: string): void;
  beginGteEvent: string;
  setBeginGteEvent(value: string): void;
  beginLteEvent: string;
  setBeginLteEvent(value: string): void;
  documentOrganizerEvent: string;
  setDocumentOrganizerEvent(value: string): void;
  addressStateEvent: string;
  setAddressStateEvent(value: string): void;
  addressCityEvent: string;
  setAddressCityEvent(value: string): void;
  typeEvent: string;
  setTypeEvent(value: string): void;
  ageEvent: string;
  setAgeEvent(value: string): void;
  privacityEvent: string;
  setPrivacityEvent(value: string): void;
  categoryEvent: string;
  setCategoryEvent(value: string): void;
  statusEvent: string | boolean;
  setStatusEvent(value: string | boolean): void;
  handleResetSearchFilter(): void;
  total: number | null;
}

export function Table({
  data,
  setHasSelected,
  setHasSelectedUser,
  setActiveEvent,
  activeEvent,
  total,
  setFilterAdvancedIsActive,
  setFilterId,
  setFilterValue,
  setFilterText,
  filterText,
  handleSearchFilter,
  filterAdvancedIsActive,
  filterValue,
  idEvent,
  setIdEvent,
  nameEvent,
  setNameEvent,
  nameOrganizerEvent,
  setNameOrganizerEvent,
  createdGteEvent,
  setCreatedGteEvent,
  createdLteEvent,
  setCreatedLteEvent,
  beginGteEvent,
  setBeginGteEvent,
  beginLteEvent,
  setBeginLteEvent,
  documentOrganizerEvent,
  setDocumentOrganizerEvent,
  addressStateEvent,
  setAddressStateEvent,
  addressCityEvent,
  setAddressCityEvent,
  typeEvent,
  setTypeEvent,
  ageEvent,
  setAgeEvent,
  privacityEvent,
  setPrivacityEvent,
  categoryEvent,
  setCategoryEvent,
  statusEvent,
  setStatusEvent,
  handleResetSearchFilter,
}: TableProps) {
  const { data: dataCities } = useCitiesByUf<City[]>("SP");

  function handleChange(value: any) {
    if (value.value === "Filtros avançados") {
      setFilterAdvancedIsActive(true);

      return;
    }

    setFilterId(value.key);
    setFilterValue(value?.value);

    if (filterText) {
      setFilterText("");
    }

    handleSearchFilter();

    setFilterAdvancedIsActive(false);
  }

  const handleSelectedUser = useCallback(
    (event: any, active: boolean) => {
      if (active) {
        setActiveEvent(event);

        setHasSelected?.(true);

        return;
      }

      setActiveEvent(null);

      setHasSelected?.(false);
    },
    [setActiveEvent, setHasSelected]
  );

  const handleChangeType = (value: any) => {
    setTypeEvent(value.value);
  };

  const handleChangeAge = (value: any) => {
    setAgeEvent(value.value);
  };

  const handleChangeStatus = (value: any) => {
    setStatusEvent(value.value);
  };

  const handleChangePrivacity = (value: any) => {
    setPrivacityEvent(value.value);
  };

  const handleChangeUf = (value: any) => {
    setAddressStateEvent(value.value);
    setAddressCityEvent("");
  };

  const handleChangeCity = (value: any) => {
    setAddressCityEvent(value.value);
  };

  const handleChangeCategory = (value: any) => {
    setCategoryEvent(value.value);
  };

  const handleKeyUpDate = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      dateRange(e);
    },
    []
  );

  const [tempCreatedGteEvent, setTempCreatedGteEvent] = useState("");
  const [tempCreatedLteEvent, setTempCreatedLteEvent] = useState("");

  useEffect(() => {
    if (
      tempCreatedGteEvent.length === 10 &&
      tempCreatedLteEvent.length === 10
    ) {
      setCreatedGteEvent(tempCreatedGteEvent);
      setCreatedLteEvent(tempCreatedLteEvent);
    }
  }, [
    setCreatedGteEvent,
    setCreatedLteEvent,
    tempCreatedGteEvent,
    tempCreatedLteEvent,
  ]);

  const removeFiltersDataCreated = () => {
    setCreatedGteEvent("");
    setCreatedLteEvent("");
  };

  const [tempBeginGteEvent, setTempBeginGteEvent] = useState("");
  const [tempBeginLteEvent, setTempBeginLteEvent] = useState("");

  useEffect(() => {
    if (tempBeginGteEvent.length === 10 && tempBeginLteEvent.length === 10) {
      setBeginGteEvent(tempCreatedGteEvent);
      setBeginLteEvent(tempCreatedLteEvent);
    }
  }, [
    setBeginGteEvent,
    setBeginLteEvent,
    tempBeginGteEvent.length,
    tempBeginLteEvent.length,
    tempCreatedGteEvent,
    tempCreatedLteEvent,
  ]);

  const removeFiltersBeginData = () => {
    setBeginGteEvent("");
    setBeginLteEvent("");
  };

  return (
    <Container>
      <Card>
        <HeaderFilter filterAdvancedIsActive={filterAdvancedIsActive}>
          <div className="first">
            <nav>
              <div className="dropdown" onClick={() => {}}>
                <Select
                  labelInValue
                  defaultValue="ID"
                  defaultActiveFirstOption
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  <Option value="ID" key="id">
                    Buscar por ID
                  </Option>
                  {/* <Option value="Nome" key="name">
                    Buscar por Nome
                  </Option>
                  <Option value="CPF/CNPJ" key="organizer_document">
                    Buscar por CPF/CNPJ
                  </Option>
                  <Option value="Filtros avançados" query="">
                    Filtros avançados
                  </Option> */}
                </Select>
              </div>

              <div className="searchInput">
                <input
                  value={filterText}
                  placeholder={`${filterValue} do faq`}
                  onChange={(e) => setFilterText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (filterText !== "") {
                        handleSearchFilter();
                      }
                    }
                  }}
                />
              </div>

              <div style={{ width: 15, marginTop: 4 }}>
                {filterText ? (
                  <IoCloseCircle
                    size={12}
                    color={Colors.secondary80}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFilterText("");
                      handleResetSearchFilter();
                    }}
                  />
                ) : null}
              </div>
            </nav>

            <div className="search" onClick={handleSearchFilter}>
              <BiSearch size={18} color={Colors.white} />
            </div>
          </div>

          <h6>
            <strong>{total ?? 0}</strong> resultados encontrados
          </h6>
        </HeaderFilter>
        {filterAdvancedIsActive ? (
          <AdvancedFilters>
            <FirstRow>
              <ContainerInput>
                <strong>ID</strong>

                <input
                  className="normal"
                  type="text"
                  placeholder="Digite o ID"
                  onChange={(e) => setIdEvent(e.target.value)}
                />
              </ContainerInput>

              <ContainerInput>
                <strong>Cadastrado entre</strong>

                <div className="range-pick">
                  <input
                    type="text"
                    placeholder="00/00/0000"
                    onKeyUp={handleKeyUpDate}
                    onChange={(e) => {
                      const text = e.target.value;

                      const allText = text.split("/");

                      setTempCreatedGteEvent(
                        `${allText[2]}-${allText[1]}-${allText[0]}`
                      );
                    }}
                  />
                  <div>-</div>
                  <input
                    type="text"
                    placeholder="00/00/0000"
                    onKeyUp={handleKeyUpDate}
                    onChange={(e) => {
                      const text = e.target.value;

                      const allText = text.split("/");

                      setTempCreatedLteEvent(
                        `${allText[2]}-${allText[1]}-${allText[0]}`
                      );
                    }}
                  />

                  <IoCloseCircle
                    color={Colors.secondary80}
                    style={{ cursor: "pointer" }}
                    onClick={removeFiltersDataCreated}
                  />
                </div>
              </ContainerInput>

              <ContainerInput>
                <strong>Tipo do evento</strong>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Escolha o tipo"
                    onChange={handleChangeType}
                    allowClear
                    onClear={() => {
                      setTypeEvent("");
                    }}
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      border: "none",
                      outline: 0,
                    }}
                  >
                    <Option value={"1"} key={1}>
                      Evento Presencial
                    </Option>

                    <Option value={"2"} key={2}>
                      Evento Online
                    </Option>

                    <Option value={"3"} key={3}>
                      Evento Hibrido
                    </Option>
                  </StyledSelect>
                </div>
              </ContainerInput>

              <ContainerInput>
                <strong>Organizador</strong>

                <input
                  className="normal"
                  type="text"
                  placeholder="Digite o nome do organiza..."
                  onChange={(e) => setNameOrganizerEvent(e.target.value)}
                />
              </ContainerInput>

              <ContainerInput>
                <strong>Que vão ocorrer entre</strong>

                <div className="range-pick">
                  <input
                    type="text"
                    placeholder="00/00/0000"
                    onKeyUp={handleKeyUpDate}
                    onChange={(e) => {
                      const text = e.target.value;

                      const allText = text.split("/");

                      setTempBeginGteEvent(
                        `${allText[2]}-${allText[1]}-${allText[0]}`
                      );
                    }}
                  />
                  <div>-</div>
                  <input
                    type="text"
                    placeholder="00/00/0000"
                    onKeyUp={handleKeyUpDate}
                    onChange={(e) => {
                      const text = e.target.value;

                      const allText = text.split("/");

                      setTempBeginLteEvent(
                        `${allText[2]}-${allText[1]}-${allText[0]}`
                      );
                    }}
                  />

                  <IoCloseCircle
                    color={Colors.secondary80}
                    style={{ cursor: "pointer" }}
                    onClick={removeFiltersBeginData}
                  />
                </div>
              </ContainerInput>

              <ContainerInput>
                <strong>Categoria</strong>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Escolha o tipo"
                    onChange={handleChangeCategory}
                    allowClear
                    onClear={() => {
                      setCategoryEvent("");
                    }}
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      border: "none",
                      outline: 0,
                    }}
                  >
                    {/* {dataCategories?.data.map((category) => {
                      return (
                        <Option value={category.id} key={category.id}>
                          {category.name}
                        </Option>
                      );
                    })} */}
                  </StyledSelect>
                </div>
              </ContainerInput>

              <ContainerInput>
                <strong>Privacidade</strong>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Escolha o tipo"
                    onChange={handleChangePrivacity}
                    allowClear
                    onClear={() => {
                      setPrivacityEvent("");
                    }}
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      border: "none",
                      outline: 0,
                    }}
                  >
                    <Option value={"1"} key={1}>
                      Público
                    </Option>

                    <Option value={"2"} key={2}>
                      Privado
                    </Option>
                  </StyledSelect>
                </div>
              </ContainerInput>

              <ContainerInput>
                <strong>Idade</strong>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Escolha o tipo"
                    onChange={handleChangeAge}
                    allowClear
                    onClear={() => {
                      setAgeEvent("");
                    }}
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      border: "none",
                      outline: 0,
                    }}
                  >
                    <Option value={"1"} key={1}>
                      Livre
                    </Option>

                    <Option value={"2"} key={2}>
                      10 anos
                    </Option>

                    <Option value={"3"} key={3}>
                      12 anos
                    </Option>

                    <Option value={"4"} key={4}>
                      14 anos
                    </Option>

                    <Option value={"5"} key={5}>
                      16 anos
                    </Option>

                    <Option value={"6"} key={6}>
                      18 anos
                    </Option>
                  </StyledSelect>
                </div>
              </ContainerInput>

              <ContainerInput>
                <strong>Status</strong>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Escolha  o status"
                    onChange={handleChangeStatus}
                    allowClear
                    onClear={() => {
                      setStatusEvent("");
                    }}
                    style={{
                      width: "100%",
                      height: "38px",
                      border: "none",
                      outline: 0,
                    }}
                  >
                    <Option value={true} key={1}>
                      ATIVO
                    </Option>

                    <Option value={false} key={2}>
                      INATIVO
                    </Option>
                  </StyledSelect>
                </div>
              </ContainerInput>

              <ContainerInput>
                <strong>Nome</strong>

                <input
                  className="normal"
                  type="text"
                  placeholder="Digite o nome"
                  onChange={(e) => setNameEvent(e.target.value)}
                />
              </ContainerInput>

              <ContainerInput>
                <strong>Estado</strong>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Escolha o estado"
                    onChange={handleChangeUf}
                    allowClear
                    onClear={() => {
                      setAddressStateEvent("");
                    }}
                    style={{
                      width: "100%",
                      height: "38px",
                      border: "none",
                      outline: 0,
                    }}
                  >
                    {ufs.map((uf) => {
                      return (
                        <Option value={uf.sigla} key={uf.id}>
                          {uf.sigla}
                        </Option>
                      );
                    })}
                  </StyledSelect>
                </div>
              </ContainerInput>

              <ContainerInput>
                <strong>Cidade</strong>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Escolha a cidade"
                    onChange={handleChangeCity}
                    allowClear
                    onClear={() => {
                      setAddressCityEvent("");
                    }}
                    value={
                      addressCityEvent ? addressCityEvent : "Escolha a cidade"
                    }
                    style={{
                      width: "100%",
                      height: "38px",
                      border: "none",
                      outline: 0,
                    }}
                  >
                    {dataCities?.map((city) => {
                      return (
                        <Option value={city.nome} key={city.nome}>
                          {city.nome}
                        </Option>
                      );
                    })}
                  </StyledSelect>
                </div>
              </ContainerInput>

              <ContainerInput>
                <strong>CPF/ CNPJ</strong>

                <input
                  className="normal"
                  type="text"
                  placeholder="Digite o cpf/cnpj"
                  onChange={(e) => setDocumentOrganizerEvent(e.target.value)}
                />
              </ContainerInput>
            </FirstRow>
          </AdvancedFilters>
        ) : null}

        <section>
          <table>
            <thead>
              <tr>
                {/* <th>
                  <Checkbox />
                </th> */}
                <th>ID</th>
                <th>TÍTULO</th>
                <th>CATEGORIA</th>
                <th>DESCRIÇÃO</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {!data
                ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                    <Tr active={false}>
                      {/* <td>
                        <Skeleton height={30} width={60} />
                      </td> */}

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>
                    </Tr>
                  ))
                : null}

              {data?.map((fa, i) => (
                <Tr key={i} active={fa?._id === activeEvent?._id}>
                  {/* <td>
                    <div className="all">
                      <Checkbox />
                    </div>
                  </td> */}

                  <td>
                    <div className="all">
                      <strong>{fa._id.substring(0, 4)}</strong>
                    </div>
                  </td>

                  <td>
                    <div className="all">
                      <strong>
                        {fa.title !== null
                          ? fa.title.length > 20
                            ? `${fa.title.substring(0, 20)}...`
                            : fa.title
                          : ""}
                      </strong>
                    </div>
                  </td>
                  {/* <td>
                    <strong>000.000.000-00</strong>
                  </td> */}

                  <td>
                    <div className="all">
                      <strong>{categoryFaq(fa.category)}</strong>
                    </div>
                  </td>

                  <td>
                    <div className="all">
                      <strong>
                        {fa.description !== null
                          ? fa.description.length > 40
                            ? `${fa.description.substring(0, 40)}...`
                            : fa.description
                          : ""}
                      </strong>
                    </div>
                  </td>

                  {/* <td>
                    <div className="all">
                      <StatusComponent
                        content={event.status ? "Ativo" : "Inativo"}
                        status={event.status}
                      />
                    </div>
                  </td> */}

                  <td>
                    <div className="all">
                      <div className="column">
                        {activeEvent?._id === fa._id ? (
                          <FiChevronUp
                            onClick={() => handleSelectedUser(fa, false)}
                            style={{ cursor: "pointer" }}
                            color={Colors.secondary80}
                            size={18}
                          />
                        ) : (
                          <FiChevronDown
                            onClick={() => handleSelectedUser(fa, true)}
                            style={{ cursor: "pointer" }}
                            color={Colors.secondary80}
                            size={18}
                          />
                        )}

                        {activeEvent?._id === fa._id ? (
                          <LinkStyled to={`/faq/${fa._id}`}>
                            <span>Detalhes</span>
                          </LinkStyled>
                        ) : null}
                      </div>
                    </div>
                  </td>
                </Tr>
              ))}
            </tbody>
          </table>
        </section>

        {data && data.length === 0 ? (
          <div className="container-empty">
            <h3>Nenhuma FAQ Encontrada</h3>
          </div>
        ) : null}
      </Card>

      <ContainerItemsMobile>
        <CardMobile>
          <HeaderFiltersMobile filterAdvancedIsActive={filterAdvancedIsActive}>
            <div className="select">
              <Select
                labelInValue
                defaultValue="ID"
                defaultActiveFirstOption
                onChange={handleChange}
                style={{ width: "100%" }}
              >
                <Option value="ID" key="id">
                  Buscar por ID
                </Option>
                {/* <Option value="Nome" key="name">
                  Buscar por Nome
                </Option>
                <Option value="CPF/CNPJ" key="organizer_document">
                  Buscar por CPF/CNPJ
                </Option>
                <Option value="Filtros avançados" query="">
                  Filtros avançados
                </Option> */}
              </Select>
            </div>

            {!filterAdvancedIsActive ? (
              <div>
                <nav>
                  <div className="searchInput">
                    <input
                      value={filterText}
                      placeholder={`${filterValue} da FAQ`}
                      onChange={(e) => setFilterText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (filterText !== "") {
                            handleSearchFilter();
                          }
                        }
                      }}
                    />
                  </div>

                  <div style={{ width: 15, marginTop: 4 }}>
                    {filterText ? (
                      <IoCloseCircle
                        size={12}
                        color={Colors.secondary80}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setFilterText("");
                          handleResetSearchFilter();
                        }}
                      />
                    ) : null}
                  </div>
                </nav>
                <div className="search" onClick={handleSearchFilter}>
                  <BiSearch size={18} color={Colors.white} />
                </div>
              </div>
            ) : null}

            <div className="footer-informations">
              <p className="quantity">{total ?? 0} resultados encontrados</p>
            </div>
          </HeaderFiltersMobile>

          {filterAdvancedIsActive ? (
            <AdvancedFilters>
              <FirstRow>
                <ContainerInput>
                  <strong>ID</strong>

                  <input
                    className="normal"
                    type="text"
                    placeholder="Digite o ID"
                    onChange={(e) => setIdEvent(e.target.value)}
                  />
                </ContainerInput>

                <ContainerInput>
                  <strong>Cadastrado entre</strong>

                  <div className="range-pick">
                    <input
                      type="text"
                      placeholder="00/00/0000"
                      onKeyUp={handleKeyUpDate}
                      onChange={(e) => {
                        const text = e.target.value;

                        const allText = text.split("/");

                        setTempCreatedGteEvent(
                          `${allText[2]}-${allText[1]}-${allText[0]}`
                        );
                      }}
                    />
                    <div>-</div>
                    <input
                      type="text"
                      placeholder="00/00/0000"
                      onKeyUp={handleKeyUpDate}
                      onChange={(e) => {
                        const text = e.target.value;

                        const allText = text.split("/");

                        setTempCreatedLteEvent(
                          `${allText[2]}-${allText[1]}-${allText[0]}`
                        );
                      }}
                    />

                    <IoCloseCircle
                      color={Colors.secondary80}
                      style={{ cursor: "pointer" }}
                      onClick={removeFiltersDataCreated}
                    />
                  </div>
                </ContainerInput>

                <ContainerInput>
                  <strong>Tipo do evento</strong>

                  <div className="select">
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha o tipo"
                      onChange={handleChangeType}
                      allowClear
                      onClear={() => {
                        setTypeEvent("");
                      }}
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        border: "none",
                        outline: 0,
                      }}
                    >
                      <Option value={"1"} key={1}>
                        Evento Presencial
                      </Option>

                      <Option value={"2"} key={2}>
                        Evento Online
                      </Option>

                      <Option value={"3"} key={3}>
                        Evento Hibrido
                      </Option>
                    </StyledSelect>
                  </div>
                </ContainerInput>

                <ContainerInput>
                  <strong>Organizador</strong>

                  <input
                    className="normal"
                    type="text"
                    placeholder="Digite o nome do organiza..."
                    onChange={(e) => setNameOrganizerEvent(e.target.value)}
                  />
                </ContainerInput>

                <ContainerInput>
                  <strong>Que vão ocorrer entre</strong>

                  <div className="range-pick">
                    <input
                      type="text"
                      placeholder="00/00/0000"
                      onKeyUp={handleKeyUpDate}
                      onChange={(e) => {
                        const text = e.target.value;

                        const allText = text.split("/");

                        setTempBeginGteEvent(
                          `${allText[2]}-${allText[1]}-${allText[0]}`
                        );
                      }}
                    />
                    <div>-</div>
                    <input
                      type="text"
                      placeholder="00/00/0000"
                      onKeyUp={handleKeyUpDate}
                      onChange={(e) => {
                        const text = e.target.value;

                        const allText = text.split("/");

                        setTempBeginLteEvent(
                          `${allText[2]}-${allText[1]}-${allText[0]}`
                        );
                      }}
                    />

                    <IoCloseCircle
                      color={Colors.secondary80}
                      style={{ cursor: "pointer" }}
                      onClick={removeFiltersBeginData}
                    />
                  </div>
                </ContainerInput>

                <ContainerInput>
                  <strong>Categoria</strong>

                  <div className="select">
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha o tipo"
                      onChange={handleChangeCategory}
                      allowClear
                      onClear={() => {
                        setCategoryEvent("");
                      }}
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        border: "none",
                        outline: 0,
                      }}
                    >
                      {/* {dataCategories?.data.map((category) => {
                        return (
                          <Option value={category.id} key={category.id}>
                            {category.name}
                          </Option>
                        );
                      })} */}
                    </StyledSelect>
                  </div>
                </ContainerInput>

                <ContainerInput>
                  <strong>Privacidade</strong>

                  <div className="select">
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha o tipo"
                      onChange={handleChangePrivacity}
                      allowClear
                      onClear={() => {
                        setPrivacityEvent("");
                      }}
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        border: "none",
                        outline: 0,
                      }}
                    >
                      <Option value={"1"} key={1}>
                        Público
                      </Option>

                      <Option value={"2"} key={2}>
                        Privado
                      </Option>
                    </StyledSelect>
                  </div>
                </ContainerInput>

                <ContainerInput>
                  <strong>Idade</strong>

                  <div className="select">
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha o tipo"
                      onChange={handleChangeAge}
                      allowClear
                      onClear={() => {
                        setAgeEvent("");
                      }}
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        border: "none",
                        outline: 0,
                      }}
                    >
                      <Option value={"1"} key={1}>
                        Livre
                      </Option>

                      <Option value={"2"} key={2}>
                        10 anos
                      </Option>

                      <Option value={"3"} key={3}>
                        12 anos
                      </Option>

                      <Option value={"4"} key={4}>
                        14 anos
                      </Option>

                      <Option value={"5"} key={5}>
                        16 anos
                      </Option>

                      <Option value={"6"} key={6}>
                        18 anos
                      </Option>
                    </StyledSelect>
                  </div>
                </ContainerInput>

                <ContainerInput>
                  <strong>Status</strong>

                  <div className="select">
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha  o status"
                      onChange={handleChangeStatus}
                      allowClear
                      onClear={() => {
                        setStatusEvent("");
                      }}
                      style={{
                        width: "100%",
                        height: "38px",
                        border: "none",
                        outline: 0,
                      }}
                    >
                      <Option value={true} key={1}>
                        ATIVO
                      </Option>

                      <Option value={false} key={2}>
                        INATIVO
                      </Option>
                    </StyledSelect>
                  </div>
                </ContainerInput>

                <ContainerInput>
                  <strong>Nome</strong>

                  <input
                    className="normal"
                    type="text"
                    placeholder="Digite o nome"
                    onChange={(e) => setNameEvent(e.target.value)}
                  />
                </ContainerInput>

                <ContainerInput>
                  <strong>Estado</strong>

                  <div className="select">
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha o estado"
                      onChange={handleChangeUf}
                      allowClear
                      onClear={() => {
                        setAddressStateEvent("");
                      }}
                      style={{
                        width: "100%",
                        height: "38px",
                        border: "none",
                        outline: 0,
                      }}
                    >
                      {ufs.map((uf) => {
                        return (
                          <Option value={uf.sigla} key={uf.id}>
                            {uf.sigla}
                          </Option>
                        );
                      })}
                    </StyledSelect>
                  </div>
                </ContainerInput>

                <ContainerInput>
                  <strong>Cidade</strong>

                  <div className="select">
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha a cidade"
                      onChange={handleChangeCity}
                      allowClear
                      onClear={() => {
                        setAddressCityEvent("");
                      }}
                      value={
                        addressCityEvent ? addressCityEvent : "Escolha a cidade"
                      }
                      style={{
                        width: "100%",
                        height: "38px",
                        border: "none",
                        outline: 0,
                      }}
                    >
                      {dataCities?.map((city) => {
                        return (
                          <Option value={city.nome} key={city.nome}>
                            {city.nome}
                          </Option>
                        );
                      })}
                    </StyledSelect>
                  </div>
                </ContainerInput>

                <ContainerInput>
                  <strong>CPF/ CNPJ</strong>

                  <input
                    className="normal"
                    type="text"
                    placeholder="Digite o cpf/cnpj"
                    onChange={(e) => setDocumentOrganizerEvent(e.target.value)}
                  />
                </ContainerInput>
              </FirstRow>
            </AdvancedFilters>
          ) : null}

          <List>
            {!data
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                  <Item>
                    {/* <Skeleton height={15} width={15} /> */}

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>
                  </Item>
                ))
              : null}

            {data?.map((fa) => (
              <Item>
                {/* <Checkbox /> */}

                <small>ID: {fa.id}</small>

                <h5>TÍTULO</h5>
                <h2>
                  {fa.title !== null
                    ? fa.title.length > 20
                      ? `${fa.title.substring(0, 20)}...`
                      : fa.title
                    : ""}
                </h2>

                <h5>CATEGORIA</h5>

                <p>{categoryFaq(fa.category)}</p>

                <h5>DESCRIÇÃO</h5>

                <p>
                  {fa.description !== null
                    ? fa.description.length > 40
                      ? `${fa.description.substring(0, 40)}...`
                      : fa.description
                    : ""}
                </p>

                <div className="chevron-mobile">
                  <StatusComponent
                    content={fa.status ? "Ativa" : "Inativa"}
                    color={fa.status ? "green" : "red"}
                  />

                  {activeEvent?.id === fa.id ? (
                    <FiChevronUp
                      onClick={() => handleSelectedUser(fa, false)}
                      style={{ cursor: "pointer" }}
                      color={Colors.secondary80}
                      size={22}
                    />
                  ) : (
                    <FiChevronDown
                      onClick={() => handleSelectedUser(fa, true)}
                      style={{ cursor: "pointer" }}
                      color={Colors.secondary80}
                      size={22}
                    />
                  )}
                </div>

                <div>
                  {activeEvent?._id === fa._id ? (
                    <div className="footer-details">
                      <LinkStyledMobile to={`/faq/${fa.id}`}>
                        <span>Detalhes</span>
                      </LinkStyledMobile>
                    </div>
                  ) : null}
                </div>
              </Item>
            ))}
          </List>
        </CardMobile>
      </ContainerItemsMobile>
    </Container>
  );
}
