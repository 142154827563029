import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerDetailsPurchase = styled.div`
  padding-top: 31px;

  div.section {
    margin-top: 18px;

    h4 {
      color: ${Colors.secondary80};
      font-size: 19px;
      font-weight: bold;
      margin-top: 14px;
    }

    h5 {
      margin-top: 4px;
    }

    h5.first {
      margin-top: 13px;
    }
  }
`;

interface AccordionProps {
  active?: boolean;
}

export const Accordion = styled.div<AccordionProps>`
  width: 100%;
  min-height: 46px;

  display: flex;
  flex-direction: column;

  background: ${(props) => (props.active ? Colors.background5 : Colors.white)};
  border: 1px solid ${Colors.border70};
  border-radius: 5px;

  padding: 12px 18px 12px 14px;
  margin-bottom: 12px;

  div.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .icon {
      cursor: pointer;
    }

    div.first {
      display: flex;
      flex-direction: row;
      align-items: center;

      h2.first {
        font-weight: 600;
        font-size: 14px;

        color: ${Colors.secondary80};
        margin-bottom: 0px;
        margin-left: 14px;
      }
    }
  }

  div.div-border {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid ${Colors.border70};

    div.row {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 10px;

      h3.last {
        margin-left: 64px;
      }

      h3 {
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;

        color: ${Colors.secondary80};

        strong {
          font-weight: bold;
        }

        margin-bottom: 4px;
      }
    }

    div.row-details {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      @media (max-width: 610px) {
        flex-direction: column;
        align-items: flex-start;

        h3 {
          margin-bottom: 20px;
        }
      }

      h3 {
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;

        color: ${Colors.secondary80};

        strong {
          font-weight: bold;
        }

        margin-right: 64px;

        margin-bottom: 4px;
      }

      > * {
        &:last-child {
          margin-right: 0px;
        }
      }
    }

    div.column {
      margin-bottom: 10px;

      h3 {
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;

        color: ${Colors.secondary80};

        strong {
          font-weight: bold;
        }

        margin-bottom: 4px;
      }
    }
  }
`;
