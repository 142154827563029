import { authClient } from "services/authClient";

import useSWR from "swr";

import moment from "moment";

import { PurchaseType } from "types";

interface CancelPurchaseProps {
  purchaseId: string;
  credentials: any;
}

export async function CancelPurchase({
  purchaseId,
  credentials,
}: CancelPurchaseProps) {
  const url = `/backoffice/cancel-purchase/${purchaseId}`;

  await authClient.patch(url, credentials);
}

interface useAuthSaleProps {
  saleId: string | undefined;
}

export function useAuthSale<Data = any, Error = any>({
  saleId,
}: useAuthSaleProps) {
  const url = `/backoffice/purchase/${saleId}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const sale = response.data;

    let dateEvent = "Não informado";
    let timeEvent = "Não informado";

    if (sale.event_id && sale.event_id.begin_date) {
      const date = moment(sale.event_id.begin_date);
      dateEvent = date.utc().format("DD/MM/YYYY");
      timeEvent = date.utc().format("HH:mm");
    }

    const createdAt = moment(sale.created_at);
    const createdAtComponent = createdAt.utc().format("DD/MM/YYYY");

    const createdAtTimeComponent = createdAt.utc().format("HH:mm");

    let dateRefundedAt = "";
    let timeRefundedAt = "";

    if (sale?.refunded_at !== null) {
      const date = moment(sale?.refunded_at);
      dateRefundedAt = date.utc().format("DD/MM/YYYY");
      timeRefundedAt = date.utc().format("HH:mm");
    }

    const newResponse = {
      ...sale,
      formattedDateRefundedAt: dateRefundedAt,
      formattedTimeRefundedAt: timeRefundedAt,
      formattedDataEvent: dateEvent,
      formattedTimeEvent: timeEvent,
      formattedCreatedAt: createdAtComponent,
      formattedTimeCreatedAt: createdAtTimeComponent,
      formattedTotal: sale.total
        ? sale.total.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        : "Não informado",
      formattedTotalFee: sale.total_fee
        ? sale.total_fee.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        : "Não informado",
      formattedTotalWet: sale.total_wet
        ? sale.total_wet.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        : "Não informado",
    };

    return newResponse;
  });

  return { data, error, mutate };
}

interface useAuthSalesProps {
  page?: string | number;
  filters?: any;
}

export function useAuthSales<Data = any, Error = any>({
  page,
  filters,
}: useAuthSalesProps) {
  const url = `/backoffice/purchase/filter?page=${page ?? 1}&limit=20${
    filters && `&${filters}`
  }`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((purchase: PurchaseType) => {
      let dateEvent = "Não informado";
      let timeEvent = "Não informado";

      if (purchase.event && purchase.event.begin_date) {
        const date = moment(purchase.event.begin_date);
        dateEvent = date.utc().format("DD/MM/YYYY");
        timeEvent = date.utc().format("HH:mm");
      }

      let dateRefundedAt = "";
      let timeRefundedAt = "";

      if (purchase?.refunded_at !== null) {
        const date = moment(purchase.refunded_at);
        dateRefundedAt = date.utc().format("DD/MM/YYYY");
        timeRefundedAt = date.utc().format("HH:mm");
      }

      const createdAt = moment(purchase.created_at);
      const createdAtComponent = createdAt.utc().format("DD/MM/YYYY");

      const createdAtTimeComponent = createdAt.utc().format("HH:mm");

      const newTickets = purchase?.tickets?.map((ticket) => {
        let checkInAtResume = "";
        let checkoutAtResume = "";

        if (ticket?.checkin_at) {
          const date = moment(ticket?.checkin_at);
          const checkInAt = date.utc().format("DD/MM/YYYY");
          const timeCheckInAt = date.utc().format("HH:mm");

          checkInAtResume = `em ${checkInAt} às ${timeCheckInAt}`;
        }

        if (ticket?.checkout_at) {
          const date = moment(ticket.checkout_at);
          const checkoutAt = date.utc().format("DD/MM/YYYY");
          const timeCheckoutAt = date.utc().format("HH:mm");

          checkoutAtResume = `em ${checkoutAt} às ${timeCheckoutAt}`;
        }

        return {
          ...ticket,
          formattedCheckinAt: checkInAtResume,
          formattedCheckoutAt: checkoutAtResume,
        };
      });

      return {
        ...purchase,
        tickets: newTickets,
        formattedDateRefundedAt: dateRefundedAt,
        formattedTimeRefundedAt: timeRefundedAt,
        formattedDataEvent: dateEvent,
        formattedTimeEvent: timeEvent,
        formattedCreatedAt: createdAtComponent,
        formattedTimeCreatedAt: createdAtTimeComponent,
        formattedTotal: purchase.total
          ? (purchase.total / 100).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          : "Não informado",
        formattedTotalFee: purchase.total_fee
          ? (purchase.total_fee / 100).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          : "Não informado",
        formattedTotalWet: purchase.total_wet
          ? (purchase.total_wet / 100).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          : "Não informado",
        isOpen: false,
      };
    });

    const newResponse = { ...response.data, results: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}
