import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tabs } from "antd";

import { GoChevronRight } from "react-icons/go";

import { useAuthOrganizer } from "services/hooks/organizer";

import { OrganizerBank, OrganizerType } from "types";

import { Colors } from "constants/colors";

import {
  Wrapper,
  Container,
  ContainerItems,
  ListButtons,
  ContainerItemsTable,
  ButtonBorder,
  ContainerTabs,
  AllEvents,
  ContainerModalDelete,
  ContainerNormalButtons,
  ButtonCancel,
  ButtonAdd,
} from "./styles";

import { ModalAddDocument } from "./components/ModalAddDocument";
import { EventsOrganizer } from "./components/EventsOrganizer";
import { DetailOrganizer } from "./components/DetailOrganizer";

import { Modal } from "components/Modal";
import { Sidebar } from "components/Sidebar";
import { Header } from "components/Header";
import { EmptyDetail } from "components/EmptyDetail";
import { ModalDelete } from "components/ModalDelete";
import { GatewayOrganizer } from "./components/GatewaOrganizer";

const { TabPane } = Tabs;

export function OrganizerPage() {
  const [activeBank] = useState<OrganizerBank | null>(null);

  const navigate = useNavigate();

  const { id } = useParams();

  const { data } = useAuthOrganizer<OrganizerType>(String(id));

  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const handleDeleteOk = () => {
    setIsModalDeleteVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsModalDeleteVisible(false);
  };

  const [isModalDeleteBankVisible, setIsModalDeleteBankVisible] =
    useState(false);

  const handleDeleteBankCancel = () => {
    setIsModalDeleteBankVisible(false);
  };

  const [isModalAddDocumentVisible, setIsModalAddDocumentVisible] =
    useState(false);

  const handleAddDocumentOk = () => {
    setIsModalDeleteVisible(false);
  };

  const handleAddDocumentCancel = () => {
    setIsModalAddDocumentVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRemoveBank = async () => {
    try {
    } catch (err) {}
  };


  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <div className="header">
            <h1>Organizadores</h1>
            <GoChevronRight
              className="icon"
              size={28}
              color={Colors.secondary80}
            />

            <h1>Detalhes</h1>
          </div>
          <h2 className="box">Caixa de ferramentas</h2>

          <ListButtons>
            <div className="last">
              <ButtonBorder onClick={() => navigate(-1)}>
                <span>Voltar</span>
              </ButtonBorder>
            </div>
          </ListButtons>
        </ContainerItems>

        <ContainerItemsTable>
          <ContainerTabs>
            <h3 className="title">
              <strong>Organizador:</strong> {data?.nickname}
            </h3>

            {!data ? <EmptyDetail /> : null}

            {data ? (
              <Tabs defaultActiveKey="1" type="card" size={"small"}>
                <TabPane tab="Detalhes" key="1">
                  {id && data ? <DetailOrganizer id={id} data={data} /> : null}
                </TabPane>

                <TabPane tab="Eventos" key="2">
                  <AllEvents>
                    {id ? <EventsOrganizer activeUserId={Number(id)} /> : null}
                  </AllEvents>
                </TabPane>

                <TabPane tab="Gateways" key="3">
                  <AllEvents>
                    {id ? <GatewayOrganizer gateways={data?.gateway_registers} /> : null}
                  </AllEvents>
                </TabPane>
              </Tabs>
            ) : null}
          </ContainerTabs>
        </ContainerItemsTable>

        <Modal
          isModalVisible={isModalDeleteVisible}
          handleCancel={handleDeleteCancel}
          handleOk={handleDeleteOk}
        >
          <ContainerModalDelete>
            <div className="content">
              <h2>Deseja excluir?</h2>

              <p>
                Você tem certeza que deseja excluir o organizador “{" "}
                {data?.name ?? ""}” ?
              </p>
            </div>

            <ContainerNormalButtons>
              <ButtonCancel onClick={handleDeleteCancel}>
                <span>Cancelar</span>
              </ButtonCancel>
              <ButtonAdd>
                <span>Sim, desejo</span>
              </ButtonAdd>
            </ContainerNormalButtons>
          </ContainerModalDelete>
        </Modal>

        {id && data?.agent_name ? (
          <ModalAddDocument
            idOrganizer={id}
            organizer={data}
            isModalVisible={isModalAddDocumentVisible}
            handleAddDocumentCancel={handleAddDocumentCancel}
            handleCancel={handleAddDocumentCancel}
            handleOk={handleAddDocumentOk}
          />
        ) : null}

        <ModalDelete
          isModalVisible={isModalDeleteBankVisible}
          handleCancel={handleDeleteBankCancel}
          handleOk={handleRemoveBank}
          text={`Você tem certeza que deseja excluir a Conta “
         ${activeBank?.holder_name ?? ""}” ?`}
        />
      </Container>
    </Wrapper>
  );
}
