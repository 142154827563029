import { ContainerInput, StyledSelect } from "./styles";

import { Select } from "antd";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

type OptionType = {
  label: string;
  value: string | number;
};

interface SelectComponentProps {
  name: string;
  label: string;
  placeholder: string;
  options: OptionType[];
}

export function SelectComponent({
  name,
  label,
  placeholder,
  options,
}: SelectComponentProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        [name]: value.value,
      });
    },
    [name, searchParams, setSearchParams]
  );

  const clearId = useCallback(() => {
    searchParams.delete(name);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [name, searchParams, setSearchParams]);

  return (
    <ContainerInput>
      <strong>{label}</strong>

      <div className="select">
        <StyledSelect
          bordered={false}
          labelInValue
          placeholder={placeholder}
          onChange={handleSelectedIdFilter}
          allowClear
          onClear={clearId}
          style={{
            width: "100%",
            minHeight: "100%",
            border: "none",
            outline: 0,
          }}
        >
          {options.map((option) => (
            <Option value={option.value} key={option.value}>
              {option.label}
            </Option>
          ))}
        </StyledSelect>
      </div>
    </ContainerInput>
  );
}
