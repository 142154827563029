import { Dropdown, DropDownContainer, LiStyledLast } from "styles/Dropdown";
import { LinkWithHover } from "styles/LinkWithHover";
import ticketImage from "assets/ticket.svg";
import eyes_secondary from "assets/eyes-secondary.svg";
import points from "assets/points.svg";

import { Container } from "./styles";
import { StatusComponent } from "components/Status";
import { useAuthEventTicket } from "services/hooks/user";

type TicketCardProps = {
  id: string;
  eventId?: string;
  status?: boolean;
  holderName?: string;
  holderEmail?: string;
  holderCpf?: string;
  onHandleSelectedTicket: () => void;
  onHandleShowModalVisualizatingTicket: () => void;
  isActive: boolean;
}

function TicketCard({
  id,
  eventId,
  holderName,
  holderCpf,
  holderEmail,
  status,
  onHandleSelectedTicket,
  onHandleShowModalVisualizatingTicket,
  isActive
}: TicketCardProps) {
  const { data: event } = useAuthEventTicket({ event_id: eventId ?? '' });

  return (
    <Container>
      <div className="first">
        <img src={ticketImage} alt="event" />
      </div>

      <div className="column">
        <header>
          <div>
            {/* <h3
              style={{ cursor: "pointer" }}
              className="last"
              onClick={() => {
                onHandleSelectedTicket();
                onHandleShowModalVisualizatingTicket();
              }}
            >
              {name
                ? name.length > 14
                  ? `${name.substring(0, 14)}...`
                  : name
                : "não informado"}
            </h3> */}
            <LinkWithHover to={`/events/${eventId}`}>
              <h4>
                <strong>Evento:</strong>{" "}
                {event ? event.name : "não informado"}
              </h4>
            </LinkWithHover>
            <h4>
              <strong>Nome:</strong>{" "}
              {holderName
                ? holderName.length > 14
                  ? `${holderName.substring(0, 14)}...`
                  : holderName
                : "não informado"}
            </h4>
            <h4>
              <strong>Email:</strong>{" "}
              {holderEmail
                ? holderEmail
                : "não informado"}
            </h4>
            <h4>
              <strong>Cpf:</strong>{" "}
              {holderCpf
                ? holderCpf
                : "não informado"}
            </h4>
          </div>

          <div className="options">
            <StatusComponent
              color={status ? "green" : "red"}
              content={status ? "ATIVO" : "INATIVO"}
            />

            <DropDownContainer>
              <div
                className="area"
                onClick={onHandleSelectedTicket}
              >
                <img src={points} alt="" />
              </div>

              {isActive ? (
                <Dropdown height="67px">
                  <ul>
                    <LiStyledLast
                      onClick={onHandleShowModalVisualizatingTicket}
                    >
                      <img src={eyes_secondary} alt="eye" />
                      <h5>Visualizar ingresso</h5>
                    </LiStyledLast>
                  </ul>
                </Dropdown>
              ) : null}
            </DropDownContainer>
          </div>
        </header>
      </div>
    </Container>
  )
}

export default TicketCard;
