import { PurchaseType } from "types";
import {
  returnStatusPurchase,
  returnTypePaymentPurchase,
} from "utils/purchase";
import { Accordion, ContainerDetailsPurchase } from "./styles";

import detailsIcon from "assets/detailsIcon.svg";
import detailsIconActive from "assets/detailsIconActive.svg";
import calendarPurchase from "assets/calendarPurchase.svg";
import calendarPurchaseActive from "assets/calendarPurchaseActive.svg";
import moneyPurchase from "assets/moneyPurchase.svg";
import moneyPurchaseActive from "assets/moneyPurchaseActive.svg";

import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { Colors } from "constants/colors";
import { useState } from "react";
import { Link } from "react-router-dom";

interface DetailsPurchaseProps {
  activePurchase: PurchaseType | null;
}

export function DetailsPurchase({ activePurchase }: DetailsPurchaseProps) {
  const [indexActive, setIndexActive] = useState<number | null>(0);

  const handleChangeIndexActive = (index: number) => {
    if (index === indexActive) {
      setIndexActive(null);

      return;
    }

    setIndexActive(index);
  };

  return (
    <ContainerDetailsPurchase>
      <Accordion active={indexActive === 0}>
        <div className="header">
          <div className="first">
            {indexActive !== 0 ? <img src={detailsIcon} alt="details" /> : null}

            {indexActive === 0 ? (
              <img src={detailsIconActive} alt="details" />
            ) : null}

            <h2 className="first">Detalhes da transação</h2>
          </div>

          {indexActive === 0 ? (
            <BsChevronUp
              className="icon"
              color={Colors.secondary70}
              onClick={() => handleChangeIndexActive(0)}
            />
          ) : (
            <BsChevronDown
              className="icon"
              color={Colors.secondary70}
              onClick={() => handleChangeIndexActive(0)}
            />
          )}
        </div>

        {indexActive === 0 ? (
          <div className="div-border">
            <div className="column">
              <Link to={`/users/${activePurchase?.user_id?._id}`}>
                <h3>
                  <strong>Nome:</strong>{" "}
                  {activePurchase?.user_id?.name
                    ? activePurchase?.user_id?.name
                    : "Não informado"}
                </h3>
              </Link>

              <h3>
                <strong>E-mail:</strong>{" "}
                {activePurchase?.user_id?.email
                  ? activePurchase?.user_id?.email
                  : "Não informado"}
              </h3>

              <h3>
                <strong>Telefone:</strong>{" "}
                {activePurchase?.user_id?.cell_phone
                  ? activePurchase?.user_id?.cell_phone
                  : "Não informado"}
              </h3>
            </div>

            <div className="column">
              <h3>
                <strong>Tipo de pagamento:</strong>{" "}
                {activePurchase?.type_payment !== null
                  ? returnTypePaymentPurchase(
                      activePurchase?.type_payment ?? ""
                    )
                  : "Não informado"}
              </h3>

              <h3>
                <strong>Orderbump:</strong>{" "}
                {activePurchase?.event_id?.name
                  ? activePurchase?.event_id?.name
                  : "Não informado"}
              </h3>
            </div>

            <div className="column">
              <h3>
                <strong>Valor da compra:</strong>{" "}
                {activePurchase?.formattedTotal}
              </h3>

              <h3>
                <strong>Taxa Eventx:</strong>{" "}
                {activePurchase?.formattedTotalFee}
              </h3>

              <h3>
                <strong>Valor líquido:</strong>{" "}
                {activePurchase?.formattedTotalWet}
              </h3>
            </div>

            <div className="column">
              <h3>
                <strong>ID:</strong> {activePurchase?._id}
              </h3>

              <h3>
                <strong>Status:</strong>{" "}
                {returnStatusPurchase(activePurchase?.purchase_status ?? "")}
              </h3>
            </div>
          </div>
        ) : null}
      </Accordion>

      <Accordion active={indexActive === 1}>
        <div className="header">
          <div className="first">
            {indexActive !== 1 ? (
              <img src={calendarPurchase} alt="details" />
            ) : null}

            {indexActive === 1 ? (
              <img src={calendarPurchaseActive} alt="details" />
            ) : null}

            <h2 className="first">Detalhes do evento</h2>
          </div>

          {indexActive === 1 ? (
            <BsChevronUp
              className="icon"
              color={Colors.secondary70}
              onClick={() => handleChangeIndexActive(1)}
            />
          ) : (
            <BsChevronDown
              className="icon"
              color={Colors.secondary70}
              onClick={() => handleChangeIndexActive(1)}
            />
          )}
        </div>

        {indexActive === 1 ? (
          <div className="div-border">
            <div className="row">
              <Link to={`/events/${activePurchase?.event?.id}`}>
                <h3>
                  <strong>Evento:</strong>{" "}
                  {activePurchase?.event_id?.name
                    ? activePurchase?.event_id?.name
                    : "Não informado"}
                </h3>
              </Link>

              <h3 className="last">
                <strong>Data:</strong>{" "}
                {activePurchase?.formattedDataEvent ?? ""}
              </h3>

              <h3 className="last">
                <strong>Horário:</strong>{" "}
                {activePurchase?.formattedTimeEvent ?? ""}
              </h3>
            </div>
          </div>
        ) : null}
      </Accordion>

      <Accordion active={indexActive === 2}>
        <div className="header">
          <div className="first">
            {indexActive !== 2 ? (
              <img src={moneyPurchase} alt="details" />
            ) : null}

            {indexActive === 2 ? (
              <img src={moneyPurchaseActive} alt="details" />
            ) : null}

            <h2 className="first">Detalhes da compra</h2>
          </div>

          {indexActive === 2 ? (
            <BsChevronUp
              className="icon"
              color={Colors.secondary70}
              onClick={() => handleChangeIndexActive(2)}
            />
          ) : (
            <BsChevronDown
              className="icon"
              color={Colors.secondary70}
              onClick={() => handleChangeIndexActive(2)}
            />
          )}
        </div>

        {indexActive === 2 ? (
          <div className="div-border">
            <div className="row-details">
              <h3>
                <strong>Data da compra:</strong>{" "}
                {activePurchase?.formattedCreatedAt ?? ""}
              </h3>

              <h3>
                <strong>Horário da compra:</strong>{" "}
                {activePurchase?.formattedTimeCreatedAt ?? ""}
              </h3>

              {activePurchase?.formattedDateRefundedAt !== "" ? (
                <h3>
                  <strong>Data do estorno:</strong>{" "}
                  {activePurchase?.formattedDateRefundedAt ?? ""}
                </h3>
              ) : null}

              {activePurchase?.formattedTimeRefundedAt !== "" ? (
                <h3>
                  <strong>Horário do estorno:</strong>{" "}
                  {activePurchase?.formattedTimeRefundedAt ?? ""}
                </h3>
              ) : null}
            </div>
          </div>
        ) : null}
      </Accordion>
    </ContainerDetailsPurchase>
  );
}
