import { useState } from "react";

import { Colors } from "constants/colors";

import {
  Container,
  Card,
  Tr,
  ContainerItemsMobile,
  CardMobile,
  List,
  Item,
  TrExtra,
  LinkStyled,
  LinkStyledMobile,
} from "./styles";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { TicketType } from "types";
import Skeleton from "react-loading-skeleton";

import { ADVANCED_FILTERS, SIMPLE_FILTERS } from "constants/filters/tickets";

import { StatusComponent } from "components/Status";

import { LinkHasHover } from "styles/LinkHasHover";
import { HeaderFilterComponent } from "components/HeaderFilter";

interface TableProps {
  data?: TicketType[];
  total: number | null;
}

export function Table({ data, total }: TableProps) {
  const [activeTicket, setActiveTicket] = useState<TicketType | null>(null);

  function handleSelecteTicket(ticket: TicketType, active: boolean) {
    if (active) {
      setActiveTicket(ticket);

      return;
    }

    setActiveTicket(null);
  }

  return (
    <Container>
      <Card>
        <HeaderFilterComponent
          total={total ?? 0}
          label="do Ingresso"
          ADVANCED_FILTERS={ADVANCED_FILTERS}
          SIMPLE_FILTERS={SIMPLE_FILTERS}
        />

        <section>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>NOME</th>
                <th className="responsive">TIPO</th>
                <th>ORGANIZADOR</th>
                <th>QUANTIDADE DISPONIBILIZADA</th>
                <th>VENDAS</th>
                <th>STATUS</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {!data
                ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                    <Tr active={false}>
                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>
                    </Tr>
                  ))
                : null}

              {data?.map((ticket, i) => (
                <>
                  <Tr key={i} active={ticket?._id === activeTicket?._id}>
                    <td>
                      <div className="all">
                        <LinkHasHover to={`/tickets/${ticket._id}`}>
                          <strong>{ticket._id}</strong>
                        </LinkHasHover>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <LinkHasHover to={`/tickets/${ticket._id}`}>
                          <strong>{ticket.name}</strong>
                        </LinkHasHover>
                      </div>
                    </td>

                    <td className="responsive">
                      <div className="all">
                        <strong>Presencial</strong>
                        {false ? (
                          <div className="column">
                            <strong>Total eventos: 12</strong>
                          </div>
                        ) : null}
                      </div>
                    </td>
                    <td>
                      <div className="all">
                        <LinkHasHover
                          to={
                            ticket?.events && ticket?.events.organizer
                              ? `/organizers/${ticket?.events?.organizer?.id}`
                              : "/tickets"
                          }
                        >
                          <strong>
                            {ticket?.events && ticket?.events.organizer
                              ? ticket?.events?.organizer?.name
                              : "Não vinculado"}
                          </strong>
                        </LinkHasHover>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>{ticket?.quantity}</strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>
                          {ticket?.userTicket_count
                            ? Number(ticket?.userTicket_count)
                            : 0}
                        </strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <StatusComponent
                          content={ticket.status ? "Ativo" : "Inativo"}
                          color={ticket.status ? "green" : "red"}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="column all">
                        {activeTicket?._id === ticket._id ? (
                          <FiChevronUp
                            onClick={() => handleSelecteTicket(ticket, false)}
                            style={{ cursor: "pointer" }}
                            color={Colors.secondary80}
                            size={18}
                          />
                        ) : (
                          <FiChevronDown
                            onClick={() => handleSelecteTicket(ticket, true)}
                            style={{ cursor: "pointer" }}
                            color={Colors.secondary80}
                            size={18}
                          />
                        )}
                      </div>
                    </td>
                  </Tr>

                  {activeTicket?._id === ticket._id ? (
                    <TrExtra>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>EVENTO</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </TrExtra>
                  ) : null}

                  {activeTicket?._id === ticket._id ? (
                    <Tr key={i} active>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="all">
                          <LinkHasHover
                            to={
                              ticket?.events
                                ? `/events/${ticket?.events.id}`
                                : "/tickets"
                            }
                          >
                            <strong>
                              {(activeTicket.events &&
                                activeTicket.events.name) ??
                                "Não informado"}
                            </strong>
                          </LinkHasHover>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="all">
                          <div className="column">
                            <LinkStyled to={`/tickets/${ticket._id}`}>
                              <span>Detalhes</span>
                            </LinkStyled>
                          </div>
                        </div>
                      </td>
                    </Tr>
                  ) : null}
                </>
              ))}
            </tbody>
          </table>
        </section>

        {data && data.length === 0 ? (
          <div className="container-empty">
            <h3>Nenhum Ingresso Encontrado</h3>
          </div>
        ) : null}
      </Card>

      <ContainerItemsMobile>
        <CardMobile>
          <HeaderFilterComponent
            total={total ?? 0}
            label="do Ingresso"
            ADVANCED_FILTERS={ADVANCED_FILTERS}
            SIMPLE_FILTERS={SIMPLE_FILTERS}
          />

          <List>
            {!data
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                  <Item>
                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>
                  </Item>
                ))
              : null}

            {data &&
              data?.map((ticket) => (
                <Item>
                  <LinkHasHover to={`/tickets/${ticket._id}`}>
                    <small>ID: {ticket._id}</small>
                  </LinkHasHover>

                  <LinkHasHover to={`/tickets/${ticket._id}`}>
                    <h1 className="first">{ticket.name}</h1>
                  </LinkHasHover>

                  <h5>TIPO</h5>
                  <h2>Presencial</h2>

                  <h5>Organizador</h5>

                  <LinkHasHover
                    to={
                      ticket?.events && ticket?.events.organizer
                        ? `/organizers/${ticket?.events?.organizer?.id}`
                        : "/tickets"
                    }
                  >
                    <p>
                      {ticket?.events && ticket?.events?.organizer
                        ? ticket?.events?.organizer?.name
                        : "Não informado"}
                    </p>
                  </LinkHasHover>

                  <h5>Evento</h5>

                  <LinkHasHover
                    to={
                      ticket?.events && ticket?.events
                        ? `/events/${ticket?.events?.id}`
                        : "/events"
                    }
                  >
                    <p>
                      {ticket?.events && ticket?.events
                        ? ticket?.events?.name
                        : "Não informado"}
                    </p>
                  </LinkHasHover>

                  <h5>QUANTIDADE DISPONIBILIZADA</h5>

                  <p>{ticket?.quantity}</p>

                  <h5>Vendas</h5>

                  <p>
                    Total Vendas:{" "}
                    {ticket?.userTicket_count
                      ? Number(ticket?.userTicket_count)
                      : 0}
                  </p>

                  <div className="chevron-mobile">
                    <StatusComponent
                      content={ticket.status ? "Ativo" : "Inativo"}
                      color={ticket.status ? "green" : "red"}
                    />

                    {activeTicket?._id === ticket._id ? (
                      <FiChevronUp
                        onClick={() => handleSelecteTicket(ticket, false)}
                        style={{ cursor: "pointer" }}
                        color={Colors.secondary80}
                        size={22}
                      />
                    ) : (
                      <FiChevronDown
                        onClick={() => handleSelecteTicket(ticket, true)}
                        style={{ cursor: "pointer" }}
                        color={Colors.secondary80}
                        size={22}
                      />
                    )}
                  </div>

                  {activeTicket?._id === ticket._id ? (
                    <div className="footer-details">
                      <LinkStyledMobile to={`/tickets/${ticket._id}`}>
                        <span>Detalhes</span>
                      </LinkStyledMobile>
                    </div>
                  ) : null}
                </Item>
              ))}

            {data && data.length === 0 ? (
              <div className="container-empty">
                <h3>Nenhum Ingresso Encontrado</h3>
              </div>
            ) : null}
          </List>
        </CardMobile>
      </ContainerItemsMobile>
    </Container>
  );
}
