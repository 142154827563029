import {
  Container,
  ContainerItems,
  ListButtons,
  Button,
  ContainerItemsTable,
  ButtonBorder,
  ContainerTabs,
  ContainerForm,
  FormFull,
  ContainerInput,
  FormLabel,
  ContainerButtons,
  ButtonRemove,
  ButttonEdit,
  ContainerModalDelete,
  ContainerNormalButtons,
  ButtonCancel,
  ButtonAdd,
  StyledSelect,
  ContainerInputFull,
} from "./styles";

import { Sidebar } from "components/Sidebar";

import { Select, Spin, Tabs } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import { Modal } from "components/Modal";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Wrapper } from "./styles";
import { Header } from "components/Header";
import { BsPlus } from "react-icons/bs";
import { Colors } from "constants/colors";
import { FAQType } from "types";

import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";

import { useNavigate, useParams } from "react-router-dom";

import {
  AddFAQ,
  DeleteFAQ,
  EditFAQ,
  useAuthFAQDetail,
} from "services/hooks/faq";

const { TabPane } = Tabs;

const { Option } = Select;

type EventFormData = {
  title: string;
  category: string;
  description: string;
  status: boolean;
};

const eventFormSchema = yup.object().shape({
  title: yup.string().required("Nome obrigatório"),
  category: yup.string().required("Senha obrigatória"),
  description: yup.string().required("Senha obrigatória"),
  status: yup.boolean().notRequired(),
});

export default function FaqDetail() {
  const [loadingRemoveFaq, setLoadingRemoveFaq] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasSelectedUser, setHasSelectedUser] = useState(false);
  const [itsCreateNewFaq, setItsCreateNewFaq] = useState(false);

  const { mutate } = useSWRConfig();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<EventFormData>({
    resolver: yupResolver(eventFormSchema),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    control: control1,
    formState: { errors: errors1, isSubmitting: isSubmitting1 },
  } = useForm<EventFormData>({
    resolver: yupResolver(eventFormSchema),
  });

  const { id } = useParams();

  const { data } = useAuthFAQDetail<FAQType>(String(id));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    reset({
      title: data?.title,
      category: data?.category,
      description: data?.description,
      status: data?.status,
    });
  }, [data, reset]);

  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const showModalDelete = () => {
    setIsModalDeleteVisible(true);
  };

  const handleDeleteOk = () => {
    setIsModalDeleteVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsModalDeleteVisible(false);
  };

  const handlePutFAQ: SubmitHandler<EventFormData> = async ({
    ...dataEvent
  }) => {
    const { title, category, description, status } = dataEvent;

    try {
      const credentials = {
        title,
        category: Number(category),
        description,
        status,
      };

      EditFAQ({ faqId: id, credentials });

      setIsEditing(false);

      mutate("/faq");

      alert("faq atualizada com sucesso");
    } catch (err) {}
  };

  const handleAddFAQ: SubmitHandler<EventFormData> = async ({
    ...dataEvent
  }) => {
    const { title, category, description } = dataEvent;

    try {
      await AddFAQ({
        title,
        category: Number(category),
        description,
      });

      setItsCreateNewFaq(false);

      alert("faq criada com sucesso");
    } catch (err) {}
  };

  const handleDeleteFaq = async () => {
    try {
      setLoadingRemoveFaq(true);

      await DeleteFAQ({ faqId: id });

      setLoadingRemoveFaq(false);

      setHasSelectedUser(false);

      handleDeleteOk();

      alert("faq removida com sucesso");
    } catch (err) {}
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [itsCreateNewFaq]);

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <h1>FAQ</h1>

          <h2 className="box">Caixa de ferramentas</h2>

          <ListButtons itsCreateNewFaq={itsCreateNewFaq || hasSelectedUser}>
            {!itsCreateNewFaq && !hasSelectedUser ? (
              <Button onClick={() => setItsCreateNewFaq(true)}>
                <BsPlus color={Colors.white} size={23} />
                <span>Novo</span>
              </Button>
            ) : null}

            <div className="last">
              {/* <ButtonBorder
                  onClick={() => {
                    setHasSelectedUser(false);
                    setItsCreateNewFaq(false);
                  }}
                >
                  <span>Voltar</span>
                </ButtonBorder> */}

              <ButtonBorder onClick={() => navigate(-1)}>
                <span>Voltar</span>
              </ButtonBorder>

              {/* <ButtonBorder>
                <span>Banir</span>
              </ButtonBorder>

              <ButtonBorder>
                <img src={reportIcon} width={18} height={18} alt="" />
                <span>Exportar relatório</span>
              </ButtonBorder> */}
            </div>
          </ListButtons>
        </ContainerItems>

        <ContainerItemsTable>
          <ContainerTabs>
            {!hasSelectedUser && itsCreateNewFaq ? (
              <Tabs defaultActiveKey="1" type="card" size={"small"}>
                <TabPane tab="Criar" key="1">
                  <ContainerForm onSubmit={handleSubmit1(handleAddFAQ)}>
                    <FormFull>
                      <ContainerInput isEditing={true}>
                        <FormLabel>
                          <h2>Título</h2>
                        </FormLabel>

                        <input
                          // defaultValue={activeEvent?.name}
                          {...register1("title")}
                        />

                        {errors1.title ? (
                          <span className="error">Insira um Nome válido</span>
                        ) : null}
                      </ContainerInput>

                      <ContainerInput isEditing={true}>
                        <FormLabel>
                          <h2>Categoria</h2>
                        </FormLabel>

                        <div className="select">
                          <Controller
                            control={control1}
                            name="category"
                            rules={{ required: "Salutation is required" }}
                            // disabled={isSubmitting}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <StyledSelect
                                bordered={false}
                                labelInValue
                                placeholder="Escolha a categoria"
                                value={value}
                                // disabled={!isEditing}
                                onChange={(e: any) => {
                                  onChange(Number(e.value));
                                }}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  border: "none",
                                  outline: 0,
                                }}
                              >
                                <Option value={1} key={"1"}>
                                  Compras
                                </Option>

                                <Option value={2} key={"2"}>
                                  Pagamento
                                </Option>

                                <Option value={3} key={"3"}>
                                  Reembolso
                                </Option>

                                <Option value={4} key={"4"}>
                                  Ingressos
                                </Option>

                                <Option value={5} key={"5"}>
                                  Eventos
                                </Option>

                                <Option value={6} key={"6"}>
                                  Minha conta
                                </Option>
                              </StyledSelect>
                            )}
                          />
                        </div>

                        {errors1.category ? (
                          <span className="error">
                            Insira uma categoria válida
                          </span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInputFull isEditing={true}>
                        <FormLabel>
                          <h2>Descrição</h2>
                        </FormLabel>

                        <textarea
                          // defaultValue={activeEvent?.description}
                          {...register1("description")}
                        />

                        {errors1.description ? (
                          <span className="error">
                            Insira uma descrição válida
                          </span>
                        ) : null}
                      </ContainerInputFull>
                    </FormFull>

                    <ContainerButtons>
                      <ButtonRemove
                        type="button"
                        onClick={() => setItsCreateNewFaq(false)}
                      >
                        Cancelar
                      </ButtonRemove>

                      <ButttonEdit type="submit" isLoading={isSubmitting1}>
                        <Spin spinning={isSubmitting1} indicator={antIcon} />

                        <span>Salvar</span>
                      </ButttonEdit>
                    </ContainerButtons>
                  </ContainerForm>
                </TabPane>
              </Tabs>
            ) : null}

            {!hasSelectedUser && !itsCreateNewFaq ? (
              <Tabs defaultActiveKey="1" type="card" size={"small"}>
                <TabPane tab="Detalhes" key="1">
                  <ContainerForm onSubmit={handleSubmit(handlePutFAQ)}>
                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Título</h2>
                        </FormLabel>

                        <input
                          // defaultValue={activeEvent?.name}
                          {...register("title")}
                          disabled={!isEditing}
                        />

                        {errors.title ? (
                          <span className="error">Insira um Nome válido</span>
                        ) : null}
                      </ContainerInput>

                      {/* <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Categoria</h2>
                        </FormLabel>

                        <input
                          // defaultValue={activeEvent?.description}
                          {...register("category")}
                          disabled={!isEditing}
                        />


                      </ContainerInput> */}

                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Categoria</h2>
                        </FormLabel>

                        <div className="select">
                          <Controller
                            control={control}
                            name="category"
                            rules={{ required: "Salutation is required" }}
                            // disabled={isSubmitting}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <StyledSelect
                                bordered={false}
                                labelInValue
                                placeholder="Escolha a categoria"
                                value={value}
                                disabled={!isEditing}
                                onChange={(e: any) => {
                                  onChange(Number(e.value));
                                }}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  border: "none",
                                  outline: 0,
                                }}
                              >
                                <Option value={1} key={"1"}>
                                  Compras
                                </Option>

                                <Option value={2} key={"2"}>
                                  Pagamento
                                </Option>

                                <Option value={3} key={"3"}>
                                  Reembolso
                                </Option>

                                <Option value={4} key={"4"}>
                                  Ingressos
                                </Option>

                                <Option value={5} key={"5"}>
                                  Eventos
                                </Option>

                                <Option value={6} key={"6"}>
                                  Minha conta
                                </Option>
                              </StyledSelect>
                            )}
                          />
                        </div>

                        {errors.category ? (
                          <span className="error">
                            Insira uma categoria válida
                          </span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Status</h2>
                        </FormLabel>

                        <div className="select">
                          <Controller
                            control={control}
                            name="status"
                            rules={{ required: "Salutation is required" }}
                            // disabled={isSubmitting}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <StyledSelect
                                bordered={false}
                                labelInValue
                                placeholder="Escolha o status"
                                value={value}
                                disabled={!isEditing}
                                onChange={(e: any) => {
                                  onChange(e.value);
                                }}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  border: "none",
                                  outline: 0,
                                }}
                              >
                                <Option value={true} key={"1"}>
                                  ATIVA
                                </Option>

                                <Option value={false} key={"2"}>
                                  INATIVA
                                </Option>
                              </StyledSelect>
                            )}
                          />
                        </div>

                        {errors.category ? (
                          <span className="error">
                            Insira uma descrição válida
                          </span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInputFull isEditing={isEditing}>
                        <FormLabel>
                          <h2>Descrição</h2>
                        </FormLabel>

                        <textarea
                          // defaultValue={activeEvent?.description}
                          {...register("description")}
                          disabled={!isEditing}
                        />

                        {errors.description ? (
                          <span className="error">
                            Insira uma descrição válida
                          </span>
                        ) : null}
                      </ContainerInputFull>
                    </FormFull>

                    <ContainerButtons>
                      {isEditing ? (
                        <ButtonRemove onClick={() => setIsEditing(false)}>
                          Cancelar
                        </ButtonRemove>
                      ) : (
                        <ButtonRemove type="button" onClick={showModalDelete}>
                          Excluir
                        </ButtonRemove>
                      )}

                      {isEditing ? (
                        <ButttonEdit type="submit" isLoading={isSubmitting}>
                          <Spin spinning={isSubmitting} indicator={antIcon} />

                          <span>Salvar</span>
                        </ButttonEdit>
                      ) : null}

                      {!isEditing ? (
                        <ButttonEdit
                          type="button"
                          onClick={() => setIsEditing(true)}
                        >
                          Editar
                        </ButttonEdit>
                      ) : null}
                    </ContainerButtons>
                  </ContainerForm>
                </TabPane>
              </Tabs>
            ) : null}
          </ContainerTabs>
        </ContainerItemsTable>

        <Modal
          isModalVisible={isModalDeleteVisible}
          handleCancel={handleDeleteCancel}
          handleOk={handleDeleteOk}
        >
          <ContainerModalDelete>
            <div className="content">
              <h2>Deseja excluir?</h2>

              <p>
                Você tem certeza que deseja excluir o faq{" "}
                {data ? `"${data?.id}"` : ""}?
              </p>
            </div>

            <ContainerNormalButtons>
              <ButtonCancel onClick={handleDeleteCancel}>
                <span>Cancelar</span>
              </ButtonCancel>

              <ButtonAdd onClick={handleDeleteFaq} isLoading={loadingRemoveFaq}>
                <Spin spinning={loadingRemoveFaq} indicator={antIcon} />

                <span>Sim, desejo</span>
              </ButtonAdd>
            </ContainerNormalButtons>
          </ContainerModalDelete>
        </Modal>
      </Container>
    </Wrapper>
  );
}
