import styled from "styled-components";
import { Colors } from "../../constants/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 60px 30px 0px 30px;

  @media (max-width: 1240px) {
    padding: 104px 30px 0px 30px;
  }

  @media (max-width: 768px) {
    padding: 104px 16px 0px 70px;
  }

  @media (max-width: 621px) {
    padding: 104px 16px 0px 30px;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    color: ${Colors.secondary90};
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: ${Colors.secondary80};
    margin-top: 32px;

    @media (max-width: 621px) {
      margin-bottom: 0px;
    }
  }
`;

export const ContainerItemsTable = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  padding: 0px 60px 0px 60px;
  margin-top: 54px;
  margin-bottom: 100px;

  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;

    color: ${Colors.secondary90};
  }

  h3.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;

    color: ${Colors.secondary80};
    margin-bottom: 0px;
    margin-top: 48px;
    @media (max-width: 740px) {
      margin-bottom: 20px;
      padding-left: 34px;
    }
  }

  div.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 768px) {
      padding-right: 20px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 1240px) {
    padding: 24px 16px 0px 28px;
  }

  @media (max-width: 768px) {
    padding: 0px 0px 0px 0px;
    margin-bottom: 40px;
    margin-top: 24px;
  }

  @media (max-width: 621px) {
    padding: 30px 0px 0px 0px;
  }
`;

export const ListButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 33px;

  @media (max-width: 740px) {
    margin-top: 16px;
  }

  div.last {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const ButtonBorder = styled.button`
  margin-left: 40px;
  min-width: 107px;
  height: 34px;
  padding: 8px 24px;
  border: 0;
  background: ${Colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 97px;

  span {
    font-weight: normal;
    font-size: 18px;

    margin-left: 12px;
    color: ${Colors.primary100};
    margin-bottom: 0px;
  }
`;
