import { PaginationStyled } from "./styles";

interface PaginationProps {
  current: number;
  onChange(current: number): void;
  total: number;
}

export function PaginationFilterComponent({
  current,
  onChange,
  total,
}: PaginationProps) {
  return (
    <PaginationStyled
      current={current}
      onChange={onChange}
      total={total}
      pageSize={20}
      showSizeChanger={false}
    />
  );
}
