import { useCallback, useState } from "react";
import { Spin } from "antd";

import {
  Container,
  ContainerItems,
  FormSignIn,
  AllInput,
  InputContainer,
  ButtonSignIn,
  ContainerForms,
  ModalStyled,
  ContainerModal,
  Wrapper,
} from "./styles";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";
import {
  RiCloseCircleLine,
  RiEyeLine,
  RiEyeOffLine,
  RiLock2Line,
  RiMailLine,
} from "react-icons/ri";

import { Colors } from "constants/colors";
import { useAuth } from "contexts/AuthContext";

import { Link } from "react-router-dom";
import { HeaderInit } from "components/HeaderInit";

type SignInFormData = {
  email: string;
  password: string;
};

const signInFormSchema = yup.object().shape({
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  password: yup.string().required("Senha obrigatória"),
});

export default function SignInPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormData>({
    resolver: yupResolver(signInFormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [showPass, setShowPass] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const { signIn } = useAuth();

  const handleSignIn: SubmitHandler<SignInFormData> = async ({
    email,
    password,
  }) => {
    try {
      await signIn({
        email,
        password,
      });
    } catch (err) {
      alert("Email ou senha invalidos");
    }
  };

  const handleShowPass = useCallback(() => {
    setShowPass(!showPass);
  }, [showPass]);

  return (
    <Wrapper>
      <HeaderInit />
      <Container>
        <ContainerItems>
          <ContainerForms>
            <div className="container-logo">
              <h1 className="title">Bem vindo (a) de volta!</h1>

              <h2 className="sub">Acesse usando seu e-mail:</h2>
            </div>

            <FormSignIn onSubmit={handleSubmit(handleSignIn)}>
              <div className="all-inputs">
                <AllInput>
                  <InputContainer error={!!errors?.email}>
                    <div>
                      <RiMailLine size={23} color={Colors.secondary40} />
                    </div>
                    <input
                      type="email"
                      placeholder="E-mail"
                      {...register("email")}
                    />
                  </InputContainer>
                  {errors.email && <span>Por favor, insira o Email.</span>}
                </AllInput>

                <AllInput>
                  <InputContainer error={!!errors.password}>
                    <div>
                      <RiLock2Line size={23} color={Colors.secondary40} />
                    </div>
                    <input
                      type={showPass ? "text" : "password"}
                      placeholder="Senha"
                      {...register("password")}
                    />

                    <div>
                      {showPass ? (
                        <RiEyeLine
                          onClick={handleShowPass}
                          size={23}
                          color={Colors.secondary40}
                        />
                      ) : (
                        <RiEyeOffLine
                          onClick={handleShowPass}
                          size={23}
                          color={Colors.secondary40}
                        />
                      )}
                    </div>
                  </InputContainer>
                  {errors.password ? (
                    <span>Por favor, insira a senha.</span>
                  ) : null}
                </AllInput>
              </div>

              <div className="forgot">
                <label>
                  <input type="checkbox" />
                  Lembrar de mim
                </label>

                <Link to="/forgot-password">Esqueci minha senha</Link>
              </div>

              <div className="button-signIn">
                <ButtonSignIn type="submit" isLoading={isSubmitting}>
                  <Spin spinning={isSubmitting} indicator={antIcon} />

                  <span>Entrar</span>
                </ButtonSignIn>
              </div>
            </FormSignIn>
          </ContainerForms>
        </ContainerItems>

        <ModalStyled
          width={857}
          visible={isModalVisible}
          onOk={handleOk}
          style={{ borderRadius: "20px" }}
          bodyStyle={{ paddingTop: 60 }}
          onCancel={handleCancel}
          closeIcon={
            <RiCloseCircleLine
              style={{ marginTop: "14px", marginRight: "34px" }}
              size={43}
              color={Colors.black40}
            />
          }
          footer={null}
        >
          <ContainerModal>
            <div className="content">
              <h2>Cadastro realizado!</h2>

              <p>Uma confirmação foi enviada para seu e-mail.</p>
            </div>

            <div className="ok-button">
              <button onClick={handleOk}>Ok</button>
            </div>
          </ContainerModal>
        </ModalStyled>
      </Container>
    </Wrapper>
  );
}
