import { ADMIN_STATUS } from "constants/adminStatus";
import Datas from "constants/Datas";

export const SIMPLE_FILTERS = [
  {
    value: "ID",
    key: "_id",
    label: "Buscar por ID",
  },
  {
    value: "Nome",
    key: "name",
    label: "Nome",
  },
  {
    value: "CPF/CNPJ",
    key: "organizer_document",
    label: "CPF/CNPJ",
  },
  {
    value: "Filtros avançados",
    key: "",
    label: "Filtros avançados",
  },
];

export const ADVANCED_FILTERS = [
  {
    label: "ID",
    type: "input",
    name: "_id",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o ID",
  },
  {
    label: "Cadastrado entre",
    type: "date",
    gte: "created_gte",
    lte: "created_lte",
  },
  {
    label: "Tipo do evento",
    name: "type_event",
    type: "select",
    options: Datas.TypePlace,
    placeholder: "Escolha o tipo",
  },
  {
    label: "Organizador",
    type: "organizer",
    name: "organizer",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o nome do organizador",
  },
  {
    label: "Que vão ocorrer entre",
    type: "date",
    gte: "begin_gte",
    lte: "begin_lte",
  },
  {
    label: "Categoria",
    type: "category",
  },
  {
    label: "Privacidade",
    name: "type_event",
    type: "select",
    options: Datas.TypePrivacity,
    placeholder: "Escolha o tipo",
  },
  {
    label: "Idade",
    name: "age",
    type: "select",
    options: Datas.Ages,
    placeholder: "Escolha o tipo",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: Datas.Status,
    placeholder: "Escolha o status",
  },
  {
    label: "Status do administrador",
    name: "admin_status",
    type: "select",
    options: ADMIN_STATUS,
    placeholder: "Escolha o status do administrador",
  },
  {
    label: "Nome",
    type: "input",
    name: "name",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o nome",
  },
  {
    label: "Estado",
    type: "state",
  },
  {
    label: "Cidade",
    type: "city",
  },
  {
    label: "CPF/ CNPJ",
    type: "input-document",
    name: "organizer_document",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o cpf/cnpj",
  },
];
