import { ContainerInput, StyledSelect } from "./styles";

import { Select } from "antd";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthOrganizers } from "services/hooks/organizer";
import { OrganizerType } from "types";

const { Option } = Select;

interface Meta {
  total: number;
}

interface OrganizersInterface {
  results: OrganizerType[];
  count: Meta;
}

type OptionType = {
  label: string;
  value: string | number;
};

interface SelectComponentProps {
  name?: string;
  label?: string;
  placeholder?: string;
  options?: OptionType[];
}

export function SelectOrganizer({
  name,
  label,
  placeholder,
  options,
}: SelectComponentProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const organizerId = searchParams.get("organizer_id") ?? "";

  const { data } = useAuthOrganizers<OrganizersInterface>({
    page: 1,
    filters: "&limit=100",
  });

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        organizer_id: value.value,
      });
    },
    [searchParams, setSearchParams]
  );

  const clearId = useCallback(() => {
    searchParams.delete("organizer_id");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  return (
    <ContainerInput>
      <strong>{label}</strong>

      <div className="select">
        <StyledSelect
          bordered={false}
          labelInValue
          defaultValue={organizerId}
          showSearch
          optionFilterProp="children"
          placeholder="Escolha o organizador"
          onChange={handleSelectedIdFilter}
          allowClear
          onClear={clearId}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          style={{
            width: "100%",
            height: "38px",
            outline: 0,
            border: "none",
          }}
        >
          {data?.results.map((organizer) => {
            return (
              <Option value={organizer._id} key={organizer._id}>
                {organizer.name}
              </Option>
            );
          })}
        </StyledSelect>
      </div>
    </ContainerInput>
  );
}
