import { authClient } from "services/authClient";
import useSWR from "swr";

import { FAQType } from "types";

interface useAuthFAQProps {
  page?: string | number;
  filter?: string;
}

export function useAuthFAQ<Data = any, Error = any>({
  page,
  filter,
}: useAuthFAQProps) {
  const url = `/backoffice/faq?page=${page ?? 1}&limit=20` + filter;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((faq: FAQType) => {
      return {
        ...faq,
      };
    });

    const newResponse = { ...response.data, results: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

export function useAuthFAQDetail<Data = any, Error = any>(id: string) {
  const url = `/backoffice/faq/${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    return response.data;
  });

  return { data, error, mutate };
}

interface EditFAQProps {
  faqId: string | undefined;
  credentials: {
    title?: string;
    category?: number;
    description?: string;
  };
}

export async function EditFAQ({ faqId, credentials }: EditFAQProps) {
  const url = `/backoffice/faq/${faqId}`;

  await authClient.patch(url, credentials);
}

interface AddFAQProps {
  title: string;
  category: number;
  description: string;
}

export async function AddFAQ(body: AddFAQProps) {
  const url = `/backoffice/faq`;

  await authClient.post(url, {
    ...body,
    status: true,
  });
}

interface DeleteFAQProps {
  faqId: string | undefined;
}

export async function DeleteFAQ({ faqId }: DeleteFAQProps) {
  const url = `/backoffice/faq/${faqId}`;

  await authClient.delete(url);
}
