import { useEffect, useState } from "react";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { GoChevronRight } from "react-icons/go";

import { Tabs } from "antd";

import { useAuthEvent } from "services/hooks/event";

import { Sidebar } from "components/Sidebar";
import { EmptyDetail } from "components/EmptyDetail";
import { Header } from "components/Header";
import { StatusComponent } from "components/Status";

import { DetailEvent } from "./components/DetailEvent";

import { EventType, TicketType } from "types";
import { formatPrice } from "utils/formatPrice";
import { Colors } from "constants/colors";

import {
  Container,
  ContainerItems,
  ListButtons,
  ContainerItemsTable,
  ButtonBorder,
  ContainerTabs,
  AllEvents,
  ContainerEvents,
  ListEvents,
  Ticket,
} from "./styles";

import { Wrapper } from "./styles";

import {
  Dropdown,
  DropDownContainer,
  LinkStyledLi,
  LinkStyledLiLast,
} from "styles/Dropdown";

import ticketImage from "assets/ticket.svg";
import points from "assets/points.svg";
import eyes_secondary from "assets/eyes-secondary.svg";
import pen_green from "assets/pen-green.svg";
import { useAuthTickets } from "services/hooks/ticket";

const { TabPane } = Tabs;

interface TicketsInterface {
  results: TicketType[];
  count: number;
}

export function EventPage() {
  const [key, setkey] = useState("1");
  const [activeTicket, setActiveTicket] = useState<TicketType | null>();

  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const { data } = useAuthEvent<EventType>(String(id));

  const { data: dataTickets } = useAuthTickets<TicketsInterface>({
    page: 1,
    filters: `&event_id=${id}`,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabKey = searchParams.get("tab_key") ?? "";

  useEffect(() => {
    if (tabKey) {
      setkey(tabKey);
    }
  }, [tabKey]);

  function handleSelectedTicket(ticket: TicketType) {
    if (activeTicket?._id === ticket._id) {
      setActiveTicket(null);

      return;
    }

    setActiveTicket(ticket);
  }

  const handleTabClick = (key: string) => {
    if (key === "3") {
      navigate({
        pathname: "/sales",
        search: `?filter_advanced_status=1&page=1&filter_value=Filtros+avançados&event=${id}`,
      });

      return;
    }

    setkey(key);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      tab_key: key,
    });
  };

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <div className="header">
            <h1>Eventos</h1>
            <GoChevronRight
              className="icon"
              size={28}
              color={Colors.secondary80}
            />

            <h1>Detalhes</h1>
          </div>

          <ListButtons>
            <div className="last">
              <ButtonBorder onClick={() => navigate(-1)}>
                <span>Voltar</span>
              </ButtonBorder>
            </div>
          </ListButtons>
        </ContainerItems>

        <ContainerItemsTable>
          <ContainerTabs>
            <h3 className="title">Eventos</h3>

            {!data ? <EmptyDetail /> : null}

            {data ? (
              <Tabs
                defaultActiveKey="1"
                type="card"
                size={"small"}
                onChange={handleTabClick}
                activeKey={key}
                key={key}
              >
                <TabPane tab="Detalhes" key="1">
                  {id ? <DetailEvent id={id} data={data} /> : null}
                </TabPane>

                <TabPane tab="Ingressos" key="2">
                  <AllEvents>
                    <ContainerEvents>
                      <ListEvents>
                        {dataTickets?.results &&
                        dataTickets?.results.length > 0 ? (
                          dataTickets?.results.map((ticket) => (
                            <Ticket>
                              <div className="first">
                                <img src={ticketImage} alt="event" />
                              </div>

                              <div className="column">
                                <head>
                                  <Link to={`/tickets/${ticket._id}`}>
                                    <h4>
                                      {ticket.name
                                        ? ticket.name.length > 14
                                          ? `${ticket.name.substring(0, 14)}...`
                                          : ticket.name
                                        : ""}
                                    </h4>
                                  </Link>

                                  <div className="options">
                                    <StatusComponent
                                      color="green"
                                      content="ATIVO"
                                    />

                                    <DropDownContainer>
                                      <div
                                        className="area"
                                        onClick={() =>
                                          handleSelectedTicket(ticket)
                                        }
                                      >
                                        <img src={points} alt="" />
                                      </div>

                                      {activeTicket?._id === ticket._id ? (
                                        <Dropdown height="98px">
                                          <ul>
                                            <LinkStyledLi
                                              to={`/tickets/${ticket._id}`}
                                            >
                                              <img
                                                src={eyes_secondary}
                                                alt="eye"
                                              />
                                              <h5>Visualizar ingresso</h5>
                                            </LinkStyledLi>

                                            <LinkStyledLiLast
                                              to={`/tickets/${ticket._id}`}
                                            >
                                              <img src={pen_green} alt="pen" />
                                              <h5>Editar ingresso</h5>
                                            </LinkStyledLiLast>
                                          </ul>
                                        </Dropdown>
                                      ) : null}
                                    </DropDownContainer>
                                  </div>
                                </head>

                                <div className="second">
                                  <div className="local">
                                    <span>{formatPrice(ticket.price)}</span>
                                  </div>
                                </div>
                              </div>
                            </Ticket>
                          ))
                        ) : (
                          <div className="empty">
                            <p>Não ha Ingressos para esse evento</p>
                          </div>
                        )}
                      </ListEvents>
                    </ContainerEvents>
                  </AllEvents>
                </TabPane>

                <TabPane tab="Vendas" key="3" />
              </Tabs>
            ) : null}
          </ContainerTabs>
        </ContainerItemsTable>
      </Container>
    </Wrapper>
  );
}
