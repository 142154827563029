import sao_paulo from "../assets/teatro.jpg";
import cover from "../assets/cover.jpg";

export const cities = [
  { name: "O auto da compadecida", image: sao_paulo },
  { name: "O auto da compadecida", image: sao_paulo },
  { name: "O auto da compadecida", image: sao_paulo },
  { name: "O auto da compadecida", image: sao_paulo },
  { name: "O auto da compadecida", image: sao_paulo },
  { name: "O auto da compadecida", image: sao_paulo },
  { name: "O auto da compadecida", image: sao_paulo },
  { name: "O auto da compadecida", image: sao_paulo },
  { name: "O auto da compadecida", image: sao_paulo },
  { name: "O auto da compadecida", image: sao_paulo },
];

export const categories = [
  { name: "Eventos Online", image: cover },
  { name: "Eventos Online", image: cover },
  { name: "Eventos Online", image: cover },
  { name: "Eventos Online", image: cover },
  { name: "Eventos Online", image: cover },
  { name: "Eventos Online", image: cover },
  { name: "Eventos Online", image: cover },
  { name: "Eventos Online", image: cover },
  { name: "Eventos Online", image: cover },
  { name: "Eventos Online", image: cover },
];
