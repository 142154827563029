import { ContainerInput, StyledSelect } from "./styles";

import { Select } from "antd";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useCitiesByUf } from "services/hooks/city";
import { City } from "types";

const { Option } = Select;

type OptionType = {
  label: string;
  value: string | number;
};

interface SelectComponentProps {
  name?: string;
  label?: string;
  placeholder?: string;
  options?: OptionType[];
  stateSelected?: string;
}

export function SelectCity({
  name,
  label,
  placeholder,
  options,
  stateSelected,
}: SelectComponentProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const address_state = searchParams.get("address_state") ?? "";

  const address_city = searchParams.get("address_city") ?? "";

  const { data: dataCities } = useCitiesByUf<City[]>(address_state ?? "SP");

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        address_city: value.value,
      });
    },
    [searchParams, setSearchParams]
  );

  const clearId = useCallback(() => {
    searchParams.delete("address_city");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  return (
    <ContainerInput>
      <strong>{label}</strong>

      <div className="select">
        <StyledSelect
          bordered={false}
          labelInValue
          showSearch
          disabled={!address_state}
          optionFilterProp="children"
          placeholder="Escolha a cidade"
          onChange={handleSelectedIdFilter}
          value={address_city || undefined}
          allowClear
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onClear={clearId}
          style={{
            width: "100%",
            height: "38px",
            border: "none",
            outline: 0,
          }}
        >
          {dataCities?.map((city) => {
            return (
              <Option value={city.nome} key={city.nome}>
                {city.nome}
              </Option>
            );
          })}
        </StyledSelect>
      </div>
    </ContainerInput>
  );
}
