import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerEvents = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 39px 24px 28px 24px;

  @media (max-width: 768px) {
    padding: 0px 28px 40px 28px;
    margin-top: 0px;
  }

  div.pagination {
    margin-top: 20px;
  }
`;

export const ListEvents = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 200px;

  @media (max-width: 823px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  div.empty {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${Colors.secondary80};
      font-weight: 500;
      font-size: 15px;
    }
  }
`;

export const EventLoading = styled.div`
  width: 48%;
  height: 74px;
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  padding: 12px;

  background: ${Colors.white};
  border: 1px solid ${Colors.border100};
  box-sizing: border-box;
  border-radius: 5px;

  @media (max-width: 900px) {
    min-width: 100%;
    width: 100%;
    /* height: 92px; */
  }

  @media (max-width: 516px) {
    /* height: 122px; */
  }

  div.first {
    img {
      width: 41px;
      height: 37px;
    }
  }

  div.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-direction: flex-start;
    margin-left: 12px;
    margin-top: 0px;

    header {
      display: flex;
      flex-direction: row;
      /* align-items: flex-start; */
      justify-content: space-between;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;

      h4 {
        font-weight: 600;
        font-size: 14px;
        /* line-height: 160%; */
        margin-top: 0px;
        margin-bottom: 0px;

        color: ${Colors.secondary80};
      }

      div.options {
        display: flex;
        flex-direction: row;

        div.area {
          cursor: pointer;
          width: 26px;
          height: 26px;
          margin-left: 12px;

          display: flex;
          align-items: center;
          justify-content: center;

          background: ${Colors.white};
          border: 1px solid ${Colors.border5};
          border-radius: 6px;

          img {
            width: 10px;
            height: 14px;
          }
        }
      }
    }

    div.second {
      display: flex;
      flex-direction: row;
      margin-top: 0px;

      @media (max-width: 516px) {
        flex-direction: column;
      }

      div.local {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 160%;

          color: ${Colors.secondary40};
        }
      }

      div.type {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};

        @media (max-width: 516px) {
          margin-left: 0px;
          padding-left: 0px;
          border-left: none;
        }
      }
    }

    div.third {
      display: flex;
      flex-direction: row;

      div.day {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }
      }

      div.time {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};
      }
    }
  }

  .icon {
    margin-left: 30px;
  }
`;

export const DetailsTicket = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 884px) {
    flex-direction: column-reverse;
  }

  div.first {
    h3.title {
      font-weight: 700;
      font-size: 26px;
      line-height: 160%;

      color: ${Colors.black100};
    }

    @media (max-width: 884px) {
      margin-top: 30px;
    }

    ul {
      margin-top: 40px;

      li {
        list-style: none;
        margin-top: 20px;

        h4 {
          font-weight: 600;
          font-size: 22px;
          color: ${Colors.black100};
          margin-bottom: 0px;
        }

        p {
          font-weight: 400;
          font-size: 18px;
          color: ${Colors.black60};
        }
      }

      li.normal {
        margin-top: 30px;
      }
    }
  }

  div.box {
    width: 320px;
    height: 321px;
    padding: 26px;

    @media (max-width: 884px) {
      width: 100%;
    }

    background: ${Colors.white};
    border: 2px solid ${Colors.background7};
    border-radius: 10px;
  }
`;

export const ContainerModalAddDocument = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 0px 89px 24px 89px;

  @media (max-width: 700px) {
    padding: 0px 20px 24px 0px;
  }

  h2.title {
    font-weight: bold;
    font-size: 32px;
    /* line-height: 160%; */
    text-align: center;
    color: ${Colors.secondary100};

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    text-align: center;

    color: #585b6c;
  }

  h3.account {
    font-weight: 600;
    font-size: 14px;

    color: ${Colors.secondary70};
    margin-top: 44px;

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  div.content {
    width: 100%;
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div.image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 180px;
      height: 220px;
      margin-top: 20px;
    }

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 85px;

      text-align: center;

      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  h2.title {
    font-weight: bold;
    font-size: 32px;
    /* line-height: 160%; */
    text-align: center;
    color: ${Colors.secondary100};
    margin-top: 20.5px;

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    text-align: center;

    color: #585b6c;
  }

  button.ok {
    cursor: pointer;
    border: 0;
    width: 80px;
    height: 50px;
    margin-top: 40px;

    background: ${Colors.primary100};
    box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    border-radius: 14px;

    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: ${Colors.white};
    }
  }
`;
