import useSWR from "swr";

import api from "services/api";

import { authClient } from "services/authClient";
import { EventUserType, PaginatedResponse, UserType } from "types";

import moment from "moment";

import { PurchaseUserType } from "types";

import { TicketUserType } from "types";

interface ForgotPasswordProps {
  credentials: any;
}

export async function ForgotPasswordUser({ credentials }: ForgotPasswordProps) {
  const url = "/auth/forgot";

  await api.post(url, credentials);
}

interface VerifyCodeProps {
  code: string;
}

export async function VerifyCode({ code }: VerifyCodeProps) {
  const url = `/verify-code-password/${code}`;

  await api.get(url);
}

interface ResetPasswordProps {
  credentials: any;
}

export async function ResetPassword({ credentials }: ResetPasswordProps) {
  const url = '/auth/verify'

  await api.post(url, credentials);
}

interface EditUserTicketProps {
  ticketId: string;
  credentials: any;
}

export async function EditUserTicket({
  ticketId,
  credentials,
}: EditUserTicketProps) {
  const url = `/backoffice/user-ticket/edit/${ticketId}`;

  await authClient.patch(url, credentials);
}

type LoginProps = {
  credentials: {
    email: string;
    password: string;
  };
};

export async function Login({ credentials }: LoginProps) {
  const url = "/backoffice/auth/signin";

  const response = await api.post(url, credentials);

  return response;
}

interface GetUserByIdProps {
  id: string | null;
}

export async function GetUserById({ id }: GetUserByIdProps) {
  const url = `/backoffice/user/${id}`;

  const response = await authClient.get(url);

  return response;
}

interface CreateUserAddressProps {
  userId: number | null | undefined;
  credentials: any;
}

export async function CreateUserAddress({
  userId,
  credentials,
}: CreateUserAddressProps) {
  const url = `/backoffice/user/add-address/${userId}`;

  await authClient.post(url, credentials);
}

interface EditUserAddressProps {
  userId: number;
  addressId: number | undefined;
  credentials: any;
}

export async function EditUserAddress({
  userId,
  addressId,
  credentials,
}: EditUserAddressProps) {
  const url = `/backoffice/user/edit-address/${userId}/${addressId}`;

  await authClient.patch(url, credentials);
}

interface DeleteUserAddressProps {
  addressId: number | undefined;
}

export async function DeleteUserAddress({ addressId }: DeleteUserAddressProps) {
  const url = `backoffice/user/remove-address/${addressId}`;

  await authClient.delete(url);
}

interface GetUsersProps {
  page?: number;
}

export async function GetUsers({ page }: GetUsersProps) {
  const url = `/backoffice/users?page=${page ?? 1}&limit=10`;

  const response = await authClient.get(url);

  return response;
}

interface useAuthEventsUserProps {
  userId: number | null;
  page?: string | number;
}

export function useAuthEventsUser<Data = any, Error = any>({
  userId,
  page,
}: useAuthEventsUserProps) {
  const url = `/backoffice/user/${userId}/events?page=${page}&limit=10`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.data.map((event: EventUserType) => {
      const date = moment(event.begin_date);
      const dateComponent = date.utc().format("DD/MM/YYYY");

      return {
        ...event,
        formattedBeginDate: dateComponent,
      };
    });

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

interface useAuthPurchasesUserProps {
  userId?: string;
  page: string | number;
}

export function useAuthPurchasesUser<Data = any, Error = any>({
  userId,
  page,
}: useAuthPurchasesUserProps) {
  const url = `/backoffice/purchase/filter?page=${page}&limit=10&user_id=${userId}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((ticket: PurchaseUserType) => {
      return {
        ...ticket,
        total: ticket?.total ? ticket?.total / 100 : 0,
      };
    });

    const newResponse = { ...response.data, results: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

interface useAuthTicketsUserProps {
  userId: number | null;
  page: string | number;
}

export function useAuthTicketsUser<Data = any, Error = any>({
  userId,
  page,
}: useAuthTicketsUserProps) {
  const url = `/backoffice/user-ticket/filter/?page=${page}&limit=10&user=${userId}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((ticket: TicketUserType) => {
      return {
        ...ticket,
      };
    });

    const newResponse = { ...response.data, results: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

interface GetUserTicketsProps {
  userId: number | null;
  page: string | number;
}

export async function GetUserTickets({ userId, page }: GetUserTicketsProps) {
  const url = `/backoffice/user/${userId}/tickets?page=${page}&limit=10`;

  const response = await authClient.get(url);

  return response;
}

interface GetUserEventsProps {
  userId: number | null;
  page: string | number;
}

export async function GetUserEvents({ userId, page }: GetUserEventsProps) {
  const url = `/backoffice/user/${userId}/events?page=${page}&limit=10`;

  const response = await authClient.get(url);

  return response;
}

interface EditUserProps {
  userId: string | undefined;
  credentials: any;
}

export async function EditUser({ userId, credentials }: EditUserProps) {
  const url = `/backoffice/user/${userId}`;

  await authClient.patch(url, credentials);
}

interface useAuthUsersProps {
  page?: string | number;
  filters?: any;
}

export function useAuthUsers<Data = any, Error = any>({
  filters,
  page = 1,
}: useAuthUsersProps) {
  const url = `/backoffice/user/filter?page=${page}&limit=20${
    filters && `&${filters}`
  }`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((user: UserType) => {
      let birthdate = "";

      const createdAt = moment(user?.created_at);
      const createdAtComponent = createdAt.utc().format("DD/MM/YYYY");

      if (user.birthdate) {
        const split = user.birthdate.split("-");

        birthdate = `${split[2]}/${split[1]}/${split[0]}`;
      }

      return {
        ...user,
        formattedBirthdate: birthdate,
        formattedCreatedAt: createdAtComponent,
        isOpen: false,
      };
    });

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

interface useAuthUserProps {
  userId: string | undefined;
}

export function useAuthUser<Data = any, Error = any>({
  userId,
}: useAuthUserProps) {
  const url = `/backoffice/user/${userId}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const user = response.data;

    let birthdate = "";

    if (user.birthday) {
      const date = moment(user.birthday);

      birthdate = date.utc().format("DD/MM/YYYY");

      // if (split[1] === undefined) {
      //   birthdate = user.birthday;
      // }
    }

    const newResponse = { ...user, formattedBirthdate: birthdate };

    return newResponse;
  });

  return { data, error, mutate };
}

type UseAuthEventTicketDto = {
  event_id?: string;
};

type UseAuthEventTicketResponse = PaginatedResponse<{
  _id: string;
  deleted_at: string | null;
  absorb_rate: boolean;
  event_id: string;
  name: string;
  description: string;
  price: number;
  discount: number;
  quantity: number;
  quantity_min: number;
  quantity_max: number;
  transfer: boolean;
  guest: boolean;
  guest_quantity: number | null;
  status: boolean;
  type_sale: number;
  type_ticket: number;
  sale_begin_date: string;
  sale_begin_hour: string;
  sale_end_date: string;
  sale_end_hour: string;
  created_at: string;
  category: string;
}>;

export function useAuthEventTicket({ event_id }: UseAuthEventTicketDto) {
  const url = `/backoffice/event-ticket/filter?event_id=${event_id}`;

  return useSWR(url, async () => {
    const { data } = await authClient.get<UseAuthEventTicketResponse>(url);

    return data.results[0];
  });
}
