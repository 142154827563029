import { ChangeEvent, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ContainerInput } from "./styles";
import { cnpj, cpf, cpfNormal, cnpjToString } from "utils/masks";

interface InputProps {
  name: string;
  label: string;
  className: string;
  type: string;
  placeholder: string;
}

export function InputDocument({
  name,
  label,
  className,
  type,
  placeholder,
}: InputProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [text, setText] = useState("");

  const handleChangeTextFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 14) {
        setText(cpfNormal(e.target.value));

        setSearchParams({
          ...Object.fromEntries([...searchParams]),
          [name]: cpfNormal(e.target.value),
        });

        return;
      }

      if (e.target.value.length > 14) {
        setText(cnpjToString(e.target.value));

        setSearchParams({
          ...Object.fromEntries([...searchParams]),
          [name]: cnpjToString(e.target.value),
        });

        return;
      }
    },
    [name, searchParams, setSearchParams]
  );

  const handleKeyUpCpf = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cpf(e);
  }, []);

  const handleKeyUpCnpj = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      cnpj(e);
    },
    []
  );

  return (
    <ContainerInput>
      <strong>{label}</strong>

      <input
        className={className}
        type={type}
        value={text}
        placeholder={placeholder}
        onChange={handleChangeTextFilter}
        onKeyUp={text.length <= 14 ? handleKeyUpCpf : handleKeyUpCnpj}
      />
    </ContainerInput>
  );
}
