import axios from "axios";

const API_VERSION = "v1"

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${API_VERSION}`,

  auth: {
    username: `${process.env.REACT_APP_USERNAME}`,
    password: `${process.env.REACT_APP_PASSWORD}`,
  },
});

export default api;
