import { Colors } from "constants/colors";
import styled from "styled-components";

interface TrProps {
  active: boolean;
}

export const Tr = styled.tr<TrProps>`
  height: ${(props) => (props.active ? "77px" : "77px")};
  max-width: 900px;
  width: 100%;

  div.all {
    padding-top: 10px;
  }
`;
