import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useSWRConfig } from "swr";

import { LoadingOutlined } from "@ant-design/icons";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import moment from "moment";

import { Select, Spin, Tabs, TimePicker, Pagination, DatePicker } from "antd";

import Skeleton from "react-loading-skeleton";

import { Header } from "components/Header";
import { EmptyDetail } from "components/EmptyDetail";
import { StatusComponent } from "components/Status";
import { Sidebar } from "components/Sidebar";

import { Colors } from "constants/colors";

import { GoChevronRight } from "react-icons/go";

import { PurchaseType, TicketType } from "types";

import { useAuthTicket } from "services/hooks/ticket";
import { useAuthSales } from "services/hooks/sale";

import { currency, maskOnlyNumbers } from "utils/masks";

import {
  Container,
  ContainerItems,
  ListButtons,
  ContainerItemsTable,
  ButtonBorder,
  ContainerTabs,
  ContainerForm,
  FormFull,
  ContainerInput,
  FormLabel,
  ContainerButtons,
  ButtonRemove,
  ButttonEdit,
  AllEvents,
  ContainerEvents,
  ListEvents,
  StyledSelect,
  EventLoading,
  Wrapper,
} from "./styles";

import points from "assets/points.svg";
import { EditTicket } from "services/hooks/ticket";
import TicketPurchase from "./TicketPurchase";

const { TabPane } = Tabs;
const { Option } = Select;

interface PurchasesInterface {
  results: PurchaseType[];
  count: number;
}

type TicketFormData = {
  type_ticket: number;
  type_sale: number;
  name: string;
  description: string;
  quantity: number;
  user_quantity_min: number;
  user_quantity_max: number;
  price: number;
  discount: number;
  transfer: boolean | string;
  sale_ticket: number;
  sale_begin_date: string;
  sale_begin_hour: string;
  sale_end_date: string;
  sale_end_hour: string;
  archive: string | null;
  absorb_rate: boolean | string;
  status: boolean;
};

const ticketFormSchema = yup.object().shape({
  name: yup.string().required("Senha obrigatória"),
  type_ticket: yup.string().required("Senha obrigatória"),
  type_sale: yup.string().required("Senha obrigatória"),
  description: yup.string().required("Senha obrigatória"),
  quantity: yup.string().required("Senha obrigatória"),
  user_quantity_min: yup.string().required("Senha obrigatória"),
  user_quantity_max: yup.string().required("Senha obrigatória"),
  price: yup.string().required("Senha obrigatória"),
  discount: yup.string().notRequired(),
  transfer: yup.string().required("Senha obrigatória"),
  sale_ticket: yup.string().notRequired(),
  sale_begin_date: yup.string().required("Senha obrigatória"),
  sale_begin_hour: yup.string().required("Senha obrigatória"),
  sale_end_date: yup.string().required("Senha obrigatória"),
  sale_end_hour: yup.string().required("Senha obrigatória"),
  archive: yup.string().notRequired(),
  absorb_rate: yup.string().required("Senha obrigatória"),
});

const dateFormat = "DD/MM/YYYY";
// const monthFormat = "YYYY/MM";
const formatHour = "HH:mm";

export function TicketPage() {
  const [isEditing, setIsEditing] = useState(false);

  const { id } = useParams();

  const { mutate } = useSWRConfig();

  const navigate = useNavigate();

  const [pagePurchase, setPagePurchase] = useState(1);

  const { data: dataPurchases } = useAuthSales<PurchasesInterface>({
    page: pagePurchase,
    filters: `ticket_id=${id}`,
  });

  const onChangePurchase = (currentPage: number) => {
    setPagePurchase(currentPage);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<TicketFormData>({
    resolver: yupResolver(ticketFormSchema),
  });

  // const { data } = useAuthTickets<TicketsInterface>({
  //   filters: `id=${id}`,
  // });

  const { data } = useAuthTicket<TicketType>(String(id));

  // const activeTicket = data?.data[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    reset({
      status: data?.status,
      name: data?.name,
      description: data?.description,
      quantity: data?.quantity,
      price: data?.price,
      discount: data?.discount,
      type_ticket: data?.type_ticket,
      type_sale: data?.type_sale,
      sale_begin_date: data?.formattedSaleBeginDate,
      sale_end_date: data?.formattedSaleEndDate,
      sale_begin_hour: data?.formattedSaleBeginHour,
      sale_end_hour: data?.formattedSaleEndHour,
      transfer: data?.transfer,
      absorb_rate: data?.absorb_rate,
      user_quantity_min: data?.quantity_min,
      user_quantity_max: data?.quantity_max,
    });
  }, [data, reset]);

  const handlePutTicket: SubmitHandler<TicketFormData> = async ({
    ...dataTicket
  }) => {
    const {
      status,
      type_ticket,
      type_sale,
      name,
      description,
      quantity,
      user_quantity_min,
      user_quantity_max,
      price,
      discount,
      transfer,
      sale_begin_date,
      sale_begin_hour,
      sale_end_date,
      sale_end_hour,
      absorb_rate,
    } = dataTicket;

    try {
      const newDateBegin = sale_begin_date.split("/");

      const dateComponentBegin = `${newDateBegin[2]}-${newDateBegin[1]}-${newDateBegin[0]}`;

      const newDateEnd = sale_end_date.split("/");

      const dateComponentEnd = `${newDateEnd[2]}-${newDateEnd[1]}-${newDateEnd[0]}`;

      const credentials = {
        type_ticket,
        type_sale: Number(type_sale),
        name,
        description,
        quantity: Number(quantity),
        quantity_min: Number(user_quantity_min),
        quantity_max: Number(user_quantity_max),
        price: Number(price),
        discount: Number(discount) ?? 0,
        transfer: transfer === "false" || !absorb_rate ? false : true,
        ...(Number(type_sale) === 2 && { sale_ticket: data?._id }),
        sale_begin_date: dateComponentBegin,
        sale_begin_hour,
        sale_end_date: dateComponentEnd,
        sale_end_hour,
        absorb_rate: absorb_rate === "false" || !absorb_rate ? false : true,
        status,
      };

      await EditTicket({ ticketId: data?._id, credentials });

      setIsEditing(false);

      mutate("/tickets");

      alert("Ingresso atualizado com sucesso");
    } catch (err) {}
  };

  const watchingTypeTicket = watch("type_ticket");

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const handleKeyNumbers = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      maskOnlyNumbers(e);
    },
    []
  );

  const handleKeyPrice = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    currency(e);
  }, []);

  useEffect(() => {
    if (watchingTypeTicket === 2) {
      setValue("price", 0, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("discount", 0, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue("price", data?.price ?? 0, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("discount", data?.discount ?? 0, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [data?.discount, data?.price, setValue, watchingTypeTicket]);

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <div className="header">
            <h1>Ingressos</h1>
            <GoChevronRight
              className="icon"
              size={28}
              color={Colors.secondary80}
            />

            <h1>Detalhes</h1>
          </div>
          <h2 className="box">Caixa de ferramentas</h2>

          <ListButtons>
            <div className="last">
              <ButtonBorder onClick={() => navigate(-1)}>
                <span>Voltar</span>
              </ButtonBorder>
            </div>
          </ListButtons>
        </ContainerItems>

        <ContainerItemsTable>
          <ContainerTabs>
            <h3 className="title">Ingressos</h3>

            {!data ? <EmptyDetail /> : null}

            {data ? (
              <Tabs defaultActiveKey="1" type="card" size={"small"}>
                <TabPane tab="Detalhes" key="1">
                  <ContainerForm onSubmit={handleSubmit(handlePutTicket)}>
                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Nome do ingresso</h2>
                        </FormLabel>

                        <input
                          defaultValue={data?.name}
                          {...register("name")}
                          disabled={!isEditing}
                        />

                        {errors.name ? (
                          <span className="error">Insira um nome válido</span>
                        ) : null}
                      </ContainerInput>

                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Descrição do ingresso</h2>
                        </FormLabel>

                        <input
                          defaultValue={data?.description}
                          {...register("description")}
                          disabled={!isEditing}
                        />

                        {errors.description ? (
                          <span className="error">
                            Insira uma descrição válida
                          </span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Quantidade</h2>
                        </FormLabel>

                        <input
                          defaultValue={data?.quantity}
                          {...register("quantity")}
                          onKeyUp={handleKeyNumbers}
                          disabled={!isEditing}
                        />

                        {errors.quantity ? (
                          <span className="error">
                            Insira um quantidade válida
                          </span>
                        ) : null}
                      </ContainerInput>

                      <ContainerInput
                        hasPrefix
                        isEditing={isEditing && watchingTypeTicket !== 2}
                      >
                        <FormLabel>
                          <h2>Valor</h2>
                        </FormLabel>

                        <div className="hasPrefix">
                          <h3 className="prefix">R$</h3>

                          <input
                            defaultValue={data?.price}
                            {...register("price")}
                            onKeyUp={handleKeyPrice}
                            disabled={!isEditing || watchingTypeTicket === 2}
                          />
                        </div>

                        {errors.price ? (
                          <span className="error">Insira um valor valido</span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInput
                        hasPrefix
                        isEditing={isEditing && watchingTypeTicket !== 2}
                      >
                        <FormLabel>
                          <h2>Desconto</h2>
                        </FormLabel>

                        <div className="hasPrefix">
                          <h3 className="prefix">R$</h3>

                          <input
                            defaultValue={data?.discount}
                            {...register("discount")}
                            onKeyUp={handleKeyPrice}
                            disabled={!isEditing || watchingTypeTicket === 2}
                          />
                        </div>
                      </ContainerInput>

                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Status</h2>
                        </FormLabel>

                        <div className="select">
                          <Controller
                            control={control}
                            name="status"
                            rules={{ required: "Salutation is required" }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <StyledSelect
                                bordered={false}
                                labelInValue
                                placeholder="Escolha o status"
                                disabled={!isEditing}
                                value={value}
                                onChange={(e: any) => {
                                  onChange(e.value);
                                }}
                                style={{
                                  width: "100%",
                                  minHeight: "100%",
                                  border: "none",
                                  outline: 0,
                                }}
                              >
                                <Option value={true} key={1}>
                                  ATIVO
                                </Option>

                                <Option value={false} key={2}>
                                  INATIVO
                                </Option>
                              </StyledSelect>
                            )}
                          />
                        </div>

                        {errors.type_ticket ? (
                          <span className="error">Insira um tipo valido</span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Data inicial</h2>
                        </FormLabel>

                        <Controller
                          control={control}
                          name="sale_begin_date"
                          rules={{ required: "Salutation is required" }}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <DatePicker
                              format={dateFormat}
                              value={value ? moment(value, dateFormat) : null}
                              disabled={!isEditing}
                              onChange={(date: unknown, dateString: string) => {
                                if (!dateString) {
                                  onChange(null);
                                  return;
                                }

                                onChange(dateString);
                              }}
                            />
                          )}
                        />

                        {errors.sale_begin_date ? (
                          <span className="error">Insira uma data valida</span>
                        ) : null}
                      </ContainerInput>

                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Data final</h2>
                        </FormLabel>

                        <Controller
                          control={control}
                          name="sale_end_date"
                          rules={{ required: "Salutation is required" }}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <DatePicker
                              format={dateFormat}
                              disabled={!isEditing}
                              defaultValue={
                                data?.sale_end_date
                                  ? moment(
                                      new Date(data.sale_end_date),
                                      dateFormat
                                    )
                                  : undefined
                              }
                              onChange={(date: unknown, dateString: string) => {
                                if (!dateString) {
                                  onChange(null);
                                  return;
                                }

                                onChange(dateString);
                              }}
                            />
                          )}
                        />

                        {errors.sale_end_date ? (
                          <span className="error">Insira uma data valida</span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Horario inicial</h2>
                        </FormLabel>

                        <Controller
                          control={control}
                          name="sale_begin_hour"
                          defaultValue={data?.sale_begin_hour}
                          rules={{ required: "Salutation is required" }}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <TimePicker
                              disabled={!isEditing}
                              onChange={(date: unknown, timeString: string) => {
                                if (!timeString) {
                                  onChange(null);
                                  return;
                                }

                                onChange(timeString);
                              }}
                              value={value ? moment(value, formatHour) : null}
                              format={formatHour}
                            />
                          )}
                        />

                        {errors.sale_begin_hour ? (
                          <span className="error">
                            Insira um horario valido
                          </span>
                        ) : null}
                      </ContainerInput>

                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Horario final</h2>
                        </FormLabel>

                        <Controller
                          control={control}
                          name="sale_end_hour"
                          defaultValue={data?.sale_end_hour}
                          rules={{ required: "Salutation is required" }}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <TimePicker
                              disabled={!isEditing}
                              onChange={(date: unknown, timeString: string) => {
                                if (!timeString) {
                                  onChange(null);
                                  return;
                                }

                                onChange(timeString);
                              }}
                              value={value ? moment(value, formatHour) : null}
                              format={formatHour}
                            />
                          )}
                        />

                        {errors.sale_end_hour ? (
                          <span className="error">
                            Insira um horario valido
                          </span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Tipo de ingresso</h2>
                        </FormLabel>

                        <div className="select">
                          <Controller
                            control={control}
                            name="type_ticket"
                            rules={{ required: "Salutation is required" }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <StyledSelect
                                bordered={false}
                                labelInValue
                                placeholder="Escolha o tipo"
                                disabled={!isEditing}
                                value={value}
                                onChange={(e: any) => {
                                  onChange(e.value);
                                }}
                                style={{
                                  width: "100%",
                                  minHeight: "100%",
                                  border: "none",
                                  outline: 0,
                                }}
                              >
                                <Option value={1} key={1}>
                                  Ingresso Comum
                                </Option>

                                <Option value={2} key={2}>
                                  Convite
                                </Option>

                                <Option value={3} key={3}>
                                  Inscrição
                                </Option>
                              </StyledSelect>
                            )}
                          />
                        </div>

                        {errors.type_ticket ? (
                          <span className="error">Insira um tipo valido</span>
                        ) : null}
                      </ContainerInput>

                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Tipo de venda</h2>
                        </FormLabel>

                        <div className="select">
                          <Controller
                            control={control}
                            name="type_sale"
                            rules={{ required: "Salutation is required" }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <StyledSelect
                                bordered={false}
                                labelInValue
                                placeholder="Escolha o tipo"
                                disabled={!isEditing}
                                value={value}
                                onChange={(e: any) => {
                                  onChange(e.value);
                                }}
                                style={{
                                  width: "100%",
                                  minHeight: "100%",
                                  border: "none",
                                  outline: 0,
                                }}
                              >
                                <Option value={1} key={1}>
                                  Venda por Data
                                </Option>

                                <Option value={2} key={2}>
                                  Venda por Lote
                                </Option>
                              </StyledSelect>
                            )}
                          />
                        </div>

                        {errors.type_sale ? (
                          <span className="error">Insira um tipo valido</span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Quantidade mínima</h2>
                        </FormLabel>

                        <input
                          defaultValue={data?.quantity_min}
                          {...register("user_quantity_min")}
                          onKeyUp={handleKeyNumbers}
                          disabled={!isEditing}
                        />

                        {errors.user_quantity_min ? (
                          <span className="error">
                            Insira um quantidade valida
                          </span>
                        ) : null}
                      </ContainerInput>

                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Quantidade máxima</h2>
                        </FormLabel>

                        <input
                          defaultValue={data?.quantity_max}
                          {...register("user_quantity_max")}
                          onKeyUp={handleKeyNumbers}
                          disabled={!isEditing}
                        />

                        {errors.user_quantity_max ? (
                          <span className="error">
                            Insira um quantidade valida
                          </span>
                        ) : null}
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Permitir transferência do ingresso?</h2>
                        </FormLabel>

                        <div className="select">
                          <Controller
                            control={control}
                            name="transfer"
                            rules={{ required: "Salutation is required" }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <StyledSelect
                                bordered={false}
                                labelInValue
                                placeholder="Escolha o tipo"
                                disabled={!isEditing}
                                value={value}
                                onChange={(e: any) => {
                                  onChange(e.value);
                                }}
                                style={{
                                  width: "100%",
                                  minHeight: "100%",
                                  border: "none",
                                  outline: 0,
                                }}
                              >
                                <Option value={true} key={1}>
                                  SIM
                                </Option>

                                <Option value={false} key={2}>
                                  NÃO
                                </Option>
                              </StyledSelect>
                            )}
                          />
                        </div>
                      </ContainerInput>

                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Absorver taxa de serviço?</h2>
                        </FormLabel>

                        <div className="select">
                          <Controller
                            control={control}
                            name="absorb_rate"
                            rules={{ required: "Salutation is required" }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <StyledSelect
                                bordered={false}
                                labelInValue
                                placeholder="Escolha o tipo"
                                disabled={!isEditing}
                                value={value}
                                onChange={(e: any) => {
                                  onChange(e.value);
                                }}
                                style={{
                                  width: "100%",
                                  minHeight: "100%",
                                  border: "none",
                                  outline: 0,
                                }}
                              >
                                <Option value={true} key={1}>
                                  SIM
                                </Option>

                                <Option value={false} key={2}>
                                  NÃO
                                </Option>
                              </StyledSelect>
                            )}
                          />
                        </div>
                      </ContainerInput>
                    </FormFull>

                    <ContainerButtons>
                      {isEditing ? (
                        <ButtonRemove onClick={() => setIsEditing(false)}>
                          Cancelar
                        </ButtonRemove>
                      ) : null}

                      {isEditing ? (
                        <ButttonEdit type="submit" isLoading={isSubmitting}>
                          <Spin spinning={isSubmitting} indicator={antIcon} />

                          <span>Salvar</span>
                        </ButttonEdit>
                      ) : null}

                      {!isEditing ? (
                        <ButttonEdit
                          type="button"
                          onClick={() => setIsEditing(true)}
                        >
                          Editar
                        </ButttonEdit>
                      ) : null}
                    </ContainerButtons>
                  </ContainerForm>
                </TabPane>
                <TabPane tab="Vendas" key="2">
                  <AllEvents>
                    <ContainerEvents>
                      <div className="total">
                        <h3>
                          <strong>{dataPurchases?.count}</strong>{" "}
                          {dataPurchases?.count !== 1 ? "vendas" : "venda"}{" "}
                          neste ingresso
                        </h3>
                      </div>

                      <ListEvents>
                        {!dataPurchases?.results
                          ? Array.from({ length: 10 }).map((index) => (
                              <EventLoading>
                                <div className="first">
                                  <Skeleton height="100%" width={60} />
                                </div>

                                <div className="column">
                                  <head>
                                    <Skeleton height={18} width={60} />

                                    <div className="options">
                                      <StatusComponent
                                        color="green"
                                        content="ATIVO"
                                      />

                                      <div className="area">
                                        <img src={points} alt="" />
                                      </div>
                                    </div>
                                  </head>

                                  <div className="second">
                                    <div className="local">
                                      <Skeleton height={18} width={90} />
                                    </div>
                                  </div>
                                </div>
                              </EventLoading>
                            ))
                          : null}

                        {dataPurchases?.results &&
                        dataPurchases?.results.length > 0
                          ? dataPurchases.results.map((purchase, index) => {
                              return (
                                <TicketPurchase
                                  purchase={purchase}
                                  index={index}
                                />
                              );
                            })
                          : null}

                        {dataPurchases?.results &&
                        dataPurchases.results.length === 0 ? (
                          <div className="empty">
                            <p>Não ha vendas para esse Ingresso</p>
                          </div>
                        ) : null}
                      </ListEvents>

                      {dataPurchases?.results &&
                      dataPurchases?.results.length > 0 ? (
                        <div className="pagination">
                          <Pagination
                            current={pagePurchase}
                            onChange={onChangePurchase}
                            total={dataPurchases?.count}
                          />
                        </div>
                      ) : null}
                    </ContainerEvents>

                    {data?.tickets && data?.tickets.length > 0 ? (
                      <ContainerButtons>
                        <ButtonRemove>Excluir</ButtonRemove>

                        <ButttonEdit>Editar</ButttonEdit>
                      </ContainerButtons>
                    ) : null}
                  </AllEvents>
                </TabPane>
              </Tabs>
            ) : null}
          </ContainerTabs>
        </ContainerItemsTable>
      </Container>
    </Wrapper>
  );
}
