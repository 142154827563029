import { createContext, useContext } from "react";

type VisualContextData = {
  createBanner(): void;
  updateBanner(): void;
  deleteBanner(): void;
  createHighlightedEvent(): void;
  updateHighlightedEvent(): void;
  deleteHighlightedEvent(): void;
  createCategory(): void;
  updateCategory(): void;
  deleteCategory(): void;
};

const VisualContext = createContext<VisualContextData>({} as VisualContextData);

export const VisualProvider: React.FC = ({ children }) => {
  async function createBanner() {}

  async function updateBanner() {}

  async function deleteBanner() {}

  async function createHighlightedEvent() {}

  async function updateHighlightedEvent() {}

  async function deleteHighlightedEvent() {}

  async function createCategory() {}

  async function updateCategory() {}

  async function deleteCategory() {}

  return (
    <VisualContext.Provider
      value={{
        createBanner,
        updateBanner,
        deleteBanner,
        createHighlightedEvent,
        updateHighlightedEvent,
        deleteHighlightedEvent,
        createCategory,
        updateCategory,
        deleteCategory,
      }}
    >
      {children}
    </VisualContext.Provider>
  );
};

export function useVisual(): VisualContextData {
  const context = useContext(VisualContext);

  if (!context) {
    throw new Error(`useVisual must be used within a VisualProvider`);
  }

  return context;
}
