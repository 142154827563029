import { Container, ContainerItemsTable } from "./styles";
import { Sidebar } from "components/Sidebar";

import { Table } from "./components/Table";

import { Wrapper } from "./styles";
import { Header } from "components/Header";
import { useAuthEvents } from "services/hooks/event/index";
import { EventType } from "types";

import { useCallback, useEffect, useState } from "react";

import { PaginationFilterComponent } from "components/PaginationFilter";
import { useSearchParams } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import { paramsToString } from "utils/searchOptions";

interface EventsInterface {
  results: EventType[];
  count: number;
}

export default function EventsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const debouncedSearch = useDebounce(paramsString, 1000);

  const { data } = useAuthEvents<EventsInterface>({
    page: pageCurrent ?? 1,
    filters: debouncedSearch,
  });

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });
    },

    [searchParams, setSearchParams]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, searchParams, setSearchParams]);

  const filterAdvancedStatus = searchParams.get("filter_advanced_status");

  const idFilter = searchParams.get("id") ?? "";
  const name = searchParams.get("name") ?? "";
  const organizerName = searchParams.get("organizer_name") ?? "";
  const organizer = searchParams.get("organizer") ?? "";
  const createdGte = searchParams.get("created_gte") ?? "";
  const createdLte = searchParams.get("created_lte") ?? "";
  const beginGte = searchParams.get("begin_gte") ?? "";
  const beginLte = searchParams.get("begin_lte") ?? "";
  const organizerDocument = searchParams.get("organizer_document") ?? "";
  const address_state = searchParams.get("address_state") ?? "";
  const address_city = searchParams.get("address_city") ?? "";
  const typeEvent = searchParams.get("type_event") ?? "";
  const age = searchParams.get("age") ?? "";
  const privacity = searchParams.get("privacity") ?? "";
  const categories = searchParams.get("category") ?? "";
  const status = searchParams.get("status") ?? "";
  const admin_status = searchParams.get("admin_status") ?? "";

  useEffect(() => {
    if (filterAdvancedStatus === "1") {
      const paramsString = new URLSearchParams(
        paramsToString({
          _id: idFilter,
          name,
          organizer_name: organizerName,
          organizer,
          created_gte: createdGte,
          created_lte: createdLte,
          begin_gte: beginGte,
          begin_lte: beginLte,
          organizer_document: organizerDocument,
          address_state,
          address_city,
          type_event: typeEvent,
          age,
          privacity,
          categories,
          status,
          admin_status,
        })
      );

      setParamsString(paramsString);
    }
  }, [
    address_city,
    address_state,
    age,
    beginGte,
    beginLte,
    categories,
    createdGte,
    createdLte,
    filterAdvancedStatus,
    idFilter,
    name,
    organizer,
    organizerDocument,
    organizerName,
    privacity,
    status,
    typeEvent,
    admin_status,
  ]);

  const filterId = searchParams.get("filter_id") ?? "";
  const filterText = searchParams.get("filter_text") ?? "";

  useEffect(() => {
    if (filterAdvancedStatus === "0") {
      const paramsString = new URLSearchParams(
        paramsToString({
          simple_filter_id: filterId,
          simple_filter_text: filterText,
        })
      );

      setParamsString(paramsString);
    }
  }, [filterAdvancedStatus, filterId, filterText]);

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />

        <ContainerItemsTable>
          <h1>Eventos</h1>

          <h3 className="title">Eventos</h3>
          <Table data={data?.results} total={data?.count ?? null} />
          <div className="pagination">
            <PaginationFilterComponent
              current={page}
              onChange={onChange}
              total={data?.count ?? 0}
            />
          </div>
        </ContainerItemsTable>
      </Container>
    </Wrapper>
  );
}
