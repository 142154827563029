import { Status } from "./styles";

interface StatusComponentProps {
  content: string;
  color: "green" | "red" | "yellow" | "orange" | "gray" | "darkBlue" | string;
  size?: "small";
}

export function StatusComponent({
  content,
  color,
  size,
}: StatusComponentProps) {
  return (
    <Status color={color} size={size}>
      <span>{content}</span>
    </Status>
  );
}
