import { Select } from "antd";
import styled, { css } from "styled-components";
import { Colors } from "../../constants/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerItems = styled.div`
  max-width: 1180px;
  width: 100%;
  padding: 60px 16px 0px 246px;
  /* margin-bottom: 120px; */

  /* div.header {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg.icon {
      margin-right: 10px;
      margin-left: 10px;
    }
  } */

  @media (max-width: 1120px) {
    padding: 104px 16px 0px 28px;
  }

  @media (max-width: 768px) {
    padding: 104px 16px 0px 70px;
  }

  @media (max-width: 621px) {
    padding: 104px 16px 0px 30px;

    /* h2.box {
      display: none;
    } */
  }

  h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 100%;
    /* line-height: 100%; */
    /* identical to box height, or 36px */

    color: ${Colors.secondary90};
    margin-bottom: 0px;
  }

  /* h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;

    color: ${Colors.secondary80};
    margin-top: 32px;

    @media (max-width: 621px) {
      margin-bottom: 0px;
    }
  } */
`;

export const ListButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 33px;

  @media (max-width: 740px) {
    margin-top: 16px;
  }

  div.last {
    display: flex;
    flex-direction: row;
    align-items: center;

    /* @media (max-width: 1070px) {
      display: none;
    } */
  }
`;

export const Button = styled.button`
  border: 0;
  cursor: pointer;
  outline: 0;
  width: 137px;
  height: 34px;

  background: ${Colors.primary100};
  border-radius: 97px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;

    color: ${Colors.white};
  }
`;

export const ButtonBorder = styled.button`
  margin-left: 40px;
  min-width: 107px;
  height: 34px;
  padding: 8px 24px;
  border: 0;
  background: ${Colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 97px;

  span {
    font-weight: normal;
    font-size: 18px;

    margin-left: 12px;
    color: ${Colors.primary100};
    margin-bottom: 0px;
  }
`;

export const ContainerCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
`;

export const Card = styled.div`
  width: 703px;
  min-height: 963px;
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  background: #ffffff;
  border: 1px solid ${Colors.border5};
  box-shadow: 0px 25px 33px rgba(108, 93, 211, 0.05);
  border-radius: 8px;

  padding: 24px 24px 24px 26px;

  div.header {
    height: 54px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 21px;
    border-bottom: 1px solid ${Colors.border5};

    div.first {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex;

      h2.title {
        font-weight: 600;
        font-size: 22px;
        /* line-height: 33px; */
        /* identical to box height */

        color: ${Colors.secondary80};
        margin-left: 14px;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

    div.area {
      cursor: pointer;
      width: 36px;
      height: 36px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: #ffffff;
      border: 1px solid #f2f0fc;
      border-radius: 6px;
    }
  }
`;

export const Border = styled.nav`
  height: 90px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 21px;
  border-bottom: 1px solid ${Colors.border5};
  margin-top: 13px;

  @media (max-width: 414px) {
    height: 148px;
  }

  @media (max-width: 712px) {
    height: 138px;
  }

  div.point {
    width: 10px !important;
    height: 10px !important;

    background: #d40050;
    border-radius: 50%;
  }

  div.content {
    display: flex;
    flex-direction: row;

    div.column {
      display: flex;
      flex-direction: column;
      margin-left: 12px;

      @media (max-width: 414px) {
        width: 86%;

        h3 {
          font-size: 14px;
        }

        h4 {
          font-size: 12px;
        }

        p {
          font-size: 10px;
        }
      }

      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        color: #504079;
      }

      h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        display: flex;
        align-items: center;

        color: #504079;
      }

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;

        color: #a79fbc;
      }
    }
  }
`;

interface CircleProps {
  type?: string;
}

export const Circle = styled.div<CircleProps>`
  width: 36px !important;
  height: 36px !important;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${(props) =>
    props.type === "normal" &&
    css`
      background: rgba(111, 92, 158, 0.1);
    `};

  ${(props) =>
    (props.type === "green" || props.type === "check") &&
    css`
      background: rgba(113, 224, 131, 0.1);
    `};

  ${(props) =>
    (props.type === "exit" || props.type === "red") &&
    css`
      background: rgba(212, 76, 0, 0.1);
    `};

  ${(props) =>
    props.type === "yellow" &&
    css`
      background: rgba(224, 226, 90, 0.1);
    `};

  ${(props) =>
    props.type === "blue" &&
    css`
      background: rgba(44, 129, 208, 0.1);
    `};
`;
