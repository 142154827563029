import Datas from "constants/Datas";

export const SIMPLE_FILTERS = [
  {
    value: "ID",
    key: "_id",
    label: "Buscar por ID",
  },
  {
    value: "Nome",
    key: "name",
    label: "Nome",
  },
  {
    value: "Email",
    key: "email",
    label: "Buscar por Email",
  },
  {
    value: "Telefone",
    key: "phone",
    label: "Buscar por Telefone",
  },
  {
    value: "CPF/CNPJ",
    key: "document",
    label: "CPF/CNPJ",
  },
  {
    value: "Filtros avançados",
    key: "",
    label: "Filtros avançados",
  },
];

export const ADVANCED_FILTERS = [
  {
    label: "ID",
    type: "input",
    name: "_id",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o ID",
  },
  {
    label: "Cadastrado entre",
    type: "date",
  },
  {
    label: "Tipo documento",
    name: "type_document",
    type: "select",
    options: Datas.TypeDocument,
    placeholder: "Escolha o tipo",
  },
  {
    label: "Estado",
    type: "state",
  },
  {
    label: "Cidade",
    type: "city",
  },
  {
    label: "Nome",
    type: "input",
    name: "name",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o nome",
  },
  {
    label: "CPF/ CNPJ",
    type: "input-document",
    name: "document",
    className: "normal",
    type_input: "text",
    placeholder: "Digite o cpf/cnpj",
  },
];
