export const Colors = {
  background: "#ffffff",
  primary100: "#D40050",
  primary90: "#D81A62",
  primary80: "#DD3373",
  primary70: "#E14D85",
  primary60: "#E56696",
  primary50: "#E980A7",
  primary40: "#EE99B9",
  primary30: "#F2B3CB",
  primary20: "#F6CCDC",
  primary10: "#FBE5ED",
  primary5: "#FDE8EC",

  secondary100: "#241058",
  secondary90: "#3A2869",
  secondary80: "#504079",
  secondary70: "#66588A",
  secondary60: "#7C709B",
  secondary50: "#9187AB",
  secondary40: "#A79FBC",
  secondary30: "#BDB7CD",
  secondary20: "#D3CFDE",
  secondary10: "#E9E7EE",
  secondary5: "#7d709e",
  secondary3: "#B3ABC9",
  secondary2: "#5D4E84",
  secondary1: "#D6D0E3",

  tertiary100: "#A2004C",
  tertiary60: "#C76694",
  tertiary40: "#DA99B7",
  tertiary20: "#ECCCDB",

  black100: "#11142D",
  black90: "#292C42",
  black80: "#414357",
  black70: "#585B6C",
  black60: "#707281",
  black50: "#888996",
  black40: "#A0A1AB",
  black30: "#B8B9C0",
  black20: "#CFD0D5",
  black10: "#E7E7EA",
  black5: "#f8f8f8",
  black2: "#f8f8fa",
  black3: "#b5b5b5",
  black4: "#c9c9c9",
  black6: "#5f5f5f",
  black7: "#c4c4c4",
  black8: "#F0EFF3",
  black9: "#11142d",

  white: "#ffffff",
  white10: " #f9f9f9",
  white20: "#f4f3f4",
  white30: "#f6f6f6",

  error: "#E05E5E",
  error10: "#FF4D4F",
  error20: "#e14848",

  red5: "#de665e",
  red10: "#DE665E",

  success: "#93C77B",
  success100: "#71e083",
  success90: "#58c86a",
  green10: "#8BD17F",

  purple10: "#ad9bdb",
  purple5: "#8d85a3",
  purple15: "#e2ddf8",
  purple25: "#CEC8F4",
  purple20: "#c2b9da",
  purple30: "#b3abc9",
  purple35: "#a79fbc",
  purple40: "#66588a",
  purple50: "#bdb6d1",
  purple60: "#311B6B",

  pink30: "#e91a44",
  red20: "#b30000",
  red30: "#d40000",
  red50: "#E24949",
  yellow10: '#FDFCEE',
  yellow50: "#E2C049",
  yellow100: '#E7E04D',
  red100: "#E25656",

  backgroundGreen: '#f5faf2',

  orange100: "#F6B148",

  black: "#000000",

  border: "#e1e1e1",
  border5: "#f2f0fc",
  border6: "#a39db1",
  border10: "#f4f3f7",
  border20: "#f0f0f0",
  border30: "#f0eeff",
  border40: "#e9e7ee",
  border45: "#F2F0FC",
  border50: "#e7e7ea",
  border60: "#f1f1f1",
  border70: "#e6e2f8",
  border80: "#f5f1ff",
  border90: "#fdfdfd",
  border100: "#cbcbcb",

  background5: "#F8F7F9",
  background6: "#d9d9d9",
  background7: "#F0F0F0",

  gray: "#aaaaaa",
  gray10: "#636173",
  gray20: "#adadad",
  gray30: "#C4C4C4",
  gray40: "#888996",
  gray45: "#585b6c",
  gray50: "#D0D0D0",
  gray60: "#e7e7e7",
  gray70: "#f2f2f2",
  gray80: "#f8f7fd",
  gray90: "#8A8A8A",
  gray100: "#ACACAC",
};
