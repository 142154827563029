import Skeleton from "react-loading-skeleton";
import { Container } from "./styles";

export function EmptyDetail() {
  return (
    <Container>
      <Skeleton
        style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
        height="60px"
        width="100%"
      />

      <Skeleton style={{ marginTop: "10px" }} height="420px" width="100%" />
    </Container>
  );
}
