import { Modal } from "components/Modal";

import {
  ButtonAdd,
  ButtonCancel,
  ContainerModalDelete,
  ContainerNormalButtons,
} from "./styles";

import { LoadingOutlined } from "@ant-design/icons";
import { Colors } from "constants/colors";
import { Spin } from "antd";

interface ModalProps {
  isModalVisible: boolean;
  handleCancel(): void;
  handleOk(): void;
  text?: string;
  loading?: boolean;
}

export function ModalDelete({
  isModalVisible,
  handleCancel,
  handleOk,
  text,
  loading,
}: ModalProps) {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <Modal
      isModalVisible={isModalVisible}
      handleCancel={handleCancel}
      handleOk={handleOk}
    >
      <ContainerModalDelete>
        <div className="content">
          <h2>Deseja excluir?</h2>

          <p>{text ?? ""}</p>
        </div>

        <ContainerNormalButtons>
          <ButtonCancel onClick={handleCancel}>
            <span>Cancelar</span>
          </ButtonCancel>
          <ButtonAdd onClick={handleOk}>
            {!loading ? (
              <span>Sim, desejo</span>
            ) : (
              <Spin spinning={loading} indicator={antIcon} />
            )}
          </ButtonAdd>
        </ContainerNormalButtons>
      </ContainerModalDelete>
    </Modal>
  );
}
