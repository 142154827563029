import { Container, ContainerItemsTable } from "./styles";
import { Sidebar } from "components/Sidebar";

import { Table } from "./components/Table";

import { Wrapper } from "./styles";
import { Header } from "components/Header";

import { OrganizerType } from "types";
import { useAuthOrganizers } from "services/hooks/organizer";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import { paramsToString } from "utils/searchOptions";
import { PaginationFilterComponent } from "components/PaginationFilter";

interface OrganizersInterface {
  results: OrganizerType[];
  count: number;
}

export default function OrganizersPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const debouncedSearch = useDebounce(paramsString, 1000);

  const { data } = useAuthOrganizers<OrganizersInterface>({
    page: pageCurrent ?? 1,
    filters: debouncedSearch,
  });

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });
    },

    [searchParams, setSearchParams]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, searchParams, setSearchParams]);

  const idFilter = searchParams.get("_id") ?? "";
  const nameOrganizer = searchParams.get("name") ?? "";
  const addressState = searchParams.get("address_state") ?? "";
  const addressCity = searchParams.get("address_city") ?? "";
  const typeDocument = searchParams.get("type_document") ?? "";
  const dataLte = searchParams.get("data_lte") ?? "";
  const dataGte = searchParams.get("data_gte") ?? "";
  const documentOrganizer = searchParams.get("document") ?? "";

  useEffect(() => {
    const paramsString = new URLSearchParams(
      paramsToString({
        _id: idFilter,
        name: nameOrganizer,
        address_state: addressState,
        address_city: addressCity,
        data_lte: dataLte,
        data_gte: dataGte,
        type_document: typeDocument,
        document: documentOrganizer,
      })
    );

    setParamsString(paramsString);
  }, [
    addressCity,
    addressState,
    dataGte,
    dataLte,
    documentOrganizer,
    idFilter,
    nameOrganizer,
    typeDocument,
  ]);

  const filterId = searchParams.get("filter_id") ?? "";
  const filterText = searchParams.get("filter_text") ?? "";

  useEffect(() => {
    const paramsString = new URLSearchParams(
      paramsToString({
        simple_filter_id: filterId,
        simple_filter_text: filterText,
      })
    );

    setParamsString(paramsString);
  }, [filterId, filterText]);

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />

        <ContainerItemsTable>
          <h1>Organizador</h1>

          <h3 className="title">Organizadores</h3>
          <Table data={data?.results} total={data?.count ?? 0} />

          <div className="pagination">
            <PaginationFilterComponent
              current={page}
              onChange={onChange}
              total={data?.count ?? 0}
            />
          </div>
        </ContainerItemsTable>
      </Container>
    </Wrapper>
  );
}
