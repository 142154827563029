import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-left: 8px;

  strong {
    color: ${Colors.white};
    font-weight: 500;
    font-size: 14px;
  }

  div.range-pick {
    border: 0;
    background: ${Colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 206px;
    height: 38px;
    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;
    padding-left: 8px;
    padding-right: 2px;

    @media (max-width: 503px) {
      width: 140px;
      padding-left: 8px;

      input {
        font-size: 10px;
      }
    }

    input {
      max-width: 78px;
      height: 100%;
      border: 0;
      background: transparent;
      outline: 0;
      font-size: 12px;
      color: ${Colors.secondary3};

      @media (max-width: 503px) {
        width: 98%;
      }
    }
  }
`;
