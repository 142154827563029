import useSWR from "swr";

import axios from "axios";

export function useCitiesByUf<Data = any, Error = any>(state: string) {
  const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${
    state ?? "SP"
  }/municipios`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await axios.get(url);

    return response.data;
  });

  return { data, error, mutate };
}

interface GetAddressByZipCodeProps {
  zipCode: string;
}

export async function GetAddressByZipCode({
  zipCode,
}: GetAddressByZipCodeProps) {
  const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);

  return response;
}
