import useSWR from "swr";

import { authClient } from "services/authClient";

import moment from "moment";

import { EventType } from "types";

export function useAuthEvent<Data = any, Error = any>(id: string) {
  const url = `/backoffice/event/${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const event = response.data;

    const date = moment(event.begin_date);
    const dateComponent = date.utc().format("DD/MM/YYYY");

    const endDate = moment(event.end_date);
    const dateEndComponent = endDate.utc().format("DD/MM/YYYY");

    const newResponse = {
      ...event,
      formattedBeginDate: dateComponent,
      formattedEndDate: dateEndComponent,
    };

    return newResponse;
  });

  return { data, error, mutate };
}

export async function UploadFileEvent(formData: FormData, id: string) {
  const url = `/backoffice/event/upload/${id}`;

  await authClient.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

interface EditEventProps {
  id: string;
  credentials: any;
}

export async function EditEvent({ id, credentials }: EditEventProps) {
  const url = `/backoffice/event/${id}`;

  await authClient.patch(url, {
    ...credentials,
  });
}

export async function EditStatusEvent({ id, credentials }: EditEventProps) {
  const url = `/backoffice/event/${id}`;

  await authClient.patch(url, credentials);
}

interface useAuthEventsProps {
  page?: string | number;
  filters?: any;
}

export function useAuthEvents<Data = any, Error = any>({
  page,
  filters,
}: useAuthEventsProps) {
  const url = `/backoffice/event/filter?page=${page ?? 1}&limit=20${
    filters ? `&${filters}` : null
  }`;

  // const url = `/backoffice/event/filter?page=${page ?? 1}&limit=20`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((event: EventType) => {
      const date = moment(event.begin_date);
      const dateComponent = date.utc().format("DD/MM/YYYY");

      const endDate = moment(event.end_date);
      const dateEndComponent = endDate.utc().format("DD/MM/YYYY");

      const dateCreatedAt = moment(event.created_at);
      const dateComponentCreatedAt = dateCreatedAt
        .utc()
        .format("DD/MM/YYYY HH:mm");

      return {
        ...event,
        formattedBeginDate: dateComponent,
        formattedEndDate: dateEndComponent,
        formattedCreatedAt: dateComponentCreatedAt,
        isOpen: false,
      };
    });

    const newResponse = { ...response.data, results: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}
