import { Select } from "antd";
import styled from "styled-components";
import { Colors } from "../../constants/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 60px 60px 0px 60px;

  @media (max-width: 1240px) {
    padding: 104px 60px 0px 60px;
  }

  @media (max-width: 768px) {
    padding: 104px 16px 0px 70px;
  }

  @media (max-width: 621px) {
    padding: 104px 16px 0px 30px;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;

    color: ${Colors.secondary90};
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;

    color: ${Colors.secondary80};
    margin-top: 32px;
  }
`;

export const ContainerItemsTable = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  padding: 0px 30px 0px 60px;
  margin-top: 54px;
  margin-bottom: 100px;

  @media (max-width: 740px) {
    margin-top: 32px;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;

    color: ${Colors.secondary90};

    @media (max-width: 840px) {
      padding-left: 30px;
      margin-bottom: 24px;
    }
  }

  h3.title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;

    color: ${Colors.secondary80};
    margin-bottom: 0px;
    margin-top: 48px;

    @media (max-width: 840px) {
      padding-left: 30px;
      margin-bottom: 24px;
    }
  }

  div.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 768px) {
      padding-right: 20px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 1240px) {
    padding: 24px 16px 0px 28px;
  }

  @media (max-width: 768px) {
    padding: 108px 0px 0px 0px;
    margin-bottom: 40px;
  }

  @media (max-width: 621px) {
    padding: 108px 0px 0px 0px;
  }
`;

export const StyledSelect = styled(Select)``;
