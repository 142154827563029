import { Select } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerModalAddDocument = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 0px 109px 24px 109px;

  @media (max-width: 700px) {
    padding: 0px 20px 24px 0px;
  }

  h2.title {
    font-weight: bold;
    font-size: 32px;
    /* line-height: 160%; */
    text-align: center;
    color: ${Colors.secondary100};

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    text-align: center;

    color: #585b6c;
  }

  h3.account {
    font-weight: 600;
    font-size: 14px;

    color: ${Colors.secondary70};
    margin-top: 44px;

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  div.content {
    width: 100%;
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div.image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 180px;
      height: 220px;
      margin-top: 20px;
    }

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 85px;

      text-align: center;

      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  div.all-input {
    width: 94%;
  }

  @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  }
`;

export const FormFullFirst = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background: ${Colors.white10}; */
  margin-top: 10px;

  div.all-input {
    width: 94%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      /* identical to box height, or 32px */

      /* Secundaria/secundaria 70 */

      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  }
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
}

export const ContainerInput = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  height: 60px;

  @media (max-width: 800px) {
    min-height: 40px;
  }

  input {
    width: 96%;
    outline: 0;
    border: 0;
    /* background: ${Colors.white10}; */
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    /* line-height: 20%; */
    padding: 0px 0px 4px 3px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  div.radio {
   padding-left: 4px;
   margin-top: 5px;

  .ant-radio-checked .ant-radio-inner{
    border-color: ${Colors.primary100} !important ;
  }

  .ant-radio-checked .ant-radio-inner:after{
    background-color: ${Colors.primary100};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${Colors.primary100} ;
  }

  .ant-radio-checked .ant-radio-inner:focus{
    background: ${Colors.primary100};
  }
 }


  div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      color: ${Colors.secondary70};

      div.row {
        width: 100%;
        display: flex;
        flex-direction: row;

        h5 {
          color: ${Colors.primary100};
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          margin-left: 10px;
        }
      }
    }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    /* margin-bottom: 0px;
      margin-top: 23px; */
  }
`;

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    /* line-height: 160%; */
    padding: 0px 0px 4px 4px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    /* margin-bottom: 0px;
      margin-top: 23px; */
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    margin-left: 4px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 32px;
  margin-top: 52px;
  margin-bottom: 52px;
`;

export const ButtonRemove = styled.button`
  cursor: pointer;
  margin-right: 10px;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${Colors.white};
  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  color: ${Colors.primary100};
`;

export const ButttonEdit = styled.button`
  cursor: pointer;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${Colors.primary100};
  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;

  color: ${Colors.white};
`;

export const StyledSelect = styled(Select)``;

export const ContainerNormalButtons = styled.div`
  width: 100%;
  margin-top: 83px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonCancel = styled.button`
  width: 132px;
  height: 50px;
  border: 0;
  cursor: pointer;

  border: 1px solid ${Colors.primary100};
  background: ${Colors.white};
  box-sizing: border-box;
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */

    text-align: center;

    color: ${Colors.primary100};
  }
`;

interface ButtonCancelPurchaseProps {
  isLoading?: boolean;
  active?: boolean;
}

export const ButtonCancelPurchase = styled.button<ButtonCancelPurchaseProps>`
  cursor: pointer;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${(props) => (props.active ? Colors.primary100 : Colors.black10)};

  border-radius: 14px;

  font-weight: 700;
  font-size: 18px;
  line-height: 34px;

  color: ${Colors.white};

  span {
    margin-left: ${(props) => (props.isLoading ? "5px" : 0)};
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  h2.title {
    font-weight: bold;
    font-size: 32px;
    /* line-height: 160%; */
    text-align: center;
    color: ${Colors.secondary100};
    margin-top: 20.5px;

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    text-align: center;

    color: #585b6c;
  }

  button.ok {
    cursor: pointer;
    border: 0;
    width: 80px;
    height: 50px;
    margin-top: 40px;

    background: ${Colors.primary100};
    box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    border-radius: 14px;

    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: ${Colors.white};
    }
  }
`;

export const ContainerInformations = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div.inside {
    width: 100%;
    height: 166px;
    margin-top: 36px;
    background: ${Colors.white};
    border: 1px solid ${Colors.border70};
    border-radius: 5px;

    padding: 12px 12px 0px 12px;

    header {
      width: 100%;
      padding-bottom: 12px;

      border-bottom: 1px solid ${Colors.border70};

      h3 {
        font-weight: 600;
        font-size: 14px;
        color: ${Colors.secondary80};
        margin-bottom: 0px;
      }
    }

    main {
      div.row {
        margin-top: 12px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 800px) {
          flex-direction: column;
        }
      }

      div.column {
        width: 50%;

        @media (max-width: 800px) {
          width: 100%;
        }

        h2 {
          font-weight: 700;
          font-size: 10px;
          color: ${Colors.purple50};
          margin-bottom: 0px;
        }

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          color: ${Colors.secondary80};
          margin-bottom: 0px;
        }
      }
    }
  }
`;
