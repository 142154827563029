import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
  isFull?: boolean;
}

export const Container = styled.div<ContainerInputProps>`
  width: ${(props) => (props.isFull ? "100%" : "49%")};
  display: flex;
  flex-direction: column;

  height: 128px;

  @media (max-width: 800px) {
    min-height: 40px;
    width: 100%;
    margin-top: 10px;
  }

  background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};

  ${(props) =>
    props.isEditing &&
    css`
        border-bottom: 2px solid ${Colors.secondary20}};
      `};

  ${(props) =>
    !props.isEditing &&
    props?.error &&
    css`
        border-bottom: 2px solid ${Colors.error20}};
      `};

  textarea {
    height: 100%;
    width: 100%;
    outline: 0;
    border: 0;
    background: ${(props) => (props.isEditing ? Colors.white : Colors.white10)};

    color: ${Colors.secondary70};

    font-size: 12px;
    padding: 0px 0px 8px 8px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }

    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 8px;
      height: 2px;

      margin-top: -10px;
    }

    Track ::-webkit-scrollbar-track {
      background: ${Colors.black10};
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${Colors.primary100};
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${Colors.red20};
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    margin-bottom: 0px;
  }
`;

export const Label = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    margin-left: 8px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;
