import { useEffect, useState } from "react";

import { useSWRConfig } from "swr";
import { useParams } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Modal } from "components/Modal";

import { LoadingOutlined } from "@ant-design/icons";

import { Spin } from "antd";

import { Input } from "components/FormDetails/Input";

import { TicketPurchase, UserTicket } from "types";
import { Colors } from "constants/colors";

import {
  ButtonCancel,
  ButtonCancelPurchase,
  Center,
  ContainerButtons,
  ContainerForm,
  ContainerModal,
  ContainerNormalButtons,
  ContainerNormalModal,
  FormFull,
} from "./styles";

import checkCancelPurchaseIcon from "assets/checkCancelPurchaseIcon.svg";
import bird from "assets/bird-icon.svg";
import { EditUserTicket } from "services/hooks/user";
import { ResendTicket } from "services/hooks/ticket";

type UserFormData = {
  name: string;
  email: string;
  document: string;
};

const userFormSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  email: yup.string().required("Email obrigatório"),
  document: yup.string().required("Documento obrigatório"),
});

interface ModalEditPurchaseUserProps {
  isModalVisible: boolean;
  handleCancel(): void;
  handleOk(): void;
  ticket: UserTicket;
  setActiveTicket(value: UserTicket | null): void;
}

export function ModalEditPurchaseUser({
  isModalVisible,
  handleCancel,
  handleOk,
  ticket,
  setActiveTicket,
}: ModalEditPurchaseUserProps) {
  const [step, setStep] = useState(0);

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UserFormData>({
    resolver: yupResolver(userFormSchema),
  });

  const { mutate } = useSWRConfig();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const handlePutUser: SubmitHandler<UserFormData> = async ({
    ...dataUser
  }) => {
    const { name, email, document } = dataUser;

    try {
      const credentials: any = {
        holder_name: name,
        holder_email: email,
        holder_cpf: document,
      };

      await EditUserTicket({ ticketId: ticket?._id, credentials });

      setStep(1);

      mutate(`/backoffice/purchases?id=${id}`);

      reset();
    } catch (err) {
      alert("Erro ao editar este ingresso");
    }
  };

  const [loadingResendTicket, setLoadingResendTicket] = useState(false);

  const handleResendTicket = async () => {
    try {
      setLoadingResendTicket(true);

      await ResendTicket(ticket._id);

      setLoadingResendTicket(false);

      setStep(2);
    } catch (err) {
      setLoadingResendTicket(false);
      alert("Erro ao reenviar o ingresso");
    }
  };

  useEffect(() => {
    reset({
      name: ticket.holder_name,
      email: ticket.holder_email,
      document: ticket.holder_cpf ?? "",
    });
  }, [reset, ticket]);

  return (
    <Modal
      isModalVisible={isModalVisible}
      handleCancel={() => {
        handleCancel();
        setActiveTicket(null);
      }}
      handleOk={() => {
        handleOk();
        setActiveTicket(null);
      }}
    >
      {step === 0 ? (
        <ContainerNormalModal onSubmit={handleSubmit(handlePutUser)}>
          <h2 className="title">Editar dados do comprador</h2>

          <p className="description">
            Selecione abaixo qual dos dados do comprador deste ingresso você
            quer editar:
          </p>

          <ContainerForm>
            <FormFull>
              <Input
                isEditing={true}
                label="Nome completo"
                isFull
                error={errors.name}
                {...register("name")}
              />
            </FormFull>

            <FormFull>
              <Input
                isEditing={true}
                label="E-mail"
                error={errors.email}
                {...register("email")}
              />

              <Input
                isEditing={true}
                label="CPF"
                mask={"cpf"}
                error={errors.document}
                {...register("document")}
              />
            </FormFull>
          </ContainerForm>

          <ContainerNormalButtons>
            <ButtonCancel type="button" onClick={handleCancel}>
              <span>Cancelar</span>
            </ButtonCancel>

            <ButtonCancelPurchase type="submit" isLoading={isSubmitting} active>
              <Spin spinning={isSubmitting} indicator={antIcon} />

              <span>Salvar</span>
            </ButtonCancelPurchase>
          </ContainerNormalButtons>
        </ContainerNormalModal>
      ) : null}

      {step === 1 ? (
        <ContainerModal>
          <Center>
            <img src={checkCancelPurchaseIcon} alt="cancel" />

            <h2 className="title">Dados alterados!</h2>

            <p className="description">
              Os dados do comprador deste ingresso foram alterados com sucesso.
            </p>
          </Center>

          <div className="container-border">
            <div className="border">
              <h3>O que fazer agora?</h3>
            </div>
          </div>

          <ContainerButtons>
            <button
              className="close"
              onClick={() => {
                handleCancel();
                setActiveTicket(null);
              }}
            >
              <span>Voltar para os detalhes da venda</span>
            </button>

            <button type="button" className="send" onClick={handleResendTicket}>
              {loadingResendTicket ? (
                <Spin spinning={loadingResendTicket} indicator={antIcon} />
              ) : (
                <img src={bird} alt="bird" width={16} height={16} />
              )}

              <span>Reenviar o ingresso do comprador</span>
            </button>
          </ContainerButtons>
        </ContainerModal>
      ) : null}

      {step === 2 ? (
        <ContainerModal>
          <Center>
            <img src={checkCancelPurchaseIcon} alt="cancel" />

            <h2 className="title">Ingresso reenviado!</h2>

            <p className="description">
              O ingresso foi reenviado para o comprador com sucesso.
            </p>

            <button
              className="ok"
              type="button"
              onClick={() => {
                handleOk();
                setActiveTicket(null);
              }}
            >
              <span>Ok</span>
            </button>
          </Center>
        </ContainerModal>
      ) : null}
    </Modal>
  );
}
