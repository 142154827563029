import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";

import { useNavigate } from "react-router-dom";

import { authClient } from "services/authClient";

import { GetUserById, Login } from "services/hooks/user";

export type User = {
  id: number;
  email: string;
  name: string;
  last_name: string;
  avatar: string;
  cell_phone: string;
  birthdate: string;
  document: string;
  gender: string;
  type_document: string;
  type_user: number;
};

type Error = {
  response: {
    status: number;
    data: {
      code: string;
    };
  };
};

type SignInCredentials = {
  email: string;
  password: string;
};

type AuthContextData = {
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  hydratationUser(): void;
  user: User | undefined;
  isAuthenticated: boolean;
  hasOrganizer: boolean;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  let navigate = useNavigate();

  const [user, setUser] = useState<User | undefined>();

  const signOut = useCallback(() => {
    localStorage.removeItem("Backoffice.token");
    localStorage.removeItem("userId");

    setUser(undefined);
    navigate("/signin");
  }, [navigate]);

  useEffect(() => {
    async function getUser() {
      const token = localStorage.getItem("Backoffice.token");
      const userId = localStorage.getItem("userId");

      if (token) {
        try {
          const { data } = await GetUserById({ id: userId });

          if (data) {
            setUser({
              id: data._id,
              name: data.name,
              last_name: data.last_name,
              avatar: data.avatar,
              cell_phone: data.cell_phone,
              email: data.email,
              birthdate: data.birthdate,
              document: data.document,
              gender: data.gender,
              type_document: data.type_document,
              type_user: data.type_user,
            });
          }
        } catch (err) {
          const response = err as Error;

          const { status } = response.response;

          if (status === 401) {
            signOut();
          }
        }
      }
    }

    getUser();
  }, [signOut]);

  async function hydratationUser() {
    const token = localStorage.getItem("Backoffice.token");
    const userId = localStorage.getItem("userId");

    if (token) {
      try {
        const { data } = await GetUserById({ id: userId });

        if (data) {
          setUser({
            id: data._id,
            name: data.name,
            last_name: data.last_name,
            avatar: data.avatar,
            cell_phone: data.cell_phone,
            email: data.email,
            birthdate: data.birthdate,
            document: data.document,
            gender: data.gender,
            type_document: data.type_document,
            type_user: data.type_user,
          });
        }
      } catch (err) {
        signOut();
      }
    }
  }

  async function signIn({ email, password }: SignInCredentials) {
    const credentials = {
      email,
      password,
    };

    const response = await Login({ credentials });

    const { token, user } = response.data;

    localStorage.setItem("userId", user._id);
    localStorage.setItem("Backoffice.token", token);

    authClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    const { data } = await GetUserById({ id: user._id });

    if (data) {
      setUser({
        id: data._id,
        name: data.name,
        last_name: data.last_name,
        avatar: data.avatar,
        cell_phone: data.cell_phone,
        email: data.email,
        birthdate: data.birthdate,
        document: data.document,
        gender: data.gender,
        type_document: data.type_document,
        type_user: data.type_user,
      });
    }

    navigate("/");
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user,
        isAuthenticated: !!user,
        hydratationUser,
        hasOrganizer: user?.type_user === 1,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}
