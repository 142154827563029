import { Colors } from "constants/colors";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinkHasHover = styled(Link)`
  text-decoration: underline;
  text-decoration-color: ${Colors.secondary80};

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${Colors.secondary80};
  }
`;
