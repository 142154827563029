import RoutesPage from "./routes";
import "./App.css";
import GlobalStyle from "./styles/GlobalStyle";
import { BrowserRouter } from "react-router-dom";

import { AppProvider } from "contexts";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <AppProvider>
        <RoutesPage />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
