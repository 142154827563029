import { ContainerInput, StyledSelect } from "./styles";

import { Select } from "antd";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import Datas from "constants/Datas";

const { Option } = Select;

type OptionType = {
  label: string;
  value: string | number;
};

interface SelectComponentProps {
  name?: string;
  label?: string;
  placeholder?: string;
  options?: OptionType[];
  handleSelectState?(value: string): void;
}

export function SelectUf({
  name = "address_state",
  label,
  placeholder,
  options,
  handleSelectState,
}: SelectComponentProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const address_state = searchParams.get("address_state") ?? "";

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      if (handleSelectState) {
        handleSelectState(value?.value);
      }

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        [name]: value.value,
      });
    },
    [handleSelectState, name, searchParams, setSearchParams]
  );

  const clearId = useCallback(() => {
    searchParams.delete(name);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [name, searchParams, setSearchParams]);

  return (
    <ContainerInput>
      <strong>{label}</strong>

      <div className="select">
        <StyledSelect
          bordered={false}
          labelInValue
          showSearch
          optionFilterProp="children"
          placeholder="Escolha o estado"
          onChange={handleSelectedIdFilter}
          allowClear
          value={address_state || undefined}
          onClear={clearId}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          style={{
            width: "100%",
            height: "38px",
            border: "none",
            outline: 0,
          }}
        >
          {Datas.States.map((uf) => {
            return (
              <Option value={uf.value} key={uf.label}>
                {uf.value}
              </Option>
            );
          })}
        </StyledSelect>
      </div>
    </ContainerInput>
  );
}
