import { Colors } from "constants/colors";
import styled from "styled-components";

export const AllEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -16px;
  border-left: 1px solid ${Colors.border100};
  border-bottom: 1px solid ${Colors.border100};
  border-right: 1px solid ${Colors.border100};
`;

export const ContainerEvents = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 39px 65px 28px 56px;

  @media (max-width: 768px) {
    padding: 0px 28px 40px 28px;
    margin-top: 0px;
  }

  div.button-adress {
    width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: flex-end;
    margin-top: 0px;
  }
`;

export const ListEvents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 200px;
  padding-bottom: 40px;

  @media (max-width: 823px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  div.empty {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${Colors.secondary80};
      font-weight: 500;
      font-size: 15px;
    }
  }
`;

export const Address = styled.div`
  width: 48%;
  height: 166px;
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  padding: 12px;

  background: ${Colors.white};
  border: 1px solid ${Colors.border100};
  box-sizing: border-box;
  border-radius: 5px;

  @media (max-width: 900px) {
    min-width: 100%;
    width: 100%;
  }

  div.first {
    img {
      width: 41px;
      height: 37px;
    }
  }

  div.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-direction: flex-start;
    margin-left: 12px;
    margin-top: 0px;

    head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;

      h4 {
        font-weight: 600;
        font-size: 14px;
        margin-top: 0px;
        margin-bottom: 0px;

        color: ${Colors.secondary80};
      }

      div.options {
        display: flex;
        flex-direction: row;

        div.area {
          cursor: pointer;
          width: 26px;
          height: 26px;
          margin-left: 12px;

          display: flex;
          align-items: center;
          justify-content: center;

          background: ${Colors.white};
          border: 1px solid ${Colors.border5};
          border-radius: 6px;

          img {
            width: 10px;
            height: 14px;
          }
        }
      }
    }

    div.second {
      display: flex;
      flex-direction: row;
      margin-top: 0px;

      @media (max-width: 516px) {
        flex-direction: column;
      }

      div.local {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
        }
      }

      div.type {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};

        @media (max-width: 516px) {
          margin-left: 0px;
          padding-left: 0px;
          border-left: none;
        }
      }
    }

    div.third {
      display: flex;
      flex-direction: row;

      div.day {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }
      }

      div.time {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};
      }
    }
  }

  .icon {
    margin-left: 30px;
  }
`;

export const ButtonAddNew = styled.div`
  cursor: pointer;
  border: 0;
  width: 180px;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 219px;
  height: 44px;

  background: ${Colors.primary100};
  border-radius: 6px;

  span {
    font-size: 16px;
    line-height: 100%;

    color: ${Colors.white};
  }
`;
