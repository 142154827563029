import useSWR from "swr";

import moment from "moment";

import { authClient } from "services/authClient";
import { TicketType } from "types";

export async function ResendTicket(ticketId: string) {
  const url = `/backoffice/resend-ticket/${ticketId}`;

  await authClient.patch(url);
}

interface EditTicketProps {
  ticketId: string | undefined;
  credentials: any;
}

export async function EditTicket({ ticketId, credentials }: EditTicketProps) {
  const URL = `/backoffice/event-ticket/${ticketId}`;

  await authClient.patch(URL, credentials);
}

interface UseAuthTicketsProps {
  page?: string | number;
  filters?: string;
}

export function useAuthTickets<Data = any, Error = any>({
  page,
  filters,
}: UseAuthTicketsProps) {
  const url = `/backoffice/event-ticket/filter?page=${page ?? 1}&limit=20${
    filters && `&${filters}`
  }`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((ticket: TicketType) => {
      const date = moment(ticket.sale_begin_date);
      const dateComponent = date.utc().format("DD/MM/YYYY");

      const endDate = moment(ticket.sale_end_date);
      const dateEndComponent = endDate.utc().format("DD/MM/YYYY");

      let timeStartComp = "00:00";
      let timeEndComp = "00:00";

      if (ticket && ticket.sale_begin_hour) {
        const split = ticket.sale_begin_hour.split(":");

        timeStartComp = `${split[0]}:${split[1]}`;
      }

      if (ticket && ticket.sale_end_hour) {
        const split = ticket.sale_end_hour.split(":");

        timeEndComp = `${split[0]}:${split[1]}`;
      }

      return {
        ...ticket,
        formattedPrice: ticket.price
          ? ticket.price.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          : "",
        formattedSaleBeginDate: dateComponent,
        formattedSaleEndDate: dateEndComponent,
        formattedSaleBeginHour: timeStartComp,
        formattedSaleEndHour: timeEndComp,
        isOpen: false,
      };
    });

    const newResponse = { ...response.data, results: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

export function useAuthTicket<Data = any, Error = any>(id: string) {
  const url = `/backoffice/event-ticket/${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const ticket = response.data;

    // let dateComponent = "";

    // if (organizer.agent_birthdate) {
    //   const date = moment(organizer.agent_birthdate);
    //   dateComponent = date.utc().format("DD/MM/YYYY");
    // }

    const newResponse = {
      ...ticket,
      // formattedAgentBirthdate: dateComponent,
    };

    return newResponse;
  });

  return { data, error, mutate };
}
