import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Select } from "antd";

import { IoCloseCircle } from "react-icons/io5";
import { BiSearch } from "react-icons/bi";

import { Colors } from "constants/colors";

import { cnpj, cpf, phone, phoneToString } from "utils/masks";

import { Input } from "components/Filters/Input";
import { SelectEvent } from "components/Filters/SelectEvent";
import { SelectTicket } from "components/Filters/SelectTicket";
import { SelectComponent } from "components/Filters/Select";

import {
  AdvancedFilters,
  FirstRow,
  HeaderFilter,
  HeaderFiltersMobile,
} from "./styles";

import { InputDateFilter } from "components/Filters/InputDateFilter";
import { InputRange } from "components/Filters/InputRange";
import { SelectCity } from "components/Filters/SelectCity";
import { SelectUf } from "components/Filters/SelectUf";
import { SelectCategory } from "components/Filters/SelectCategory";
import { SelectOrganizer } from "components/Filters/SelectOrganizer";
import { InputDocument } from "components/Filters/InputDocument";

const { Option } = Select;

interface SimpleFilter {
  value: string | number;
  key?: string;
  label: string;
}

interface AdvancedFilter {
  label?: string;
  type?:
    | "input"
    | "date"
    | "price_range"
    | "event"
    | "ticket"
    | "select"
    | "state"
    | "city"
    | "category"
    | string;
  name?: string;
  className?: string;
  type_input?: string;
  placeholder?: string;
  options?: SimpleFilter[];
  gte?: string;
  lte?: string;
}

interface HeaderFilterComponentProps {
  total: number;
  label?: string;
  SIMPLE_FILTERS: SimpleFilter[];
  ADVANCED_FILTERS: AdvancedFilter[];
}

export function HeaderFilterComponent({
  total,
  ADVANCED_FILTERS,
  SIMPLE_FILTERS,
  label,
}: HeaderFilterComponentProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [text, setText] = useState("");

  const [selectState, setSelectState] = useState("");

  const filterAdvancedStatus = searchParams.get("filter_advanced_status");
  const filterId = searchParams.get("filter_id") ?? "";
  const filterValue = searchParams.get("filter_value") ?? "ID";
  const filterText = searchParams.get("filter_text") ?? "";

  useEffect(() => {
    if (filterAdvancedStatus === null) {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        filter_advanced_status: "0",
        page: "1",
      });
    }
  }, [filterAdvancedStatus, searchParams, setSearchParams]);

  useEffect(() => {
    if (!filterId) {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        filter_id: "_id",
      });
    }

    setText(filterText ?? "");

    if (filterAdvancedStatus === "1") {
      setText("");

      searchParams.delete("filter_id");
      searchParams.delete("filter_text");

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        filter_advanced_status: "1",
      });
    }
  }, [
    filterAdvancedStatus,
    filterId,
    filterText,
    searchParams,
    setSearchParams,
  ]);

  const handleKeyUpCpf = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cpf(e);
  }, []);

  const handleKeyUpCnpj = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      cnpj(e);
    },
    []
  );

  const handleKeyUpPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      phone(e);
    },
    []
  );

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      if (value.value === "Filtros avançados") {
        setText("");

        searchParams.delete("filter_id");
        searchParams.delete("filter_value");
        searchParams.delete("filter_text");

        setSearchParams({
          ...Object.fromEntries([...searchParams]),
          filter_value: value.value,
          filter_advanced_status: "1",
        });

        return;
      }

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        filter_advanced_status: "0",
        filter_id: value.key,
        filter_value: value.value,
      });
    },
    [searchParams, setSearchParams]
  );

  const handleChangeTextFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (filterId === "cell_phone") {
        setText(phoneToString(e.target.value));

        setSearchParams({
          ...Object.fromEntries([...searchParams]),
          filter_text: phoneToString(e.target.value),
        });

        return;
      }

      setText(e.target.value);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        filter_text: e.target.value,
      });
    },
    [filterId, searchParams, setSearchParams]
  );

  const handleClearText = useCallback(() => {
    setText("");

    searchParams.delete("filter_text");

    setSearchParams({ ...Object.fromEntries([...searchParams]) });
  }, [searchParams, setSearchParams]);

  const handleSelectState = useCallback((value: string) => {
    setSelectState(value);
  }, []);

  return (
    <>
      <HeaderFilter filterAdvancedIsActive={filterAdvancedStatus === "1"}>
        <div className="first">
          <nav>
            <div className="dropdown" onClick={() => {}}>
              <Select
                labelInValue
                defaultValue="ID"
                defaultActiveFirstOption
                onChange={handleSelectedIdFilter}
                style={{ width: "100%" }}
                value={filterValue}
              >
                {SIMPLE_FILTERS.map((option) => (
                  <Option value={option.value} key={option.key}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="searchInput">
              <input
                value={text}
                placeholder={`${filterValue} ${label ?? ""}`}
                onChange={handleChangeTextFilter}
                onKeyUp={
                  filterId === "type_document" ||
                  filterId === "organizer_document"
                    ? text.length <= 14
                      ? handleKeyUpCpf
                      : handleKeyUpCnpj
                    : filterId === "cell_phone"
                    ? handleKeyUpPhone
                    : () => {}
                }
              />
            </div>

            <div className="clear">
              {filterText ? (
                <IoCloseCircle
                  size={12}
                  color={Colors.secondary80}
                  style={{ cursor: "pointer" }}
                  onClick={handleClearText}
                />
              ) : null}
            </div>
          </nav>

          <div className="search">
            <BiSearch size={18} color={Colors.white} />
          </div>
        </div>

        <h6>
          <strong>{total ?? 0}</strong> resultados encontrados
        </h6>
      </HeaderFilter>

      <HeaderFiltersMobile
        filterAdvancedIsActive={filterAdvancedStatus === "1"}
      >
        <div className="select">
          <Select
            labelInValue
            defaultValue="ID"
            defaultActiveFirstOption
            onChange={handleSelectedIdFilter}
            style={{ width: "100%" }}
            value={filterValue}
          >
            {SIMPLE_FILTERS.map((option) => (
              <Option value={option.value} key={option.key}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>

        {filterAdvancedStatus === "0" ? (
          <div>
            <nav>
              <div className="searchInput">
                <input
                  value={text}
                  placeholder={`${filterValue} da venda`}
                  onChange={handleChangeTextFilter}
                  onKeyUp={
                    filterId === "type_document"
                      ? text.length <= 14
                        ? handleKeyUpCpf
                        : handleKeyUpCnpj
                      : filterId === "cell_phone"
                      ? handleKeyUpPhone
                      : () => {}
                  }
                />
              </div>

              <div style={{ width: 15, marginTop: 20 }}>
                {filterText ? (
                  <IoCloseCircle
                    size={12}
                    color={Colors.secondary80}
                    style={{ cursor: "pointer" }}
                    onClick={handleClearText}
                  />
                ) : null}
              </div>
            </nav>
            <div className="search" onClick={() => {}}>
              <BiSearch size={14} color={Colors.white} />
            </div>
          </div>
        ) : null}

        <div className="footer-informations">
          <p className="quantity">{total ?? 0} resultados encontrados</p>
        </div>
      </HeaderFiltersMobile>

      {filterAdvancedStatus === "1" ? (
        <AdvancedFilters>
          <FirstRow>
            {ADVANCED_FILTERS.map((filter) => {
              if (filter.type === "input") {
                return (
                  <Input
                    name={filter.name ?? ""}
                    label={filter.label ?? ""}
                    className={filter.className ?? ""}
                    type={filter.type_input ?? ""}
                    placeholder={filter.placeholder ?? ""}
                  />
                );
              }

              if (filter.type === "input-document") {
                return (
                  <InputDocument
                    name={filter.name ?? ""}
                    label={filter.label ?? ""}
                    className={filter.className ?? ""}
                    type={filter.type_input ?? ""}
                    placeholder={filter.placeholder ?? ""}
                  />
                );
              }

              if (filter.type === "date") {
                return (
                  <InputDateFilter
                    label={filter.label ?? ""}
                    gteNameFilter={filter.gte}
                    lteNameFilter={filter.lte}
                  />
                );
              }

              if (filter.type === "price_range") {
                return <InputRange />;
              }

              if (filter.type === "event") {
                return <SelectEvent name={filter.name ?? "event"} />;
              }

              if (filter.type === "ticket") {
                return <SelectTicket />;
              }

              if (filter.type === "select") {
                return (
                  <SelectComponent
                    options={filter.options ?? []}
                    name={filter.name ?? ""}
                    label={filter.label ?? ""}
                    placeholder={filter.placeholder ?? ""}
                  />
                );
              }

              if (filter.type === "state") {
                return (
                  <SelectUf
                    label="Estado"
                    name={filter.name}
                    handleSelectState={handleSelectState}
                  />
                );
              }

              if (filter.type === "city") {
                return (
                  <SelectCity label="Cidade" stateSelected={selectState} />
                );
              }

              if (filter.type === "category") {
                return <SelectCategory label="Categoria" />;
              }

              if (filter.type === "organizer") {
                return <SelectOrganizer label="Organizador" />;
              }

              return null;
            })}
          </FirstRow>
        </AdvancedFilters>
      ) : null}
    </>
  );
}
