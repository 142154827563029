import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 48%;
  height: 164px;
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  padding: 12px;

  background: ${Colors.white};
  border: 1px solid ${Colors.border100};
  box-sizing: border-box;
  border-radius: 5px;

  @media (max-width: 900px) {
    min-width: 100%;
    width: 100%;
    /* height: 92px; */
  }

  @media (max-width: 516px) {
    /* height: 122px; */
  }

  div.first {
    img {
      width: 41px;
      height: 37px;
    }
  }

  div.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-direction: flex-start;
    margin-left: 12px;
    margin-top: 0px;

    header {
      display: flex;
      flex-direction: row;
      /* align-items: flex-start; */
      justify-content: space-between;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;

      h3 {
        font-weight: 600;
        font-size: 14px;

        margin-top: 0px;
        margin-bottom: 0px;

        color: ${Colors.secondary80};
      }

      h3.last {
        margin-bottom: 8px;
      }

      h4 {
        font-weight: 400;
        font-size: 12px;

        margin-top: 0px;
        margin-bottom: 6px;
        line-height: 18px;

        color: ${Colors.secondary80};

        strong {
          font-weight: bold;
        }
      }

      div.options {
        display: flex;
        flex-direction: row;

        div.area {
          cursor: pointer;
          width: 26px;
          height: 26px;
          margin-left: 12px;

          display: flex;
          align-items: center;
          justify-content: center;

          background: ${Colors.white};
          border: 1px solid ${Colors.border5};
          border-radius: 6px;

          img {
            width: 10px;
            height: 14px;
          }
        }
      }
    }

    div.second {
      display: flex;
      flex-direction: row;
      margin-top: 0px;

      @media (max-width: 516px) {
        flex-direction: column;
      }

      div.local {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 160%;

          color: ${Colors.secondary40};
        }
      }

      div.type {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};

        @media (max-width: 516px) {
          margin-left: 0px;
          padding-left: 0px;
          border-left: none;
        }
      }
    }

    div.third {
      display: flex;
      flex-direction: row;

      div.day {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }
      }

      div.time {
        span {
          font-weight: 500;
          font-size: 11px;
          line-height: 160%;

          color: ${Colors.secondary80};
          margin-left: 5px;
        }

        margin-left: 10px;
        padding-left: 11px;
        border-left: 1px solid ${Colors.border70};
      }
    }
  }

  .icon {
    margin-left: 30px;
  }
`;
