import {
  ButtonRemove,
  ButttonEdit,
  ContainerButtons,
  ContainerDescription,
  ContainerForm,
  ContainerInput,
  ContainerInputFull,
  ContainerInputFullPrefix,
  FormFull,
  FormFullNormal,
  FormLabel,
  StyledSelect,
} from "./styles";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useSWRConfig } from "swr";
import { City, OrganizerType } from "types";
import { useCallback, useEffect, useState } from "react";

import { cep, cnpj, cpf, currency, date, phone } from "utils/masks";

import { Select, Spin } from "antd";
import { ufs } from "constants/ufs";

import { LoadingOutlined } from "@ant-design/icons";
import { Colors } from "constants/colors";
import { TextArea } from "components/FormDetails/TextArea";
import { EditOrganizer } from "services/hooks/organizer";
import { GetAddressByZipCode, useCitiesByUf } from "services/hooks/city";
import { OrganizerFormData } from "./types";

const { Option } = Select;

const organizerFormSchema = yup.object().shape({
  name: yup.string().notRequired(),
  nickname: yup.string().notRequired(),
  agent_name: yup.string().notRequired(),
  agent_document: yup.string().notRequired(),
  agent_birthdate: yup.string().notRequired(),
  agent_email: yup
    .string()
    .required("E-mail obrigatório")
    .email("E-mail inválido"),
  agent_phone: yup.string().notRequired(),
  type_document: yup.number().notRequired(),
  address_zipcode: yup.string().notRequired(),
  address_country: yup.string().notRequired(),
  address_district: yup.string().notRequired(),
  address_state: yup.string().notRequired(),
  address_city: yup.string().notRequired(),
  address_street: yup.string().notRequired(),
  address_complement: yup.string().notRequired(),
  address_number: yup.string().notRequired(),
  business_name: yup.string().notRequired(),
  business_phone: yup.string().notRequired(),
  business_email: yup.string().notRequired(),
  fee: yup.string().notRequired(),
  formattedLinkNick: yup.string().notRequired(),
});

type ErrorPutOrganizer = {
  response: {
    data: {
      error: string;
    };
  };
};

interface DetailOrganizerProps {
  id: string;
  data: OrganizerType;
}

export function DetailOrganizer({ id, data }: DetailOrganizerProps) {
  const [isEditing, setIsEditing] = useState(false);

  const { mutate } = useSWRConfig();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<OrganizerFormData>({
    resolver: yupResolver(organizerFormSchema),

  });

  const [stateByOrganizerSelected, setStateByOrganizerSelected] = useState("");

  const { data: dataCities } = useCitiesByUf<City[]>(
    stateByOrganizerSelected ? stateByOrganizerSelected : "SP"
  );

  useEffect(() => {
    reset({
      name: data?.name,
      nickname: data?.nickname ?? "",
      agent_name: data?.agent_name,
      agent_document: data?.agent_document,
      agent_birthdate: data?.formattedAgentBirthdate,
      agent_email: data?.agent_email,
      agent_phone: data?.agent_phone,
      description: data?.description,
      business_name: data?.business_name ?? "",
      business_email: data?.business_email ?? "",
      business_phone: data?.business_phone ?? "",
      type_document: data?.type_document,
      address_zipcode: data?.address_zipcode,
      address_country: data?.address_country,
      address_district: data?.address_district,
      address_state: data?.address_state,
      address_city: data?.address_city,
      address_street: data?.address_street,
      address_number: data?.address_number,
      address_complement: data?.address_complement ?? "",
      fee: data?.fee ? String(data?.fee) : null,
      formattedLinkNick:
        process.env.REACT_APP_AMBIENT === "DEV"
          ? `https://eventx---web-develop-ywzg5.ondigitalocean.app/${
              data?.nickname ?? ""
            }`
          : `https://www.eventx.com.br/${data.nickname ?? ""}`,
    });
  }, [data, reset]);

  const addressZipCode = watch("address_zipcode");

  const handleSearchAddress = useCallback(async () => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        try {
          const response = await GetAddressByZipCode({
            zipCode: addressZipCode,
          });

          const { logradouro, bairro, localidade, uf } = response.data;

          if (bairro === undefined) {
            setError("address_zipcode", {
              type: "manual",
              message: "Não foi possivel encontrar o Cep",
            });
            return;
          }
          setValue("address_street", logradouro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_district", bairro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_city", localidade, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_state", uf, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } catch (err) {
          setError("address_zipcode", {
            type: "required",
            message: "Não foi possivel encontrar o Cep",
          });
        }
      }
    }
  }, [addressZipCode, setError, setValue]);

  useEffect(() => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        handleSearchAddress();
      }
    }
  }, [addressZipCode, handleSearchAddress]);

  const handlePutOrganizer: SubmitHandler<OrganizerFormData> = async ({
    ...dataOrgananizer
  }) => {
    const {
      name,
      // nickname,
      agent_name,
      agent_document,
      agent_birthdate,
      agent_email,
      agent_phone,
      // type_document,
      address_zipcode,
      address_complement,
      address_country,
      address_district,
      address_state,
      address_city,
      address_street,
      address_number,
      business_name,
      description,
      business_email,
      business_phone,
      fee,
    } = dataOrgananizer;

    let newFee = null;

    if (fee) {
      let regex = /[.,\s]/g;

      let result = fee.replace(regex, "");

      const a = parseInt(result);

      newFee = a / 100;
    }

    try {
      let birthdateData = "";

      if (agent_birthdate) {
        const split = agent_birthdate.split("/");

        birthdateData = `${split[2]}-${split[1]}-${split[0]}`;
      }

      let organizer_document = null;

      if (data?.organizer_document !== null) {
        organizer_document = data?.organizer_document?.map((document) => ({
          document_url: document.document_url,
          document_type: document?.document_type,
          organizer_id: document?.organizer_id,
        }));
      }

      const credentials = {
        name,
        agent_name,
        agent_document,
        agent_birthdate: birthdateData,
        agent_email,
        agent_phone,
        address_zipcode,
        address_country,
        address_district,
        address_state,
        address_complement: address_complement ?? null,
        address_city,
        address_street,
        address_number,
        business_name,
        description,
        business_email,
        business_phone,
        fee: newFee,
        organizer_document: organizer_document ?? [],
      };

      await EditOrganizer({ idOrganizer: id, credentials });

      setIsEditing(false);

      mutate("/organizers");

      alert(`Organizador atualizado com sucesso`);
    } catch (err) {
      const response = err as ErrorPutOrganizer;

      const { error } = response.response.data;

      alert(error);
    }
  };

  const handleKeyUpCep = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cep(e);
  }, []);

  const handleKeyUpPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      phone(e);
    },
    []
  );

  const handleKeyUpCpf = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cpf(e);
  }, []);

  const handleKeyUpCnpj = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      cnpj(e);
    },
    []
  );

  const handleKeyUpDate = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      date(e);
    },
    []
  );

  const handleKeyPrice = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    currency(e);
  }, []);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const handleKeyUpDocument = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (Number(data?.type_document) === 2) {
        cnpj(e);
        return;
      }

      cpf(e);
    },
    [data?.type_document]
  );

  return (
    <ContainerForm onSubmit={handleSubmit(handlePutOrganizer)}>
      <ContainerDescription>
        <h4>Dados da empresa</h4>
      </ContainerDescription>

      <FormFull>
        <ContainerInputFull isEditing={isEditing}>
          <FormLabel>
            {data?.type_document === 1 ? <h2>CPF</h2> : <h2>CNPJ</h2>}
          </FormLabel>

          <input
            defaultValue={data?.document}
            {...register("document")}
            onKeyUp={handleKeyUpDocument}
            disabled={!isEditing}
          />

          {errors.agent_name ? (
            <span className="error">Insira um documento válido</span>
          ) : null}
        </ContainerInputFull>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Nome da empresa</h2>
          </FormLabel>

          <input
            defaultValue={data?.name}
            {...register("name")}
            disabled={!isEditing}
          />

          {errors.name ? (
            <span className="error">Insira um nome válido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Descrição de empresa</h2>
          </FormLabel>

          <input
            defaultValue={data?.description}
            {...register("description")}
            // onKeyUp={handleKeyUpDate}
            disabled={!isEditing}
          />

          {errors.description ? (
            <span className="error">Insira uma descrição válida</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Email da empresa</h2>
          </FormLabel>

          <input
            defaultValue={data?.business_email ?? ""}
            {...register("business_email")}
            disabled={!isEditing}
          />

          {errors.business_email ? (
            <span className="error">Insira um email válido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Telefone de empresa</h2>
          </FormLabel>

          <input
            defaultValue={data?.formattedAgentBirthdate}
            {...register("business_phone")}
            onKeyUp={handleKeyUpPhone}
            disabled={!isEditing}
          />

          {errors.business_phone ? (
            <span className="error">Insira uma telefone válida</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <ContainerDescription>
        <h4>Taxas</h4>
      </ContainerDescription>

      <FormFull>
        <ContainerInput isEditing={false}>
          <FormLabel>
            <h2>Taxa do Organizador</h2>
          </FormLabel>

          <input
            defaultValue={data?.fee}
            {...register("fee")}
            onKeyUp={handleKeyPrice}
            disabled={true}
          />

          {/* {errors.fee ? (
            <span className="error">Insira um valor valido</span>
          ) : null} */}
        </ContainerInput>
      </FormFull>

      <ContainerDescription>
        <h4>Endereço</h4>
      </ContainerDescription>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Cep</h2>
          </FormLabel>

          <input
            {...register("address_zipcode")}
            defaultValue={data?.address_zipcode}
            disabled={!isEditing}
            onKeyUp={handleKeyUpCep}
          />

          {errors.address_zipcode ? (
            <span className="error">Insira um cep valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Endereço</h2>
          </FormLabel>

          <input
            defaultValue={data?.address_street}
            {...register("address_street")}
            disabled={!isEditing}
          />

          {errors.address_street ? (
            <span className="error">Insira um endereço valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInputFull isEditing={isEditing}>
          <FormLabel>
            <h2>Bairro</h2>
          </FormLabel>

          <input
            defaultValue={data?.address_district}
            {...register("address_district")}
            disabled={!isEditing}
          />

          {errors.address_district ? (
            <span className="error">Insira um Bairro valido</span>
          ) : null}
        </ContainerInputFull>
      </FormFull>

      <FormFull>
        <ContainerInputFull isEditing={isEditing}>
          <FormLabel>
            <h2>Complemento</h2>
          </FormLabel>

          <input
            defaultValue={data?.address_complement}
            {...register("address_complement")}
            disabled={!isEditing}
          />
        </ContainerInputFull>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Numero</h2>
          </FormLabel>

          <input
            {...register("address_number")}
            defaultValue={data?.address_number}
            disabled={!isEditing}
          />

          {errors.address_number ? (
            <span className="error">Insira um numero valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Estado</h2>
          </FormLabel>

          <div className="select">
            <Controller
              control={control}
              name="address_state"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <StyledSelect
                  bordered={false}
                  labelInValue
                  placeholder="Escolha o estado"
                  value={value}
                  disabled={!isEditing}
                  onChange={(e: any) => {
                    onChange(e.value);
                    setStateByOrganizerSelected(e.value);
                  }}
                  style={{
                    width: "100%",
                    minHeight: "100%",
                    outline: 0,
                    border: `2px solid ${Colors.secondary20}`,
                    borderRadius: "8px",
                    marginTop: "2px",
                  }}
                >
                  {ufs.map((uf) => {
                    return (
                      <Option value={uf.sigla} key={uf.id}>
                        {uf.sigla}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            />
          </div>
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Cidade</h2>
          </FormLabel>

          <div className="select">
            <Controller
              control={control}
              name="address_city"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <StyledSelect
                  bordered={false}
                  labelInValue
                  placeholder="Escolha a cidade"
                  value={value}
                  disabled={!isEditing}
                  onChange={(e: any) => {
                    onChange(e.value);
                  }}
                  style={{
                    width: "100%",
                    minHeight: "100%",
                    outline: 0,
                    border: `2px solid ${Colors.secondary20}`,
                    borderRadius: "8px",
                    marginTop: "2px",
                  }}
                >
                  {dataCities?.map((city) => {
                    return (
                      <Option value={city.nome} key={city.nome}>
                        {city.nome}
                      </Option>
                    );
                  })}
                </StyledSelect>
              )}
            />
          </div>
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>País</h2>
          </FormLabel>

          <input
            defaultValue={data?.address_country}
            {...register("address_country")}
            // onKeyUp={handleKeyUpPhone}
            disabled={!isEditing}
          />

          {errors.address_country ? (
            <span className="error">Insira um país válido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <ContainerDescription>
        <h4>Dados pessoais</h4>
      </ContainerDescription>

      <FormFull>
        <ContainerInputFull isEditing={isEditing}>
          <FormLabel>
            <h2>Nome completo</h2>
          </FormLabel>

          <input
            defaultValue={data?.name}
            {...register("name")}
            disabled={!isEditing}
          />

          {errors.agent_name ? (
            <span className="error">Insira um nome válido</span>
          ) : null}
        </ContainerInputFull>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            {data?.type_document === 1 ? <h2>CPF</h2> : null}

            {data?.type_document === 2 ? <h2>CNPJ</h2> : null}
          </FormLabel>

          {data?.type_document === 1 ? (
            <input
              defaultValue={data?.agent_document}
              {...register("agent_document")}
              onKeyUp={handleKeyUpCpf}
              disabled={!isEditing}
            />
          ) : null}

          {data?.type_document === 2 ? (
            <input
              defaultValue={data?.agent_document}
              {...register("agent_document")}
              onKeyUp={handleKeyUpCnpj}
              disabled={!isEditing}
            />
          ) : null}

          {data?.type_document === null ? (
            <input
              defaultValue={data?.document}
              {...register("agent_document")}
              onKeyUp={handleKeyUpCpf}
              disabled={!isEditing}
            />
          ) : null}

          {errors.agent_document ? (
            <span className="error">Insira um Documento valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Data de nascimento</h2>
          </FormLabel>

          <input
            defaultValue={data?.formattedAgentBirthdate}
            {...register("agent_birthdate")}
            onKeyUp={handleKeyUpDate}
            disabled={!isEditing}
          />

          {errors.agent_birthdate ? (
            <span className="error">Insira uma data válida</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Email</h2>
          </FormLabel>

          <input
            {...register("agent_email")}
            defaultValue={data?.agent_email}
            disabled={!isEditing}
          />

          {errors.agent_email ? (
            <span className="error">Insira um email válido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Telefone</h2>
          </FormLabel>

          <input
            defaultValue={data?.agent_phone}
            {...register("agent_phone")}
            onKeyUp={handleKeyUpPhone}
            disabled={!isEditing}
          />

          {errors.agent_phone ? (
            <span className="error">Insira um Telefone válido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <ContainerDescription>
        <h4>Sobre o organizador</h4>
      </ContainerDescription>

      <FormFull>
        <ContainerInputFull isEditing={isEditing}>
          <TextArea
            label="Descrição"
            defaultValue={data?.description}
            {...register("description")}
            isEditing={isEditing}
            disabled={!isEditing}
            isFull
          />

          {errors.description ? (
            <span className="error">Insira uma descrição válida</span>
          ) : null}
        </ContainerInputFull>
      </FormFull>

      <FormFullNormal>
        <ContainerInputFullPrefix hasPrefix={isEditing} isEditing={isEditing}>
          <FormLabel>
            <h2>Link da página do organizador</h2>
          </FormLabel>

          {isEditing ? (
            <div className="hasPrefix">
              <h3 className="prefix">
                {process.env.REACT_APP_AMBIENT === "DEV"
                  ? `https://eventx---web-develop-ywzg5.ondigitalocean.app/`
                  : `https://www.eventx.com.br/`}
              </h3>

              <input {...register("nickname")} disabled />
            </div>
          ) : null}

          {!isEditing ? (
            <input {...register("formattedLinkNick")} disabled={!isEditing} />
          ) : null}

          {errors.name ? (
            <span className="error">Insira um Nome válido</span>
          ) : null}
        </ContainerInputFullPrefix>
      </FormFullNormal>

      <ContainerButtons>
        {isEditing ? (
          <ButtonRemove onClick={() => setIsEditing(false)}>
            Cancelar
          </ButtonRemove>
        ) : null}

        {isEditing ? (
          <ButttonEdit type="submit" isLoading={isSubmitting}>
            <Spin spinning={isSubmitting} indicator={antIcon} />

            <span>Salvar</span>
          </ButttonEdit>
        ) : null}

        {!isEditing ? (
          <ButttonEdit type="button" onClick={() => setIsEditing(true)}>
            Editar
          </ButttonEdit>
        ) : null}
      </ContainerButtons>
    </ContainerForm>
  );
}
