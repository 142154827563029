import { useState } from "react";

import { Link } from "react-router-dom";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { PurchaseType } from "types";
import Skeleton from "react-loading-skeleton";

import {
  returnStatusColorPurchase,
  returnStatusPurchase,
  returnTypePaymentPurchase,
} from "utils/purchase";

import { ADVANCED_FILTERS, SIMPLE_FILTERS } from "constants/filters/sales";
import { Colors } from "constants/colors";

import {
  Container,
  Card,
  Tr,
  ContainerItemsMobile,
  CardMobile,
  List,
  Item,
  TrExtra,
  TrBody,
  LinkStyled,
  LinkStyledMobile,
} from "./styles";

import { StatusComponent } from "components/Status";
import { HeaderFilterComponent } from "components/HeaderFilter";
import { SkeletonRowTable } from "components/SkeletonRowTable";

interface TableProps {
  data?: PurchaseType[];
  total: number | null;
}

export function Table({ data, total }: TableProps) {
  const [activePurchase, setActivePurchase] = useState<PurchaseType | null>(
    null
  );

  function handleSelectedUser(purchase: PurchaseType, active: boolean) {
    if (active) {
      setActivePurchase(purchase);

      return;
    }

    setActivePurchase(null);
  }

  return (
    <Container>
      <Card>
        <HeaderFilterComponent
          ADVANCED_FILTERS={ADVANCED_FILTERS}
          SIMPLE_FILTERS={SIMPLE_FILTERS}
          total={total ?? 0}
          label="da venda"
        />

        <section>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th className="responsive2">VENDEDOR</th>
                <th>COMPRADOR</th>
                <th>TIPO PAGAMENTO</th>
                <th>MÉTODO PAGAMENTO</th>
                <th>DT.VENDA</th>
                <th>VALOR</th>

                <th className="responsive">STATUS</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {!data ? (
                <SkeletonRowTable
                  height_size={30}
                  width_size={60}
                  row_numbers_size={10}
                  column_numbers_size={9}
                />
              ) : null}

              {data?.map((purchase, i) => (
                <>
                  <Tr key={i} active={purchase?._id === activePurchase?._id}>
                    <td>
                      <div className="all">
                        <Link to={`/sales/${purchase._id}`}>
                          <strong>{purchase._id}</strong>
                        </Link>
                      </div>
                    </td>

                    <td className="responsive2">
                      <div className="all">
                        <Link
                          to={
                            purchase?.event && purchase?.event.organizer
                              ? `/organizers/${purchase?.event.organizer.id}`
                              : "/sales"
                          }
                        >
                          <strong>
                            {purchase?.event &&
                            purchase?.event.organizer !== null
                              ? purchase?.event.organizer.name
                              : "Não informado"}
                          </strong>
                        </Link>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <Link
                          to={
                            purchase?.user && purchase?.user.id
                              ? `/users/${purchase?.user?.id}`
                              : "/sales"
                          }
                        >
                          <strong>
                            {purchase?.user
                              ? purchase?.user?.name
                              : "Não informado"}
                          </strong>
                        </Link>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>Venda única</strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>
                          {purchase.type_payment !== null
                            ? returnTypePaymentPurchase(purchase.type_payment)
                            : "Não informado"}
                        </strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>{purchase.formattedCreatedAt}</strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>{purchase.formattedTotal}</strong>
                      </div>
                    </td>

                    <td className="responsive">
                      <div className="all">
                        <StatusComponent
                          content={returnStatusPurchase(
                            purchase?.payment_status ?? ""
                          )}
                          color={returnStatusColorPurchase(
                            purchase?.payment_status ?? ""
                          )}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="column all">
                        {activePurchase?._id === purchase._id ? (
                          <FiChevronUp
                            onClick={() => handleSelectedUser(purchase, false)}
                            style={{ cursor: "pointer" }}
                            color={Colors.secondary80}
                            size={18}
                          />
                        ) : (
                          <FiChevronDown
                            onClick={() => handleSelectedUser(purchase, true)}
                            style={{ cursor: "pointer" }}
                            color={Colors.secondary80}
                            size={18}
                          />
                        )}
                      </div>
                    </td>
                  </Tr>

                  {activePurchase?._id === purchase._id ? (
                    <TrExtra>
                      <th>DADOS DA VENDA</th>
                      <th></th>
                      <th>DADOS DO COMPRADOR</th>
                      <th>ORDERBUMP</th>
                      <th>TAXA EVENTX</th>
                      <th>VALOR LÍQUIDO</th>
                      <th>VALOR JUROS</th>
                      <th> </th>
                      <th> </th>
                    </TrExtra>
                  ) : null}

                  {activePurchase?._id === purchase._id ? (
                    <TrBody key={i} active>
                      <td>
                        <div className="informations">
                          {/* <Link
                            to={
                              purchase?.user && purchase?.user.id
                                ? `/users/${purchase?.user?.id}`
                                : "/sales"
                            }
                          > */}
                          <strong>
                            {purchase?.event?.name
                              ? purchase?.event?.name.length > 11
                                ? `${purchase?.event?.name.substring(0, 11)}...`
                                : purchase?.event?.name
                              : "Não informado"}
                          </strong>
                          {/* </Link> */}
                          <strong className="last">
                            {purchase?.tickets
                              ? purchase?.tickets[0].ticket?.name
                              : ""}
                          </strong>
                        </div>
                      </td>
                      <td></td>

                      <td>
                        <div className="informations">
                          <Link
                            to={
                              purchase?.user && purchase?.user.id
                                ? `/users/${purchase?.user?.id}`
                                : "/sales"
                            }
                          >
                            <strong>
                              {purchase?.user?.email
                                ? purchase.user.email
                                : "Não informado"}
                            </strong>
                          </Link>
                          <strong className="last">
                            {purchase?.user?.cell_phone}
                          </strong>
                        </div>
                      </td>
                      <td>
                        <div className="all">
                          <Link
                            to={
                              purchase.event && purchase?.event.name !== null
                                ? `/events/${purchase?.event?.id}`
                                : "/sales"
                            }
                          >
                            <strong>
                              {purchase.event && purchase?.event.name !== null
                                ? purchase?.event?.name.length > 11
                                  ? `${purchase?.event?.name.substring(
                                      0,
                                      11
                                    )}...`
                                  : purchase?.event?.name
                                : "Não informado"}
                            </strong>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <strong>{purchase.formattedTotalFee}</strong>
                      </td>
                      <td>
                        <strong>{purchase.formattedTotalWet}</strong>
                      </td>

                      <td>
                        <strong>{purchase.formattedTotalFee}</strong>
                      </td>

                      <td></td>

                      <td>
                        <div className="column">
                          <LinkStyled to={`/sales/${purchase._id}`}>
                            <span>Detalhes</span>
                          </LinkStyled>
                        </div>
                      </td>
                    </TrBody>
                  ) : null}
                </>
              ))}
            </tbody>
          </table>
        </section>

        {data && data.length === 0 ? (
          <div className="container-empty">
            <h3>Nenhuma Venda Encontrada</h3>
          </div>
        ) : null}
      </Card>

      <ContainerItemsMobile>
        <CardMobile>
          <HeaderFilterComponent
            ADVANCED_FILTERS={ADVANCED_FILTERS}
            SIMPLE_FILTERS={SIMPLE_FILTERS}
            total={total ?? 0}
            label="da venda"
          />

          <List>
            {!data
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                  <Item>
                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>
                  </Item>
                ))
              : null}

            {data && data.length === 0 ? (
              <div className="container-empty">
                <h3>Nenhuma venda Encontrada</h3>
              </div>
            ) : null}

            {data?.map((purchase) => (
              <Item>
                <Link to={`/sales/${purchase._id}`}>
                  <small>ID: {purchase._id}</small>
                </Link>

                <h5>ORGANIZADOR</h5>

                <Link
                  to={
                    purchase?.event && purchase?.event.organizer
                      ? `/organizers/${purchase?.event.organizer.id}`
                      : "/sales"
                  }
                >
                  <h2>{purchase?.event?.organizer?.name ?? "Não informado"}</h2>
                </Link>

                <h5>COMPRADOR</h5>

                <Link
                  to={
                    purchase?.user && purchase?.user.id
                      ? `/users/${purchase?.user?.id}`
                      : "/sales"
                  }
                >
                  <h2>{purchase?.user?.name ?? "Não informado"}</h2>
                </Link>

                <h5>ORDERPUMP</h5>

                <Link
                  to={
                    purchase.event && purchase?.event.name !== null
                      ? `/events/${purchase?.event?.id}`
                      : "/sales"
                  }
                >
                  <h2>
                    {purchase.event && purchase?.event.name !== null
                      ? purchase?.event.name
                      : "Não informado"}
                  </h2>
                </Link>

                <h5>DT.VENDA</h5>
                <h2>{purchase?.formattedCreatedAt}</h2>

                <h5>TOTAL</h5>
                <h2>{purchase?.formattedTotal}</h2>

                <h5>TAXAS EVENTX</h5>
                <h2>{purchase?.formattedTotalFee}</h2>

                <div className="chevron-mobile">
                  <StatusComponent
                    content={returnStatusPurchase(purchase?.payment_status ?? "")}
                    color={returnStatusColorPurchase(purchase?.payment_status ?? "")}
                  />

                  {activePurchase?._id === purchase._id ? (
                    <FiChevronUp
                      onClick={() => handleSelectedUser(purchase, false)}
                      style={{ cursor: "pointer" }}
                      color={Colors.secondary80}
                      size={22}
                    />
                  ) : (
                    <FiChevronDown
                      onClick={() => handleSelectedUser(purchase, true)}
                      style={{ cursor: "pointer" }}
                      color={Colors.secondary80}
                      size={22}
                    />
                  )}
                </div>

                {activePurchase?._id === purchase._id ? (
                  <div className="footer-details">
                    <LinkStyledMobile to={`/sales/${purchase._id}`}>
                      <span>Detalhes</span>
                    </LinkStyledMobile>
                  </div>
                ) : null}
              </Item>
            ))}
          </List>
        </CardMobile>
      </ContainerItemsMobile>
    </Container>
  );
}
