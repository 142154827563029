import { ChangeEvent, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ContainerInput } from "./styles";

interface InputProps {
  name: string;
  label: string;
  className: string;
  type: string;
  placeholder: string;
}

export function Input({
  name,
  label,
  className,
  type,
  placeholder,
}: InputProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [text, setText] = useState("");

  // const filterAdvancedStatus = searchParams.get("filter_advanced_status");

  const handleChangeTextFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        [name]: e.target.value,
      });
    },
    [name, searchParams, setSearchParams]
  );

  return (
    <ContainerInput>
      <strong>{label}</strong>

      <input
        className={className}
        type={type}
        value={text}
        placeholder={placeholder}
        onChange={handleChangeTextFilter}
      />
    </ContainerInput>
  );
}
