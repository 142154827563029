import { useCallback, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useSWRConfig } from "swr";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Select, Spin, Tabs } from "antd";
import { GoChevronRight } from "react-icons/go";
import { LoadingOutlined } from "@ant-design/icons";

import {
  Container,
  ContainerItems,
  ListButtons,
  ContainerItemsTable,
  ButtonBorder,
  ContainerTabs,
  ContainerForm,
  FormFull,
  ContainerInput,
  FormLabel,
  ContainerButtons,
  ButtonRemove,
  ButttonEdit,
  AllEvents,
  ContainerEvents,
  ListEvents,
  StyledSelect,
} from "./styles";

import { Wrapper } from "./styles";

import { useAuthUser } from "services/hooks/user";

import { OrganizerType, UserType } from "types";
import { Colors } from "constants/colors";

import { AddAddress } from "./components/AddAddress";
import { TicketsUser } from "./components/TicketsUser";
import { PurchasesUser } from "./components/PurchasesUser";
import { EditAddress } from "./components/EditAddress";
import { ListAddresses } from "./components/ListAddresses";

import { Header } from "components/Header";
import { EmptyDetail } from "components/EmptyDetail";

import { Input } from "components/FormDetails/Input";
import { Sidebar } from "components/Sidebar";

import Datas from "constants/Datas";
import { EditUser } from "services/hooks/user";
import OrganizerCard from "./components/OrganizerCard";
import { useAuthOrganizersById } from "services/hooks/organizer";

const { TabPane } = Tabs;

const { Option } = Select;

type UserFormData = {
  name: string;
  email: string;
  password: string;
  type_document: number;
  document: string;
  last_name: string;
  birthdate: string;
  cell_phone: string;
  gender: string;
  address_title: string;
  address_zipcode: string;
  address_country: string;
  address_district: string;
  address_state: string;
  address_city: string;
  address_street: string;
  address_number: string;
  address_complement: string;
};

const userFormSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  email: yup.string().notRequired(),
  password: yup.string().notRequired(),
  type_document: yup.number().notRequired(),
  document: yup.string().notRequired(),
  last_name: yup.string().notRequired(),
  birthdate: yup.string().notRequired(),
  cell_phone: yup.string().notRequired(),
  gender: yup.string().notRequired().nullable(true),
  address_title: yup.string().notRequired(),
  address_zipcode: yup.string().notRequired(),
  address_country: yup.string().notRequired(),
  address_district: yup.string().notRequired(),
  address_state: yup.string().notRequired(),
  address_city: yup.string().notRequired(),
  address_street: yup.string().notRequired(),
  address_number: yup.string().notRequired(),
  address_complement: yup.string().notRequired(),
});

type AddressFormData = {
  id: number;
  address_title: string;
  address_zipcode: string;
  address_country: string;
  address_district: string;
  address_state: string;
  address_city: string;
  address_street: string;
  address_number: string;
  address_complement: string;
};

export default function UserPage() {
  const [itsCreateAddress, setItsCreateAddress] = useState(false);
  const [itsEditAddress, setItsEditAddress] = useState(false);

  const [isEditingAddress, setIsEditingAddress] = useState(false);

  const [activeOrganizer, setActiveOrganizer] = useState<OrganizerType | null>(
    null
  );

  const [selectedAddress, setSelectedAddress] =
    useState<AddressFormData | null>(null);

  const [isEditing, setIsEditing] = useState(false);

  const [activeUser, setActiveUser] = useState<UserType | null>(null);

  const [page, setPage] = useState(1);

  const { mutate } = useSWRConfig();

  const navigate = useNavigate();

  const { id } = useParams();

  const { data } = useAuthUser<UserType>({ userId: id });
  const { data: organizers } = useAuthOrganizersById(id ?? "");

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<UserFormData>({
    resolver: yupResolver(userFormSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    reset({
      name: data?.name,
      email: data?.email,
      document: data?.document ?? "",
      birthdate: data?.formattedBirthdate,
      cell_phone: data?.cell_phone,
      gender: data?.gender,
    });
  }, [data, reset]);

  const resetForm = () => {
    reset({
      name: data?.name,
      email: data?.email,
      document: data?.document ?? "",
      birthdate: data?.formattedBirthdate,
      cell_phone: data?.cell_phone,
      gender: data?.gender,
    });
  };

  const handlePutUser: SubmitHandler<UserFormData> = async ({
    ...dataUser
  }) => {
    const { name, email, password, document, birthdate, cell_phone, gender } =
      dataUser;

    let birthdateData = "";

    if (birthdate) {
      const split = birthdate.split("/");

      birthdateData = `${split[2]}-${split[1]}-${split[0]}`;
    }

    try {
      const credentials = {
        name,
        email,
        password: password ?? null,
        document,
        birthdate: birthdateData,
        cell_phone,
        gender,
      };

      await EditUser({ userId: id, credentials });

      mutate("/users");

      alert("Usuario atualizado com sucesso");

      setIsEditing(false);
    } catch (err) {}
  };

  const handleCloseEditAddress = useCallback(() => {
    setItsEditAddress(false);
    setIsEditingAddress(false);
    setSelectedAddress(null);
  }, []);

  const handleSelectedOrganizer = useCallback(
    (organizer: OrganizerType) => {
      if (activeOrganizer?._id === organizer._id) {
        setActiveOrganizer(null);

        return;
      }

      setActiveOrganizer(organizer);
    },
    [activeOrganizer?._id]
  );

  const handleChangeItsEditAddress = useCallback((value: boolean) => {
    setItsEditAddress(value);
  }, []);

  const handleChangeSelectedAddress = useCallback(
    (address: AddressFormData) => {
      setSelectedAddress(address);
    },
    []
  );

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <div className="header">
            <h1>Usuários</h1>
            <GoChevronRight
              className="icon"
              size={28}
              color={Colors.secondary80}
            />

            <h1>Detalhes</h1>
          </div>
          <h2 className="box">Caixa de ferramentas</h2>

          <ListButtons>
            <div className="last">
              <ButtonBorder onClick={() => navigate(-1)}>
                <span>Voltar</span>
              </ButtonBorder>
            </div>
          </ListButtons>
        </ContainerItems>

        <ContainerItemsTable>
          <ContainerTabs>
            <h3 className="title">Usuários</h3>

            {!data ? <EmptyDetail /> : null}

            {data ? (
              <Tabs defaultActiveKey="1" type="card" size={"small"}>
                <TabPane tab="Detalhes" key="1">
                  <ContainerForm onSubmit={handleSubmit(handlePutUser)}>
                    <FormFull>
                      <Input
                        isEditing={isEditing}
                        label="Nome"
                        error={errors.name}
                        {...register("name")}
                      />

                      <Input
                        isEditing={isEditing}
                        label="Data de nascimento"
                        error={errors.birthdate}
                        {...register("birthdate")}
                        mask={"birthdate"}
                      />
                    </FormFull>

                    <FormFull>
                      <Input
                        isEditing={isEditing}
                        label="Telefone"
                        error={errors.cell_phone}
                        {...register("cell_phone")}
                        mask={"phone"}
                      />

                      <Input
                        isEditing={isEditing}
                        label={data?.type_document !== "2" ? "CPF" : "CNPJ"}
                        {...register("document")}
                        mask={data?.type_document !== "2" ? "cpf" : "cnpj"}
                      />
                    </FormFull>

                    <FormFull>
                      <ContainerInput isEditing={isEditing}>
                        <FormLabel>
                          <h2>Gênero</h2>
                        </FormLabel>

                        <div className="select">
                          <Controller
                            control={control}
                            name="gender"
                            render={({ field: { onChange, value } }) => (
                              <StyledSelect
                                bordered={false}
                                labelInValue
                                placeholder="Escolha o Gênero"
                                value={value}
                                disabled={!isEditing}
                                defaultValue={data?.gender}
                                onChange={(e: any) => {
                                  onChange(e.value);
                                }}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  border: "none",
                                  outline: 0,
                                }}
                              >
                                {Datas.Genders.map((gender) => (
                                  <Option
                                    value={gender.value}
                                    key={gender.value}
                                  >
                                    {gender.label}
                                  </Option>
                                ))}
                              </StyledSelect>
                            )}
                          />
                        </div>
                      </ContainerInput>
                    </FormFull>

                    <FormFull>
                      <Input
                        type="email"
                        isEditing={isEditing}
                        label="Email"
                        error={errors.email}
                        {...register("email")}
                      />
                    </FormFull>

                    <ContainerButtons>
                      {isEditing ? (
                        <ButtonRemove
                          onClick={() => {
                            resetForm();
                            setIsEditing(false);
                          }}
                        >
                          Cancelar
                        </ButtonRemove>
                      ) : null}

                      {isEditing ? (
                        <ButttonEdit type="submit" isLoading={isSubmitting}>
                          <Spin spinning={isSubmitting} indicator={antIcon} />

                          <span>Salvar</span>
                        </ButttonEdit>
                      ) : null}

                      {!isEditing ? (
                        <ButttonEdit
                          type="button"
                          onClick={() => setIsEditing(true)}
                        >
                          Editar
                        </ButttonEdit>
                      ) : null}
                    </ContainerButtons>
                  </ContainerForm>
                </TabPane>

                <TabPane tab="Endereços" key="2">
                  {!itsCreateAddress && !itsEditAddress ? (
                    <ListAddresses
                      addresses={data?.user_address}
                      handleChangeItsEditAddress={handleChangeItsEditAddress}
                      handleChangeSelectedAddress={handleChangeSelectedAddress}
                    />
                  ) : null}

                  {id && itsCreateAddress && !itsEditAddress ? (
                    <AddAddress
                      userId={Number(id)}
                      page={page}
                      setItsCreateAddress={setItsCreateAddress}
                      setActiveUser={setActiveUser}
                    />
                  ) : null}

                  {itsEditAddress &&
                  !itsCreateAddress &&
                  selectedAddress &&
                  data ? (
                    <EditAddress
                      activeUser={data}
                      address={selectedAddress}
                      handleCloseEditAddress={handleCloseEditAddress}
                      isEditingAddress={isEditingAddress}
                      setIsEditingAddress={setIsEditingAddress}
                      page={page}
                      selectedAddress={selectedAddress}
                      setActiveUser={setActiveUser}
                      setItsCreateAddress={setItsCreateAddress}
                    />
                  ) : null}
                </TabPane>

                <TabPane tab="Compras" key="6">
                  <AllEvents>
                    {data ? <PurchasesUser activeUserId={id} /> : null}
                  </AllEvents>
                </TabPane>

                <TabPane tab="Ingresso do usuário" key="5">
                  <AllEvents>
                    {data ? <TicketsUser activeUserId={data?._id} /> : null}
                  </AllEvents>
                </TabPane>

                <TabPane tab="Organizadores" key="3">
                  <AllEvents>
                    <ContainerEvents>
                      <ListEvents>
                        {organizers?.results &&
                        organizers?.results.length > 0 ? (
                          organizers.results.map((organizer) => (
                            <OrganizerCard
                              id={organizer._id}
                              isActive={activeOrganizer?._id === organizer._id}
                              onSelectOrganizer={() =>
                                handleSelectedOrganizer(organizer)
                              }
                              status={organizer.status}
                              agent_email={organizer.agent_email}
                              agent_name={organizer.agent_name}
                              agent_phone={organizer.agent_phone}
                            />
                          ))
                        ) : (
                          <div className="empty">
                            <p>Não ha Organizadores</p>{" "}
                          </div>
                        )}
                      </ListEvents>
                    </ContainerEvents>
                  </AllEvents>
                </TabPane>
              </Tabs>
            ) : null}
          </ContainerTabs>
        </ContainerItemsTable>
      </Container>
    </Wrapper>
  );
}
