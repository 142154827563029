import { useEffect, useRef, useState } from "react";

import {
  Container,
  ContainerItems,
  Item,
  CardArrowRight,
  Content,
  ContainerModal,
  ContainerButtons,
  ButtonCancel,
  ButtonAdd,
  ContainerNewCategory,
  ContentNewCategory,
  DivImage,
  AllInput,
  InputContainer,
  ButtonAddImage,
  ButtoMore,
} from "./styles";

import empty_image from "assets/empty-image.svg";

import { Link } from "react-router-dom";
import Slider, { CustomArrowProps } from "react-slick";
import { RiArrowRightLine } from "react-icons/ri";
import { BiCalendarCheck } from "react-icons/bi";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Colors } from "constants/colors";
import { Modal } from "components/Modal";
import { BsPlus } from "react-icons/bs";

import { CategoryType } from "types";
import { useAuthCategories } from "services/hooks/category";

type CategoryFormData = {
  title: string;
};

interface Meta {
  total: number;
}

interface CategoriesInterface {
  data: CategoryType[];
  meta: Meta;
}

interface BannersProps {
  title: string;
  slug: string;
  showModal(): void;
  setIsModalVisible(value: boolean): void;
  isModalVisible: boolean;
}

const categoryFormSchema = yup.object().shape({
  title: yup.string().required("Titulo obrigatório"),
});

export function BannersCategory({
  title,
  slug,
  showModal,
  setIsModalVisible,
  isModalVisible,
}: BannersProps) {
  const { data } = useAuthCategories<CategoriesInterface>({});

  const slider = useRef<Slider>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CategoryFormData>({
    resolver: yupResolver(categoryFormSchema),
  });

  const ArrowRight = ({ style, onClick }: CustomArrowProps) => {
    return (
      <CardArrowRight onClick={onClick} style={style}>
        <RiArrowRightLine
          style={{ marginTop: 5, marginLeft: 2 }}
          fontSize={23}
          color={Colors.secondary80}
        />
      </CardArrowRight>
    );
  };

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 5.04,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,
    // prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 2.03,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          // centerMode: true,
        },
      },
    ],
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddCategory: SubmitHandler<CategoryFormData> = async ({
    title,
  }) => {
    try {
      // await signIn({
      //   email,
      //   password,
      // });
    } catch (err) {
      alert("Email ou senha invalidos");
    }
  };

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  return (
    <Container>
      <ContainerItems>
        <Content>
          <h1>{title}</h1>

          <div className="all-buttons">
            <Link to="/visual#categories">
              <button className="view">
                <span>Ver todos</span>
              </button>
            </Link>

            {/* <button className="add" onClick={showModal}>
              <BsPlus color={Colors.white} size={23} />
              <span className="web">Adicionar {slug}</span>
              <span className="mobile">Adicionar</span>
            </button> */}
          </div>
        </Content>

        <Slider {...settings} ref={slider}>
          {data?.data.map((category, index) => (
            <Item key={category.id}>
              <img src={category.thumb} alt="" />
              <h3>{category.name}</h3>
            </Item>
          ))}
        </Slider>

        <Link to="/visual/#categories">
          <ButtoMore>Ver todos</ButtoMore>
        </Link>
      </ContainerItems>
      <Modal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
      >
        <ContainerModal onSubmit={handleSubmit(handleAddCategory)}>
          <div className="content">
            <h2>Nova categoria</h2>

            <p>Crie uma nova categoria</p>
          </div>

          <ContainerNewCategory>
            <ContentNewCategory>
              <h5 className="title">Informações da categoria</h5>
              <AllInput>
                <InputContainer error={!!errors?.title}>
                  <div>
                    <BiCalendarCheck size={23} color={Colors.secondary40} />
                  </div>
                  <input
                    type="title"
                    placeholder="Nome da categoria"
                    {...register("title")}
                  />
                </InputContainer>
                {errors.title && <span>Por favor, insira o Email.</span>}
              </AllInput>

              <div className="new-image">
                <h5 className="title-image">Adicione uma imagem de capa</h5>

                <span className="image">
                  Especificações: arquivo do tipo PNG ou JPEG, com dimensão
                  recomendada de 720x1024 px e tamanho máximo de 4MB.
                </span>
              </div>

              <input
                id="file"
                type="file"
                style={{ display: "none" }}
                accept="image/png, image/gif, image/jpeg"
                onChange={onSelectFile}
              />

              <ButtonAddImage htmlFor="file">
                <BsPlus color={Colors.primary100} size={18} />

                <span>Adicionar Imagem</span>
              </ButtonAddImage>
            </ContentNewCategory>

            <DivImage>
              {selectedFile ? (
                <img className="preview" src={preview} alt="" />
              ) : (
                <img src={empty_image} alt="empty" />
              )}
            </DivImage>
          </ContainerNewCategory>

          <ContainerButtons>
            <ButtonCancel onClick={handleCancel}>
              <span>Cancelar</span>
            </ButtonCancel>
            <ButtonAdd>
              <span>Criar categoria</span>
            </ButtonAdd>
          </ContainerButtons>
        </ContainerModal>
      </Modal>
    </Container>
  );
}
