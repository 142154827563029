import { ContainerInput, StyledSelect } from "./styles";

import { Select } from "antd";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { CategoryType } from "types";
import { useAuthAllCategories } from "services/hooks/category";

const { Option } = Select;

interface Meta {
  total: number;
}
interface CategoriesInterface {
  data: CategoryType[];
  meta: Meta;
}

type OptionType = {
  label: string;
  value: string | number;
};

interface SelectComponentProps {
  name?: string;
  label?: string;
  placeholder?: string;
  options?: OptionType[];
}

export function SelectCategory({
  name,
  label,
  placeholder,
  options,
}: SelectComponentProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: dataCategories } = useAuthAllCategories<CategoriesInterface>();

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        category: value.value,
      });
    },
    [searchParams, setSearchParams]
  );

  const clearId = useCallback(() => {
    searchParams.delete("category");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  return (
    <ContainerInput>
      <strong>{label}</strong>

      <div className="select">
        <StyledSelect
          bordered={false}
          labelInValue
          showSearch
          optionFilterProp="children"
          placeholder="Escolha a categoria"
          onChange={handleSelectedIdFilter}
          allowClear
          onClear={clearId}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          style={{
            width: "100%",
            height: "38px",
            border: "none",
            outline: 0,
          }}
        >
          {dataCategories?.data.map((category) => {
            return (
              <Option value={category.id} key={category.id}>
                {category.name}
              </Option>
            );
          })}
        </StyledSelect>
      </div>
    </ContainerInput>
  );
}
