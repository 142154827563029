import { ChangeEvent, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { maskOnlyNumbers } from "utils/masks";
import { ContainerInput } from "./styles";

export function InputRange() {
  const [searchParams, setSearchParams] = useSearchParams();

  // const [valueGtePurchase, setValueGtePurchase] = useState("");
  // const [valueLtePurchase, setValueLtePurchase] = useState("");

  const handleKeyPrice = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    maskOnlyNumbers(e);
  }, []);

  const handleChangePriceGte = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        value_gte: e.target.value,
      });
    },
    [searchParams, setSearchParams]
  );

  const handleChangePriceLte = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        value_lte: e.target.value,
      });
    },
    [searchParams, setSearchParams]
  );

  return (
    <ContainerInput>
      <strong>Valor entre</strong>

      <div className="range-prices">
        <div>R$</div>
        <input
          className="first"
          type="text"
          placeholder="0"
          onKeyUp={handleKeyPrice}
          onChange={handleChangePriceGte}
        />
        <div>-R$</div>
        <input
          type="text"
          placeholder="5000"
          onKeyUp={handleKeyPrice}
          onChange={handleChangePriceLte}
        />
      </div>
    </ContainerInput>
  );
}
