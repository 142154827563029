import useSWR from "swr";

import moment from "moment";

import { TicketCharts } from "types";
import { TotalType } from "types";
import { PurchaseCharts } from "types";

import { authClient } from "../../authClient";

import user_result_icon from "../../../assets/user-result-icon.svg";
import client_result_icon from "../../../assets/cliente-result-icon.svg";
import organizer_result_icon from "../../../assets/organizer-result-icon.svg";
import event_result_icon from "../../../assets/event-result-icon.svg";
import ticket_result_icon from "../../../assets/ticket-result-icon.svg";
import purchase_result_icon from "../../../assets/purchase-result-icon.svg";

import { LastEventType } from "types";

import { HighlightedEventType } from "types";

interface Props {
  filter?: string;
}

export function useAuthTotals<Data = any, Error = any>({ filter }: Props) {
  const url = `/backoffice/dashboard/totals?` + filter;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const indexes = [0, 3, 2, 4, 5, 1];

    const names = [
      "Usuários",
      "Clientes",
      "Organizadores",
      "Eventos",
      "Ingressos",
      "Nº Vendas",
      "Total vendas",
      "Total Eventx",
    ];

    const icons = [
      user_result_icon,
      client_result_icon,
      organizer_result_icon,
      event_result_icon,
      ticket_result_icon,
      purchase_result_icon,
      purchase_result_icon,
      purchase_result_icon,
    ];

    const newMap = response?.data?.map((total: TotalType, index: number) => {
      return {
        ...total,
        index: indexes[index],
      };
    });

    const newMapData = newMap.sort(
      (a: TotalType, b: TotalType) => a.index - b.index
    );

    const finalData = newMapData.map((total: TotalType, index: number) => ({
      ...total,
      icon: icons[index],
      name: names[index],
    }));

    return finalData;
  });

  return { data, error, mutate };
}

export function useAuthTicketCharts<Data = any, Error = any>({
  filter,
}: Props) {
  const url = `/backoffice/dashboard/chart/tickets?` + filter;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMap = response.data.map((total: TicketCharts) => {
      return {
        ...total,
        formattedPurchases: Number(total.purchases),
      };
    });

    return newMap;
  });

  return { data, error, mutate };
}

export function useAuthPurchaseCharts<Data = any, Error = any>({
  filter,
}: Props) {
  const url = `/backoffice/dashboard/chart/purchases?` + filter;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const names = ["Cartão de crédito", "Boleto", "Cortesia"];

    const newMap = response.data.map((total: PurchaseCharts, index: number) => {
      return {
        data: total.data.map((purchase) => {
          const date = moment(purchase.date);
          const dateComponent = date.utc().format("DD/MM/YYYY");

          return {
            x: dateComponent,
            y: Number(purchase.amount),
          };
        }),
        name: names[index],
      };
    });

    return newMap;
  });

  return { data, error, mutate };
}

export function useAuthLastEvents<Data = any, Error = any>() {
  const url = `/backoffice/event/filter?page=1&limit=10`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.data.map((event: LastEventType) => {
      return {
        ...event,
      };
    });

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

export function useHighlightedtEvents<Data = any, Error = any>() {
  const url = `/backoffice/event/filter?highlight=true&page=1&limit=10`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map(
      (event: HighlightedEventType) => {
        return {
          ...event,
        };
      }
    );

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

export function useAuthSliderEvents<Data = any, Error = any>() {
  const url = `/backoffice/event/filter?slider=1&page=1&limit=10`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map(
      (event: HighlightedEventType) => {
        return {
          ...event,
        };
      }
    );

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}
