import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0px 109px 64px 109px;

  @media (max-width: 790px) {
    padding: 0px 9px 64px 9px;
  }

  div.input {
    width: 100%;
    height: 38px;
    background: ${Colors.white};

    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 13px;

    input {
      height: 100%;
      width: 100%;
      background: transparent;
      padding-left: 12px;
      outline: 0;
      border: 2px solid ${Colors.secondary20};
      border-right: none;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    button.search {
      cursor: pointer;
      width: 46px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #a2004c;
      border: 2px solid #a2004c;
      border-radius: 0px 5px 5px 0px;
    }
  }

  div.logo-qr-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.content {
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 14px;

      text-align: center;

      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }

  /* div.or {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  div.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    span {
      padding: 0px 11px;
    }

    div.line {
      min-width: 160px;
      width: 100%;
      border: 1px solid ${Colors.secondary100};
      opacity: 0.2;
    }
  }

  div.download-app {
    width: 100%;
    margin-top: 36px;
    align-items: center;
    justify-content: center;

    padding-left: 10px;
    padding-right: 10px;

    h5 {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;

      color: ${Colors.black3};

      @media (max-width: 790px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }

    div.row {
      width: 100%;
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      justify-content: center;
      margin-top: 31px;

      img {
        min-width: 189px;
        min-height: 128px;

        @media (max-width: 790px) {
          min-width: 72px;
          min-height: 70px;
        }
      }
    }
  }
`;

export const ContainerEvents = styled.div`
  max-width: 900px;
  display: flex;
  /* height: 400px; */
  /* margin-top: 20px; */
  /* overflow-y: scroll; */


  /* ::-webkit-scrollbar {
    width: 8px;
    max-height: 10%;
  }


  ::-webkit-scrollbar-track {
    background: ${Colors.black10};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.primary100};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.red20};
  } */

  flex-direction: row;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: space-between;



  @media (max-width: 440px) {
    padding: 0px 18px 0px 18px;
    flex-direction: column;
  }
`;

interface EventProps {
  hasSelected?: boolean;
}

export const Event = styled.div<EventProps>`
  width: 290px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  cursor: pointer;

  padding: 10px;

  border: 1px solid
    ${(props) => (props.hasSelected ? Colors.primary100 : "transparent")};
  border-radius: 5px;

  @media (max-width: 880px) {
    width: 100%;
  }

  @media (max-width: 640px) {
    width: 100%;
  }

  div.column {
    img {
      width: 280px;
      height: 156px;
      border-radius: 14px;
    }

    div.empty {
      width: 280px;
      height: 156px;

      background: ${Colors.background6};
      border-radius: 14px;
    }

    h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${Colors.secondary100};
      margin-top: 14px;
    }
  }

  div.column-loading {
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  margin-top: 139px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 790px) {
    margin-top: 79px;
  }
`;

export const ButtonCancel = styled.button`
  width: 132px;
  height: 50px;
  border: 0;
  cursor: pointer;

  border: 1px solid ${Colors.primary100};
  background: ${Colors.white};
  box-sizing: border-box;
  border-radius: 14px;

  @media (max-width: 790px) {
    width: 162px;
    height: 34px;
    border-radius: 6px;
  }

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.primary100};

    @media (max-width: 790px) {
      font-weight: 700;
      font-size: 14px;
    }
  }
`;

interface ButtonAddProps {
  hasEvent?: boolean;
}

export const ButtonAdd = styled.button<ButtonAddProps>`
  width: 200px;
  height: 50px;
  border: 0;
  cursor: pointer;

  @media (max-width: 790px) {
    width: 162px;
    height: 34px;
    border-radius: 6px;
  }

  background: ${(props) =>
    props.hasEvent ? Colors.primary100 : Colors.black10};

  ${(props) =>
    props.hasEvent &&
    css`
      box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    `}

  border-radius: 14px;

  span {
    color: ${(props) => (props.hasEvent ? Colors.white : Colors.black30)};
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    text-align: center;

    @media (max-width: 790px) {
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
    }
  }
`;
