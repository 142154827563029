// import { useAuth } from "contexts/AuthContext";
import { EventPage } from "pages/event";
import EventsPage from "pages/events";
import FAQPage from "pages/faq";
import FaqDetail from "pages/faq-detail";
import ForgotPassword from "pages/forgot-password";
import Home from "pages/home";
import MessagesPage from "pages/messages";
import NotificationsPage from "pages/notifications";
import { OrganizerPage } from "pages/organizer";
import OrganizersPage from "pages/organizers";
import { SalePage } from "pages/sale";
import SalesPage from "pages/sales";
import SignInPage from "pages/signin";
import { TicketPage } from "pages/ticket";
import TicketsPage from "pages/tickets";
import UserPage from "pages/user";
import UsersPage from "pages/users";
import VisualPage from "pages/visual";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";

export default function RoutesPage() {
  return (
    <Routes>
      <Route path="/" element={<PrivateOutlet />}>
        <Route path="/" element={<Home />} />

        <Route path="/notifications" element={<NotificationsPage />} />

        <Route path="/users" element={<UsersPage />} />

        <Route path="/users/:id" element={<UserPage />} />

        <Route path="/organizers" element={<OrganizersPage />} />

        <Route path="/organizers/:id" element={<OrganizerPage />} />

        <Route path="/events" element={<EventsPage />} />

        <Route path="/events/:id" element={<EventPage />} />

        <Route path="/tickets" element={<TicketsPage />} />

        <Route path="/tickets/:id" element={<TicketPage />} />

        <Route path="/sales" element={<SalesPage />} />

        <Route path="/sales/:id" element={<SalePage />} />

        <Route path="/visual" element={<VisualPage />} />

        {/* <Route path="/messages" element={<MessagesPage />} /> */}

        <Route path="/faq" element={<FAQPage />} />

        <Route path="/faq/:id" element={<FaqDetail />} />
      </Route>

      <Route path="/signin" element={<GuestRoute />}>
        <Route path="/signin" element={<SignInPage />} />
      </Route>

      <Route path="/forgot-password" element={<GuestRoute />}>
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>

      {/* <Route
        path="/signin"
        element={
          <GuestRoute>
            <SignInPage />
          </GuestRoute>
        }
      /> */}

      {/* <Route
        path="/forgot-password"
        element={
          <GuestRoute>
            <ForgotPassword />
          </GuestRoute>
        }
      /> */}

      {/* <Route
        path="/signin"
        element={
          <GuestRoute>
            <SignInPage />
          </GuestRoute>
        }
      /> */}

      {/* <Route
        path="/reset-password"
        element={
          <GuestRoute>
            <ResetPassword />
          </GuestRoute>
        }
      /> */}

      {/* <Route
        path="/forgot-password"
        element={
          <GuestRoute>
            <ForgotPassword />
          </GuestRoute>
        }
      /> */}

      {/* <Route path="/categories/:id" element={<Categories />} />
      <Route path="/search" element={<Search />} /> */}
      {/* <Route path="/politics" element={<Politics />} />
          <Route path="/terms" element={<Terms />} /> */}

      {/* <Route path="my-events" element={<PrivateOutlet />}>
        <Route index element={<MyEvents />} />
        <Route path=":id" element={<MyEvent />} />
        <Route path=":id/integrations" element={<MyEventIntegrations />} />
      </Route>

      <Route path="profile" element={<PrivateOutlet />}>
        <Route index element={<Profile />} />
        <Route path="personal-data" element={<PersonalData />} />
        <Route path="access-data" element={<AccessData />} />
        <Route path="my-tickets" element={<MyTickets />} />
        <Route path="my-shoppings" element={<MyShoppings />} />

        <Route path="addresses">
          <Route index element={<Addresses />} />
        </Route>
      </Route> */}
    </Routes>
  );
}

interface GuestRouteProps {
  children: JSX.Element;
}

interface GuestRouteProps {
  children: JSX.Element;
}

const GuestRoute = () => {
  const isAuthenticated = !!localStorage.getItem("Backoffice.token");

  return !isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

function PrivateOutlet() {
  const isAuthenticated = localStorage.getItem("Backoffice.token");
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return <Outlet />;
}
