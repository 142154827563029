import { Container } from "./styles";
import packageJson from '../../../package.json';
import logo_signin from "assets/logo2.svg";

export function HeaderInit() {
  return (
    <Container>
      <img src={logo_signin} alt="logo-signin" width="365px" height="86px" />
      <div className="text">
        <h2>
          BackOffice <span>v{packageJson.version} - {process.env.REACT_APP_INFO}</span>
        </h2>
      </div>
    </Container>
  );
}
