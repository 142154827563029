import { useCallback } from "react";
import { useAuthEvents } from "services/hooks/event";
import { EventType } from "types";

import { Select } from "antd";

import { ContainerInput, StyledSelect } from "./styles";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

interface EventsInterface {
  results: EventType[];
  count: number;
}

interface SelectEventProps {
  name: string;
}

export function SelectEvent({ name }: SelectEventProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const eventId = searchParams.get("event") ?? ""
  const organizerId = searchParams.get("organizer_id") ?? "";

  const { data: dataEvents } = useAuthEvents<EventsInterface>({
    filters: `organizer_id=${organizerId}&limit=8000`,
  });

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        [name]: value.value,
      });
    },
    [name, searchParams, setSearchParams]
  );

  const clearEventId = useCallback(() => {
    searchParams.delete(name);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [name, searchParams, setSearchParams]);

  return (
    <ContainerInput>
      <strong>Evento</strong>

      <div className="select">
        <StyledSelect
          bordered={false}
          labelInValue
          showSearch
          defaultValue={eventId}
          placeholder="Escolha o evento"
          onChange={handleSelectedIdFilter}
          allowClear
          onClear={clearEventId}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          style={{
            width: "100%",
            minHeight: "100%",
            border: "none",
            outline: 0,
          }}
        >
          {dataEvents?.results &&
            dataEvents?.results?.map((event) => (
              <Option value={event._id} key={event._id}>
                {event.name}
              </Option>
            ))}
        </StyledSelect>
      </div>
    </ContainerInput>
  );
}
