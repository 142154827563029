import { useRef } from "react";

import {
  Container,
  ContainerItems,
  Item,
  CardArrowRight,
  Content,
  ButtoMore,
} from "./styles";

import { Link } from "react-router-dom";

import Slider, { CustomArrowProps } from "react-slick";
import { RiArrowRightLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import { BsPlus } from "react-icons/bs";

import { LastEventType } from "types";

import { ModalAddSlider } from "components/ModalAddSlider";
import { useAuthSliderEvents } from "services/hooks/dashboard";

interface Meta {
  total: number;
}

interface BannersInterface {
  data: LastEventType[];
  meta: Meta;
}

interface BannersProps {
  title: string;
  slug: string;
  showModal(): void;
  setIsModalVisible(value: boolean): void;
  isModalVisible: boolean;
}

export function Banners({
  title,
  slug,
  showModal,
  setIsModalVisible,
  isModalVisible,
}: BannersProps) {
  const slider = useRef<Slider>(null);

  const { data } = useAuthSliderEvents<BannersInterface>();

  const ArrowRight = ({ style, onClick }: CustomArrowProps) => {
    return (
      <CardArrowRight onClick={onClick} style={style}>
        <RiArrowRightLine
          style={{ marginTop: 5, marginLeft: 2 }}
          fontSize={23}
          color={Colors.secondary80}
        />
      </CardArrowRight>
    );
  };

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: data?.data && data.data.length <= 2 ? data.data.length : 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,
    // prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 946,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: data?.data && data.data.length > 1 ? 1.08 : 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <Container>
      <ContainerItems>
        <Content>
          <h1>{title}</h1>

          <div className="all-buttons">
            <Link to="/visual#banners">
              <button className="view">
                <span>Ver todos</span>
              </button>
            </Link>

            <button className="add" onClick={showModal}>
              <BsPlus color={Colors.white} size={23} />
              <span className="web">Adicionar {slug}</span>
              <span className="mobile">Adicionar</span>
            </button>
          </div>
        </Content>

        <Slider {...settings} ref={slider}>
          {data?.data.map((event) => (
            <Item key={event.id}>
              <img src={event.photo} alt="" />
              <h3>{event.name}</h3>
            </Item>
          ))}
        </Slider>

        <Link to="/visual#banners">
          <ButtoMore>Ver todos</ButtoMore>
        </Link>
      </ContainerItems>

      <ModalAddSlider
        title={title}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Container>
  );
}
