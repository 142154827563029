import { Colors } from "../../constants/colors";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerItems = styled.div`
  max-width: 1100px;
  width: 100%;
  padding: 60px 30px 0px 56px;
  margin-bottom: 120px;

  @media (max-width: 1240px) {
    padding: 104px 70px 0px 56px;
  }

  @media (max-width: 768px) {
    padding: 104px 16px 0px 70px;
  }

  @media (max-width: 621px) {
    padding: 104px 16px 0px 30px;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    color: ${Colors.secondary90};

    span {
      font-size: 12px;
      color: ${Colors.background6};
    }
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;

    color: ${Colors.secondary80};
    margin-top: 32px;
  }
`;

export const Button = styled.button`
  border: 0;
  cursor: pointer;
  outline: 0;
  width: 137px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.primary100};
  border-radius: 97px;

  z-index: 6 !important;

  div.icon {
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 11px;
    border-left: 1px solid #b30446;
    margin-left: 14px;
  }

  span {
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;

    color: ${Colors.white};
  }
`;

export const Card = styled.div`
  width: 100%;
  min-height: 340px;
  padding: 34px 0px 34px 34px;
  display: flex;
  flex-direction: row;

  border: 1px solid ${Colors.border};

  box-shadow: 0px 25px 33px rgba(108, 93, 211, 0.05);

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    padding: 0px 0px;
    border: 0;
  }

  @media (max-width: 621px) {
    min-height: 140px;
  }

  background: ${Colors.white};

  border-radius: 8px;
  margin-top: 32px;
`;

export const ButtonLeftFirst = styled.button`
  margin-left: 40px;
  width: 297px;
  height: 34px;
  padding: 8px 24px;
  border: 0;
  background: ${Colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 97px;

  @media (max-width: 621px) {
    min-width: 297px;
    margin-left: 0px;
  }

  span {
    font-weight: normal;
    font-size: 18px;

    margin-left: 12px;
    color: ${Colors.primary100};
    margin-bottom: 0px;
  }
`;

export const ButtonLeft = styled.button`
  margin-left: 40px;
  width: 297px;
  height: 34px;
  padding: 8px 24px;
  border: 0;
  background: ${Colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 97px;

  @media (max-width: 621px) {
    min-width: 297px;
    margin-left: 20px;
  }

  span {
    font-weight: normal;
    font-size: 18px;

    margin-left: 12px;
    color: ${Colors.primary100};
    margin-bottom: 0px;
  }
`;

export const SidebarFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  @media (max-width: 621px) {
    display: none;
  }

  div.card-filter {
    cursor: pointer;
    width: 192px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 28px;
    padding-right: 18px;

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;

      display: flex;
      align-items: center;

      color: ${Colors.secondary70};
    }

    span.active {
      font-weight: bold;
    }
  }

  div.active {
    background: ${Colors.gray80};
  }
`;

export const ContainerResults = styled.div`
  width: 100%;
  padding-left: 47px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 24px;
    margin-top: 25px;
  }

  @media (max-width: 621px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-left: 0px;

    overflow-y: scroll;
  }
`;

interface CardResultProps {
  hasFormat?: boolean;
}

export const CardResult = styled.div<CardResultProps>`
  width: 194px;
  height: 117px;
  display: flex;
  flex-direction: column;
  background: ${Colors.border90};
  border: 0.6px solid ${Colors.border70};
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 18px;

  margin-right: 11px;

  padding: 12px 19px 0px 16px;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }

  @media (max-width: 621px) {
    margin-bottom: 10px;
    min-width: 194px;
    min-height: 117px;
    margin-right: 20px;
  }

  div.first-information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div.content {
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        font-weight: normal;
        font-size: 18px;
        line-height: 100%;
        color: ${Colors.secondary70};
        margin-left: 8px;
        margin-bottom: 0px;
      }

      div.circle {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(111, 92, 158, 0.1);
      }
    }
  }

  div.second-information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 23px;

    margin-left: ${(props) => (props.hasFormat ? "5px" : "10px")};

    h4 {
      font-weight: 600;
      font-size: 36px;

      line-height: 8px;
      color: ${Colors.secondary70};
      margin-bottom: 6px;
    }

    h4.format {
      font-size: 16px;
    }

    span.available {
      font-weight: bold;
      font-size: 9px;
      line-height: 12px;
      text-transform: uppercase;

      color: ${Colors.black4};
      opacity: 0.75;
      margin-top: 6px;
    }

    span.danger {
      font-weight: 600;
      font-size: 11px;
      line-height: 100%;

      color: ${Colors.red30};
      margin-bottom: 20px;
    }

    span.green {
      font-weight: 600;
      font-size: 11px;
      line-height: 100%;

      color: ${Colors.success100};
      margin-bottom: 20px;
    }
  }
`;

export const CardResultLoading = styled.div`
  width: 194px;
  height: 117px;
  display: flex;
  flex-direction: column;
  background: ${Colors.border90};
  border: 0.6px solid ${Colors.border70};
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 18px;
  padding: 12px 19px 0px 16px;

  margin-right: 11px;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }

  @media (max-width: 621px) {
    margin-bottom: 10px;
    min-width: 194px;
    min-height: 117px;
    margin-right: 20px;
  }

  div.first-information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div.content {
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        font-weight: normal;
        font-size: 18px;
        line-height: 100%;
        color: ${Colors.secondary70};
        margin-left: 8px;
        margin-bottom: 0px;
      }

      div.circle {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(111, 92, 158, 0.1);
      }
    }
  }

  div.second-information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 5px;
    margin-left: 10px;

    h4 {
      font-weight: 600;
      font-size: 36px;

      line-height: 8px;
      color: ${Colors.secondary70};
      margin-bottom: 6px;
    }

    span.available {
      font-weight: bold;
      font-size: 9px;
      line-height: 12px;
      text-transform: uppercase;

      color: ${Colors.black4};
      opacity: 0.75;
      margin-top: 6px;
    }

    span.danger {
      font-weight: 600;
      font-size: 11px;
      line-height: 100%;

      color: ${Colors.red30};
      margin-bottom: 20px;
    }
  }
`;

export const ContainerGraphs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 340px;

  background: ${Colors.white};
  margin-top: 56px;

  @media (max-width: 621px) {
    flex-direction: column;
  }
`;

export const TitleGraph = styled.h5`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  color: ${Colors.secondary80};

  @media (max-width: 621px) {
    display: none;
  }
`;

export const TitleGraphMobile = styled.h5`
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;

  color: ${Colors.secondary80};
  margin-bottom: 21px;

  @media (min-width: 621px) {
    display: none;
  }
`;

export const DivGraph = styled.div`
  width: 48%;

  @media (max-width: 621px) {
    width: 100%;
  }
`;

export const Graph = styled.div`
  width: 100%;
  height: 457px;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid ${Colors.border};

  box-shadow: 0px 25px 33px rgba(108, 93, 211, 0.05);

  @media (max-width: 621px) {
    width: 100%;
    margin-bottom: 10px;
    box-shadow: 0px 25px 33px rgba(108, 93, 211, 0.1);
    margin-bottom: 56px;
  }
`;

export const GraphLast = styled.div`
  width: 100%;
  height: 457px;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid ${Colors.border};

  box-shadow: 0px 25px 33px rgba(108, 93, 211, 0.05);

  @media (max-width: 621px) {
    width: 100%;
    margin-bottom: 10px;
    box-shadow: 0px 25px 33px rgba(108, 93, 211, 0.1);
  }
`;

interface CustomFilterProps {
  active?: boolean;
}

export const CustomFilter = styled.div<CustomFilterProps>`
  cursor: pointer;
  width: 192px;
  height: 48px;
  display: flex;
  flex-direction: column;
  padding: 21px 14px 0px 28px;

  div.content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 4px;
  }

  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;

    display: flex;
    align-items: center;

    color: ${Colors.secondary70};
  }

  span.active {
    font-weight: bold;
  }

  ${(props) =>
    props.active &&
    css`
      height: 145px;
      border-radius: 5px;
      border: 0.6px solid #e6e2f8;
      background: ${Colors.gray80};
      padding-bottom: 18px;
    `}

  div.time-course {
    margin-top: 21.5px;
    border-top: 0.6px solid #e2ddf8;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  strong {
    color: ${Colors.gray20};
    font-weight: 500;
    font-size: 11px;
    margin-top: 17px;
  }

  div.range-pick {
    border: 0;
    background: ${Colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 38px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;

    div.dot {
      width: 1px;
    }

    @media (max-width: 503px) {
      padding-left: 8px;

      input {
        font-size: 10px;
      }
    }

    input {
      width: 50%;
      height: 100%;
      font-size: 10px;
      border: 0;
      background: transparent;
      outline: 0;

      @media (max-width: 503px) {
        width: 98%;
      }
    }
  }

  input.normal {
    outline: none;
    background: ${Colors.white};
    border: 0;
    width: 206px;
    height: 38px;
    padding-left: 18px;
    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;

    @media (max-width: 503px) {
      width: 140px;
    }
  }

  div.select {
    border: 0;
    width: 206px;
    height: 38px;
    outline: none;
    background: ${Colors.white};

    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;

    @media (max-width: 503px) {
      width: 140px;
    }
  }
`;

export const ListButtons = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  margin-top: 33px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  div.last {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const ContainerModalAddDocument = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 0px 109px 24px 109px;

  @media (max-width: 700px) {
    padding: 0px 20px 24px 0px;
  }

  h2.title {
    font-weight: bold;
    font-size: 32px;
    color: ${Colors.secondary100};

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  h3.account {
    font-weight: normal;
    font-size: 16px;
    margin-top: 16px;
    color: ${Colors.secondary100};
    margin-bottom: 0px;

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  div.content {
    width: 100%;
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div.image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 180px;
      height: 220px;
      margin-top: 20px;
    }

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 85px;
      text-align: center;
      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }
`;

export const ContainerNormalButtons = styled.div`
  width: 100%;
  margin-top: 83px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonCancel = styled.button`
  width: 132px;
  height: 50px;
  border: 0;
  cursor: pointer;

  border: 1px solid ${Colors.primary100};
  background: ${Colors.white};
  box-sizing: border-box;
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.primary100};
  }
`;

interface ButtonCancelPurchaseProps {
  isLoading?: boolean;
}

export const ButtonCancelPurchase = styled.button<ButtonCancelPurchaseProps>`
  cursor: pointer;
  border: 0;
  width: 150px;
  height: 50px;
  background: ${Colors.primary100};
  border-radius: 14px;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  color: ${Colors.white};

  span {
    margin-left: ${(props) => (props.isLoading ? "5px" : 0)};
  }
`;

export const ContainerFiltersMobile = styled.div`
  width: 100%;
  margin-top: 48px;
  margin-bottom: 56px;

  @media (min-width: 768px) {
    display: none;
  }

  div.container-select {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div.select {
      width: 48%;
      margin-top: 8px;
    }

    div.time-course {
      width: 48%;
      margin-top: 6px;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;

    color: ${Colors.secondary90};
  }
`;

export const AccountDropDownOrganizer = styled.div`
  display: block;
  position: absolute;
  background-color: ${Colors.white};
  width: 304px;
  height: 120px;
  z-index: 3;
  margin-top: 3px;

  border-radius: 0px 0px 14px 14px;

  border: 1px solid ${Colors.border10};

  box-shadow: 0px 18px 33px rgba(108, 93, 211, 0.1);
  border-radius: 0px 0px 14px 14px;

  @media (max-width: 621px) {
    width: 240px;
  }

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      h5 {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;

        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: ${Colors.black};
      }
    }

    li.last {
      border: 0;
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;
