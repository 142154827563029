import { Colors } from "constants/colors";
import styled from "styled-components";

interface StatusProps {
  color: "green" | "red" | "yellow" | "gray" | "orange" | string;
  size?: "small";
}

export const Status = styled.div<StatusProps>`
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 38px;
  width: ${props => props.size === "small" ? "100px" : "100%"};
  padding: 0px 12px;

  background-color: ${(props) => {
    switch (props.color) {
      case "green":
        return Colors.success100;
      case "red":
        return Colors.red100;
      case "yellow":
        return Colors.yellow50;
      case "grey":
        return Colors.secondary20;
      case "darkBlue":
        return Colors.secondary90;
      case "orange":
        return Colors.orange100;
      default:
        return props.color;
    }
  }};

  border-radius: 50px;

  span {
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: ${Colors.white};
    text-shadow: 0px 0px 8px rgba(113, 224, 131, 0.5);
    margin-bottom: 0px;
  }
`;
