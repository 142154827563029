import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerModalDelete = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0px 109px 24px 109px;

  div.content {
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 85px;

      text-align: center;

      color: ${Colors.black90};

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90};
      }
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  margin-top: 139px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerNormalButtons = styled.div`
  width: 100%;
  margin-top: 83px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonCancel = styled.button`
  width: 132px;
  height: 50px;
  border: 0;
  cursor: pointer;

  border: 1px solid ${Colors.primary100};
  background: ${Colors.white};
  box-sizing: border-box;
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */

    text-align: center;

    color: ${Colors.primary100};
  }
`;

export const ButtonAdd = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  border: 0;
  cursor: pointer;

  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  span {
    color: ${Colors.white};
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;

    text-align: center;
  }
`;
