import { useState } from "react";

import { useSWRConfig } from "swr";
import Skeleton from "react-loading-skeleton";

import { BsPlus } from "react-icons/bs";

import { LastEventType } from "types";
import { Colors } from "constants/colors";
import { cities } from "constants/cities";

import { ModalAddSlider } from "components/ModalAddSlider";
import { Modal } from "components/Modal";

import {
  Container,
  ContainerItems,
  Item,
  ListItems,
  Content,
  ButtonCancel,
  ButtonAdd,
  Background,
  Circle,
  ContainerModalDelete,
  ContainerNormalButtons,
} from "./styles";

import trashIcon from "assets/trashIcon.svg";
import { useAuthSliderEvents } from "services/hooks/dashboard";
import { EditStatusEvent } from "services/hooks/event";

interface Meta {
  total: number;
}

interface BannersInterface {
  data: LastEventType[];
  meta: Meta;
}

interface BannersProps {
  title: string;
  slug: string;
  showModal(): void;
  setIsModalVisible(value: boolean): void;
  isModalVisible: boolean;
}

export function Banners({
  title,
  slug,
  showModal,
  setIsModalVisible,
  isModalVisible,
}: BannersProps) {
  const [eventHover, setEventHover] = useState<LastEventType | null>(null);
  const [hover, setHover] = useState(false);

  const { mutate } = useSWRConfig();

  const { data } = useAuthSliderEvents<BannersInterface>();

  const [selectedEvent, setSelectedEvent] = useState<LastEventType | null>(
    null
  );

  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const showModalDelete = (event: LastEventType) => {
    setIsModalDeleteVisible(true);
    setSelectedEvent(event);
  };

  const handleDeleteOk = () => {
    setIsModalDeleteVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsModalDeleteVisible(false);
  };

  const handleRemoveSlider = async () => {
    try {
      // await authClient.delete(`/backoffice/event/slider/${selectedEvent?.id}`);

      if (selectedEvent?._id) {
        const credentials = {
          slider: "0",
        };

        await EditStatusEvent({ id: selectedEvent?._id, credentials });

        mutate(`/backoffice/dashboard/slider-events?page=1&limit=30`);

        handleDeleteCancel();

        alert(`Evento removido com sucesso`);
      }
    } catch (err) {}
  };

  return (
    <Container>
      <ContainerItems>
        <Content>
          <h1>{title}</h1>

          <div className="all-buttons">
            <button className="add" onClick={showModal}>
              <BsPlus color={Colors.white} size={18} />

              <span>Adicionar {slug}</span>
            </button>
          </div>
        </Content>

        {!data?.data ? (
          <ListItems>
            {cities.map((city, index) => (
              <Item key={index}>
                <Skeleton width="191px" height="146px" />
                <Skeleton
                  width="191px"
                  style={{ marginTop: 20, marginBottom: 10 }}
                />
              </Item>
            ))}
          </ListItems>
        ) : null}

        {data?.data ? (
          <ListItems>
            {data.data.map((event) => (
              <Item
                key={event._id}
                onMouseEnter={() => {
                  setHover(true);
                  setEventHover(event);
                }}
                onMouseLeave={() => {
                  setHover(false);
                  setEventHover(null);
                }}
              >
                <Background img={event.thumb}>
                  {hover && eventHover?._id === event._id ? (
                    <div className="row">
                      <Circle onClick={() => showModalDelete(event)}>
                        <img src={trashIcon} alt="" />
                      </Circle>
                    </div>
                  ) : null}
                </Background>
                <h3>
                  {event.name
                    ? event.name.length > 22
                      ? `${event.name.substring(0, 22)}...`
                      : event.name
                    : ""}
                </h3>
              </Item>
            ))}
          </ListItems>
        ) : null}
      </ContainerItems>

      <ModalAddSlider
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title={title}
      />

      <Modal
        isModalVisible={isModalDeleteVisible}
        handleCancel={handleDeleteCancel}
        handleOk={handleDeleteOk}
      >
        <ContainerModalDelete>
          <div className="content">
            <h2>Deseja excluir?</h2>

            <p>
              Você tem certeza que deseja excluir o item “
              {selectedEvent?.name ?? ""}” da lista de Sliders?
            </p>
          </div>

          <ContainerNormalButtons>
            <ButtonCancel onClick={handleDeleteCancel}>
              <span>Cancelar</span>
            </ButtonCancel>
            <ButtonAdd onClick={handleRemoveSlider} hasEvent>
              <span>Sim, desejo</span>
            </ButtonAdd>
          </ContainerNormalButtons>
        </ContainerModalDelete>
      </Modal>
    </Container>
  );
}
