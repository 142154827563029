import { AuthProvider } from "./AuthContext";
import { TostProvider } from "./ToastContext";
import { VisualProvider } from "./VisualContext";

export const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <TostProvider>
      <VisualProvider>{children}</VisualProvider>
    </TostProvider>
  </AuthProvider>
);
