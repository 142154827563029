import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 97px;

  padding-left: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid ${Colors.black8};

  img {
    width: 150px;
    height: 40px;
  }

  div.text {
    display: flex;
    align-items: center;

    margin-left: 14px;
    padding-left: 14px;
    height: 55px;
    border-left: 2px solid #d3cfde;

    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #504079;
      margin-bottom: 0px;

      span {
        font-size: 12px;
        color: ${Colors.background6};
      }
    }
  }
`;
