import { Colors } from "../constants/colors";
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
 @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500&family=Poppins:wght@300;400;600;700&display=swap');


.slick-dots li button:before {
  font-size: 12px;
  line-height: 12px;
  margin-top: -90px;
}

@media(max-width: 1024px) {
  .slick-dots li button:before {
    margin-top: 0px;
  }
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: ${Colors.black10} !important;
  outline: none;
  background: ${Colors.black10} !important;
}


 .slick-dots li.slick-active button:before {
  color:${Colors.secondary100} !important;
  opacity: 1;
}


* {
  margin:0 ;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}


body {
  background: ${Colors.white};
  -webkit-font-smoothing: antialised;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}
`;
