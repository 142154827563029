import {
  FormEvent,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useCallback,
} from "react";

import { FieldError } from "react-hook-form";

import { cnpj, cpf, date, phone } from "utils/masks";
import { Container, Label } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  isEditing?: boolean;
  mask?: "phone" | "birthdate" | "cpf" | "cnpj";
  label?: string;
  error?: FieldError;
  isFull?: boolean;
}

export const InputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = ({ name, isEditing, label, error = null, mask, isFull, ...rest }, ref) => {
  const handleKeyUp = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      switch (mask) {
        case "phone":
          phone(e);
          break;
        case "birthdate":
          date(e);
          break;
        case "cpf":
          cpf(e);
          break;
        case "cnpj":
          cnpj(e);
          break;
        default:
          break;
      }
    },
    [mask]
  );

  return (
    <Container isFull={isFull} isEditing={isEditing}>
      {!!label && (
        <Label>
          <h2>{label}</h2>
        </Label>
      )}

      <input
        name={name}
        {...rest}
        ref={ref}
        disabled={!isEditing}
        onKeyUp={handleKeyUp}
      />

      {!!error ? <span className="error">{error.message}</span> : null}
    </Container>
  );
};

export const Input = forwardRef(InputBase);
