import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PaginationStyled } from "./styles";

interface PaginationProps {
  current: number;
  onChange(current: number): void;
  total: number;
}

export function PaginationComponent({
  current,
  onChange,
  total,
}: PaginationProps) {
  const [_, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams({ page: String(current) });

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [current, setSearchParams]);

  return (
    <PaginationStyled
      current={current}
      onChange={onChange}
      total={total}
      pageSize={20}
      showSizeChanger={false}
    />
  );
}
