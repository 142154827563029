import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -16px;
  border-left: 1px solid ${Colors.border100};
  border-right: 1px solid ${Colors.border100};
  padding: 10px 32px 20px 32px;
`;

export const Gateway = styled.div`
  width: 48%;
  height: 182px;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  padding: 22px;

  gap: 12px;

  background: ${Colors.white};
  border: 1px solid ${Colors.border100};
  box-sizing: border-box;
  border-radius: 5px;

  @media (max-width: 900px) {
    min-width: 100%;
    width: 100%;
    height: 92px;
  }

  @media (max-width: 516px) {
    height: 122px;
  }

  @media (max-width: 430px) {
    flex-direction: column;
    height: 222px;
  }

  h3 {
    color: ${Colors.secondary80};
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 0px;
  }
`;
